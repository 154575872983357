import { Component, OnInit, HostListener, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TitleCasePipe, Location, isPlatformBrowser } from '@angular/common';
import { forkJoin } from 'rxjs';
import { SimpleModalService } from 'ngx-simple-modal';
import { find } from 'lodash-es';
import { LocationService } from '../../../home/services/location.service';
import { appConfig } from '../../../core/appConfig/config';
import { UserService } from '../../services/user.service';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { environment } from '../../../../environments/environment';
import { AlertService } from '../../../core/services/alert.service';
import { SeoService } from '../../../core/services/seo.service';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { SiteContentService } from '../../../core/services/siteContents';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { UtilsService } from '../../../core/services/utils.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { GooglePlacesService } from '../../../core/services/google-places.service';
import { ProductService } from '../../../product/services/product.service';
import { ProviderModalComponent } from '../../../product/component/provider-modal/provider-modal.component';
import { CrmProxyService } from '../../../core/services/crm-proxy.service';
import { AddressValidationModalComponent } from '../address-validation-modal/address-validation-modal.component';
import { ZipcodeService } from '../../../core/services/zipcode.service';
import { CCPAUpdatesService } from '../../../core/services/ccpa-updates.service';

// start of register component import
import { CustomerServiceValidationModalComponent } from '../customer-service-validation-modal/customer-service-validation-modal.component';
import { User } from '../register/user';
// - end of register component import
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';

@Component({
  selector: 'hos-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss'],
})
export class AddPropertyComponent implements OnInit {
  selectedAddress: any = {
    rawData: '',
    zipcode: '',
  };

  locationCodeData: any;

  validAddress = true;

  showProvider: Boolean = false;

  backPress: Boolean = false;

  locationCode: any;

  selectedCity: any;

  partnerName: any;

  address: any = {
    contactType: 'RESIDENTIAL',
  };

  newAddress: any = {
    contactType: 'RESIDENTIAL',
  };

  business: any = {};

  strAddress: String = '';

  isClicked: Boolean;

  showLoader: Boolean = false;

  zipcodeValid: Boolean = false;

  userDetails: any;

  alertData: any = {};

  states: any = [];

  isPropertyExist: String;

  customer_service_number: any;

  stateCode: any;

  contactLink: any;

  streetNumberValidation: string = appConfig.streetNumber;

  zipcode: any;

  seoConfig: SeoConfig = {
    title: 'Profile Service Line Warranties | American Water Resources',
    description: `American Water Resources offers affordable home protection programs
        that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
        in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  showDropDown = false;

  suggestedAddress: any;

  homeOwner = {
    isHomeOwner: null,
    allowRental: null,
    offbillOnly: null,
  };

  showPropForm = false;

  showHomeownerQuestions = false;

  providerAvailable: Boolean = false;

  providerSelected: Boolean;

  phoneNumberExistingErrorMsg: String;

  businessPhoneErrorMsg: String;

  enableProviderPopup: boolean = false;

  onZipcodeUpdatedData: any;

  isAddProperty: boolean;

  @ViewChild('customerForm')
  customerNumberForm: any;

  @HostListener('document:click')
  clickout() {
    this.showDropDown = false;
  }

  // -start of register component ts variables
  registerError: String = '';

  registerSuccess: String = '';

  userEmail: String = '';

  securityQuestionsArray: string[];

  invalidForm: Boolean = false;

  isSecurityQueSame: Boolean = false;

  password_regex: String;

  email_regex: String;

  customer_number_regex: String;

  phone_number_regex: String;

  referer: String;

  isExistingUser: Boolean = false;

  zipcodeSet: Boolean = false;

  addressLookup: any = {
    zipcode: '',
  };

  isClickedForm = false;

  showNewForm: any;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'Register',
      link: '',
      active: true,
    },
  ];

  step0Access = true;

  step1Access = false;

  step2Access = false;

  step3Access = false;

  confirmEmailId: String;

  currentPageNumber = 0;

  existingCustomer: any;

  existingCustomerData: any;

  businessTypeMapping: any = {
    1: 'Food Service',
    2: 'Retail',
    3: 'Automotive',
    4: 'Health Services',
    5: 'Professional Services',
    6: 'Worship Center',
    7: 'Rental Home/ Apartment',
    8: 'VFW/ Union Hall/ Private Club',
    9: 'Manuftrg/ Distr/ Indust',
    10: 'Other',
  };

  userTypeMapping: any = {
    1: 'Tenant',
    2: 'Property Owner',
    3: 'Management Company',
  };

  isViewSecurity: Array<boolean> = [false, false];

  customerNumber: any;

  searchApiCall: any;

  secQueAnsErrorMsg: Array<String> = [];

  isDisposableEmail: Boolean = false;

  customerExistInHOS: Boolean = false;

  user: User = new User();

  existingUser: User = new User();

  customer: any;

  locationCodeForReferer: any;

  selectedCustNo: any;

  isBrowser: Boolean = true;

  chance: any;

  constructor(
    private seoService: SeoService,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private simpleModalService: SimpleModalService,
    private siteContentService: SiteContentService,
    private utilsService: UtilsService,
    private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private googlePlacesService: GooglePlacesService,
    private productService: ProductService,
    private crmProxyService: CrmProxyService,
    private titlecasePipe: TitleCasePipe, // - register component
    private zipcodeService: ZipcodeService,
    private ccpaUpdatesService: CCPAUpdatesService,
    private locationService: LocationService,
    private globalVariables: GlobalVariables,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.customer_service_number = environment.customer_service_number;
    this.customer_number_regex = environment.customer_number_regex;
    seoService.generateTitleTags(this.seoConfig);
    seoService.setCanonicalTags();
    this.userDetails = this.userService.getSignedInUser();
    this.siteContentService.statesdata$().subscribe(states => {
      this.states = states;
      if (!this.states) {
        this.states = this.sessionStorageService.getItem('statesList') ? JSON.parse(this.sessionStorageService.getItem('statesList')) : states;
      }
    });
    utilsService.getContactLink$().subscribe(linkData => {
      this.contactLink = `/page/contact-us/${linkData.entryId}`;
    });
    this.isBrowser = isPlatformBrowser(platformId);
    this.chance = new Chance();
  }

  ngOnInit() {
    this.sessionStorageService.deleteItem('providerSelected');
    this.sessionStorageService.deleteItem('isProvider');
    this.sessionStorageService.deleteItem('websitePartnerName');
    this.activatedRoute.queryParams.subscribe(params => {
      this.zipcode = params.zipcode;
    });
    if (this.zipcode) {
      this.address.zipcode = this.zipcode;
      this.showLoader = true;
      if (!['null', 'undefined', undefined, null, ''].includes(this.sessionStorageService.getItem('location_code'))) {
        this.locationService.getLocationsContent(this.sessionStorageService.getItem('location_code')).subscribe(data => {
          const locDataContent = data.body;
          this.showLoader = false;
          if (locDataContent.serviceState) {
            const state = this.states.find(ele => ele.key === locDataContent.serviceState);
            this.address.state = state.value;
          }
        });
      }
    }
    const providerState = this.sessionStorageService.getItem('providerState');
    if (environment.ccpa_updates_provider.includes(providerState)) {
      this.ccpaUpdatesService.getCCPAUpdates();
    }
    this.customer = this.getUser();
    this.propertyValidate('no');
    this.selectedCustNo = this.globalVariables.selected_Customer_number;
    this.isAddProperty = true;
  }

  addNewServiceAddress(form: NgForm) {
    this.sessionStorageService.setItem('providerSelected', '1');
    this.sessionStorageService.setItem('isProvider', '1');
    this.isClicked = true;
    if (form.valid && this.zipcodeValid) {
      if (
        find(this.states, {
          key: this.stateCode,
        }).value === this.address.state
      ) {
        if (!this.address.streetName) {
          this.address.streetName = '';
        }
        this.getSmartAddress(this.address, upDateAddress => {
          if (upDateAddress) {
            if (this.globalVariables.OOFSelected && this.globalVariables.OOFSelected.isOOFLocationAddProp === 'true') {
              /* Add code if need to set other field for ooflocation */
            } else {
              this.zipcodeService.getZipcodeId(upDateAddress.zipcode).subscribe(res => {
                // sthis.onZipcodeUpdated(res);
                this.address.zipcodeId = res.body.zipCodeId;
                this.address.locationCodeId = res.body.locationCodeId;
              });
            }
            this.address.streetNumber = upDateAddress.streetNumber;
            this.address.streetName = upDateAddress.streetName || '';
            this.address.zipcode = upDateAddress.zipcode;
            this.address.city = upDateAddress.city;
            this.address.state = upDateAddress.state;
          }
          this.showLoader = true;
          this.isClicked = false;
          if (this.address.contactType === 'COMMERCIAL') {
            this.address.typeOfBusiness = this.business.businessType;
            this.address.businessName = this.business.businessName;
            this.address.contactTitle = this.business.contactTitle;
            this.address.businessPhone = this.business.businessPhone;
          }
          this.address.locationCodeId = this.sessionStorageService.getItem('location_code')
            ? this.sessionStorageService.getItem('location_code')
            : this.address.locationCodeId;
          const userAddress = JSON.parse(JSON.stringify(this.address));

          const item = this.states.find(p => p.value === this.address.state);
          this.newAddress = {
            ...this.address,
          };
          this.newAddress.stateId = item.key;
          const payloadValidateProperty = {
            serviceAddress: this.newAddress,
            billingAddress: this.newAddress,
            isBillingAddressSameAsServiceAddress: true,
          };

          this.checkDuplicateProperty(payloadValidateProperty, status => {
            if (status) {
              const alertData = {
                title: '',
                message: `Our records indicate this property already exists in our system.
                        <b> Do you want to use this address?</b>`,
                alertIcon: 'fa fa-info-circle red',
                cancelText: 'NO',
                confirmText: 'YES',
              };
              this.showAlertModal(alertData, confirmed => {
                if (confirmed) {
                  this.alertData.title = "We're sorry, but this property is already registered.";
                  const alertMsg = `Please contact Customer Service at <span class="app-txt">
                                      ${this.customer_service_number}</span>.`;
                  this.alertData.message = alertMsg;
                  this.alertData.alertIcon = 'fa fa-info-circle red';
                  this.alertData.cancelText = ' ';
                  this.alertData.confirmText = 'Continue';
                  this.showAlertModal(this.alertData);
                }
              });
              this.showLoader = false;
            } else {
              this.validateProperty(payloadValidateProperty, (dataStatus, data) => {
                this.showLoader = false;

                let ownOrRentedValidated = 'true';
                let noWaterBillConversion = 'false';

                if (this.isPropertyExist === 'no') {
                  if (form.value.rental === true && form.value.tenantBill === true) {
                    noWaterBillConversion = 'true';
                  }
                } else if (data !== undefined && data.NoWaterBillConversion === true) {
                  const oofLocationCode = `${this.address.state}150`;
                  this.productService.getProviderContent(this.address.locationCodeId).subscribe(dataForLocation => {
                    if (oofLocationCode !== dataForLocation.body.locationCode) {
                      this.productService.getLocationCode(oofLocationCode).subscribe(dataForOofLocationCode => {
                        this.address.locationCodeId = dataForOofLocationCode.body[0].locationCodeId;
                      });
                    }
                  });

                  ownOrRentedValidated = data.OwnOrRentValidated.toString();
                  noWaterBillConversion = data.NoWaterBillConversion.toString();
                }

                const payload: any = {
                  serviceAddress: userAddress,
                  billingAddress: userAddress,
                  isBillingAddressSameAsServiceAddress: true,
                  ownOrRentedValidated,
                  noWaterBillConversion,
                  // [HWR-2723 Feilds Added For New-Property Registration]
                  firstName: this.userDetails.user.firstName || '',
                  lastName: this.userDetails.user.lastName,
                };

                payload.providerAvailable = this.providerAvailable;
                if (this.providerAvailable) {
                  payload.providerSelected = this.providerSelected;
                }

                if (dataStatus) {
                  this.alertData.title = 'Please confirm';
                  this.alertData.message = `<b>Our records indicate this service address is in our system.</b>
                            <br><br/> Do you wish to continue?`;
                  this.alertData.alertIcon = 'fa fa-info-circle red';
                  this.alertData.cancelText = 'CANCEL';
                  this.alertData.confirmText = 'Ok';
                  this.showAlertModal(this.alertData, confirmed => {
                    if (confirmed) {
                      payload.customerNo = data.Customer_Number;
                      payload.customerGuid = data.Customer;
                      if (data.Last_Name.toLowerCase() === this.customer.user.lastName.toLowerCase()) {
                        // add validation
                        if (data.enrollments.length > 0) {
                          const query = 'skip_schema_validation=yes';
                          this.searchApiCall = this.userService.searchCustomerById(payload.customerNo, query);
                          this.generateName(this.searchApiCall, data);
                        } else {
                          this.addCustomerAddress(payload, this.userDetails.user._id, form, true);
                        }
                      } else {
                        this.alertData.title = "We're sorry, but property doesn't match to your Account.";
                        const alertMsg = `Please contact Customer Service at <span class="app-txt">
                                                ${this.customer_service_number}</span> to verfiy your coverage.`;
                        this.alertData.message = alertMsg;
                        this.alertData.alertIcon = 'fa fa-info-circle red';
                        this.alertData.cancelText = ' ';
                        this.alertData.confirmText = 'Continue';
                        this.showAlertModal(this.alertData);
                      }
                    }
                  });
                  this.showLoader = false;
                } else {
                  if (this.isPropertyExist === 'yes') {
                    this.alertData.title = "We're sorry, but we could not locate the property address you have entered.";
                    const alertMsg = `Please contact Customer Service at <span class="app-txt">
                                    ${this.customer_service_number}</span> to verfiy your coverage.`;
                    this.alertData.message = alertMsg;
                    this.alertData.alertIcon = 'fa fa-info-circle red';
                    this.alertData.cancelText = ' ';
                    this.alertData.confirmText = 'Continue';
                    this.showAlertModal(this.alertData);
                  }
                  if (this.isPropertyExist === 'no') {
                    this.addCustomerAddress(payload, this.userDetails.user._id, form, true);
                  }
                }
              });
            }
          });
        });
      } else {
        const alertData = {
          title: 'Error',
          // tslint:disable-next-line:max-line-length
          message: `Zipcode does not exist in selected state, Please select ${
            find(this.states, {
              key: this.stateCode,
            }).value
          } to proceed.`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: 'Cancel',
          confirmText: 'Ok',
        };
        this.showAlertModal(alertData);
      }
    }
  }

  validateProperty(address: any, done: any) {
    const params = `street_number=${this.newAddress.streetNumber.trim()}&street=${this.newAddress.streetName.trim()}&
city=${this.newAddress.city.trim()}&state=${this.newAddress.state}&zip_code=${this.newAddress.zipcode.trim()}`;
    this.userService.searchCustomerByAddress(params).subscribe(
      data => {
        const addressData = data.body;
        if (addressData.length > 0) {
          done(true, addressData[0]);
        } else {
          done(false);
        }
      },
      () => {
        done(false);
      },
    );
  }

  checkDuplicateProperty(address: any, done: any) {
    this.userService
      .crmCustomerAddress(
        this.newAddress.streetNumber.trim(),
        this.newAddress.streetName.trim(),
        this.newAddress.city.trim(),
        this.newAddress.state,
        this.newAddress.stateId,
        this.newAddress.zipcode.trim(),
        this.userDetails.user._id,
      )
      .subscribe(
        () => {
          done(true);
        },
        error => {
          if (error.status === 409) {
            done(true);
          }
          if (error.status === 404) {
            done(false);
          }
        },
      );
  }

  selectValuePropertyExist(address, city) {
    this.address.streetNumber = address;
    this.address.city = city;
    this.showDropDown = false;
  }

  // - added resetformflag to not reset form as in one method form is not getting utilized
  addCustomerAddress(payload: any, userId: String, form: any, resetFormFlag: boolean) {
    this.showLoader = true;
    this.userService.addCustomerAddress(this.userDetails.user._id, payload).subscribe(
      res => {
        if (res.status === 200) {
          this.globalVariables.OOFSelected = {};
          this.alertService.success('New Property added successfully!');
          if (resetFormFlag === true) {
            form.resetForm();
          }

          $('html, body').animate(
            {
              scrollTop: 0,
            },
            'slow',
          );
          const response = res.body;
          const { address } = response.data;
          setTimeout(() => {
            /** ******** HWR-2936 Redirect to product details/listing page after property addition - Start */
            if (this.globalVariables.routeReferer) {
              /* Logic to change referer if location code is changed */
              let urlOperation: any = this.globalVariables.routeReferer.split('/');
              const micrositeNames = this.sessionStorageService.getItem('micrositeName')
                ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
                : environment.micrositeNames;
              // urlOperation = urlOperation.filter(item => !environment.micrositeNames.includes(item) && item !== "");
              urlOperation = urlOperation.filter(item => !micrositeNames.includes(item) && item !== '');
              if (this.isPropertyExist === 'no' && urlOperation.length > 0 && ['products', 'product'].includes(urlOperation[0])) {
                if (address && address.serviceAddress.zipcode !== urlOperation[1]) {
                  urlOperation[1] = address.serviceAddress.zipcode;
                }
                if (this.locationCodeForReferer && this.locationCodeForReferer.toLowerCase() !== urlOperation[2]) {
                  urlOperation[2] = this.locationCodeForReferer.toLowerCase();
                }
                this.globalVariables.routeReferer = urlOperation.join('/');
                this.router.navigate([this.globalVariables.routeReferer]);
              } else {
                this.router.navigate(['/user/myaccount'], {
                  queryParams: {
                    zipcode: address.serviceAddress.zipcode,
                    location_code: address.serviceAddress.locationCodeId,
                    addressId: address._id,
                  },
                });
              }
              // this.router.navigate([this.globalVariables.routeReferer]);
              /** ******** HWR-2936 Redirect to product details/listing page after property addition - End */
            } else {
              this.router.navigate(['/user/myaccount'], {
                queryParams: {
                  zipcode: address.serviceAddress.zipcode,
                  location_code: address.serviceAddress.locationCodeId,
                  addressId: address._id,
                },
              });
            }
          }, 2000);
        } else if (res.status === 409) {
          this.alertData.title = 'Information';
          this.alertData.message = 'Property already exists';
          this.alertData.alertIcon = 'fa fa-info-circle red';
          this.alertData.cancelText = 'Cancel';
          this.alertData.confirmText = 'Ok';
          this.showAlertModal(this.alertData);
        }
        this.showLoader = false;
      },
      () => {
        this.showLoader = false;
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          'slow',
        );
        this.alertService.error('Something went wrong. Please try again letter');
      },
    );
  }

  showAlertModal(data?: any, done?: any) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: data.title,
          message: data.message,
          alertIcon: data.alertIcon,
          cancelText: data.cancelText,
          confirmText: data.confirmText,
          confirmBtnId: data.confirmBtnId ? data.confirmBtnId : 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          if (done) {
            done(false);
          }
        }
      });
  }

  onZipcodeUpdated(data: any) {
    if (data) {
      this.address.zipcodeId = data.zipcodeId;
      this.address.locationCodeId = data.locationCodeId;
      this.sessionStorageService.setItem('location_code', data.locationCodeId);
      this.zipcodeValid = true;
      this.stateCode = data.stateCode;
      let oofLocationCode = '';
      if (this.address) {
        this.address.state = find(this.states, {
          key: data.stateCode,
        }).value;
        oofLocationCode = `${this.address.state}150`;
      }
      if (this.sessionStorageService.getItem('providerSelected') !== '1') {
        this.showLoader = true;
        this.googlePlacesService.getCityAndStateData(this.address.zipcode).subscribe(
          result => {
            this.autoCompleteSearchCallback(result.body.results[0]);
            this.productService.getProviderContent(data.locationCodeId).subscribe(dataForLocationR => {
              let dataForLocation = dataForLocationR.body;
              this.locationCodeForReferer = dataForLocation.locationCode;
              if (oofLocationCode !== dataForLocation.locationCode && this.homeOwner.offbillOnly && this.isPropertyExist === 'no') {
                this.productService.getLocationCode(oofLocationCode).subscribe(dataForOofLocationCode => {
                  dataForLocation = dataForOofLocationCode.body[0];
                  this.address.locationCodeId = dataForLocation.locationCodeId;
                  this.locationCodeForReferer = dataForLocation.locationCode;
                  this.updateDataForLocation(dataForLocation);
                });
              } else {
                this.updateDataForLocation(dataForLocation);
              }
            });
          },
          () => {
            // error in google service
            this.showLoader = false;
          },
        );
      }
    } else {
      this.zipcodeValid = false;
      this.sessionStorageService.deleteItem('providerSelected');
      this.sessionStorageService.deleteItem('isProvider');
      this.sessionStorageService.deleteItem('websitePartnerName');
    }
  }

  openModalPopup(selectedZipcode: String) {
    this.providerAvailable = true;
    this.simpleModalService
      .addModal(
        ProviderModalComponent,
        {
          zipcode: selectedZipcode,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.showProvider = true;
          this.providerSelected = true;
          this.productService.setCategories();
          this.globalVariables.OOFSelected.isOOFLocationAddProp = 'false';
          //  this.goToPage(1);
        } else if (this.backPress) {
          this.backPress = false;
        } else {
          this.partnerName = '';
          this.sessionStorageService.setItem('websitePartnerName', '');
          this.providerSelected = false;
          this.globalVariables.OOFSelected.isOOFLocationAddProp = 'true';
          this.notMyProvider();
        }
        if (this.address.state.toLowerCase() === 'ca') {
          this.ccpaUpdatesService.getCCPAUpdates();
        }
      });
  }

  notMyProvider() {
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    this.selectedCity = '';
    if (savedAddress) {
      const address = JSON.parse(savedAddress);
      if (address.rawData && address.rawData.address_components.length) {
        this.selectedCity = address.rawData.address_components[1].short_name;
      }
    }
    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      data => {
        if (data.length > 0) {
          this.address.locationCodeId = data[0].locationCodeId;
          this.locationCodeForReferer = data[0].locationCode;
          this.sessionStorageService.setItem('location_code', data[0].locationCodeId);
          this.productService.setCategories();
          this.locationCode = data[0].locationCodeId;
        } else {
          this.getLocationDataState();
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getLocationDataState() {
    this.selectedCity = '';
    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      dataReceived => {
        if (dataReceived.length > 0) {
          this.address.locationCodeId = dataReceived[0].locationCodeId;
          this.locationCodeForReferer = dataReceived[0].locationCode;
          this.sessionStorageService.setItem('location_code', dataReceived[0].locationCodeId);
          this.productService.setCategories();
          this.locationCode = dataReceived[0].locationCodeId;
        } else {
          this.getLocationCodeCrm(this.locationCodeData.locationCode);
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getLocationCodeCrm(locationCode: any) {
    this.productService.getLocationDataCRM(locationCode).subscribe(
      locationR => {
        const location = locationR.body;
        this.address.locationCodeId = location.LocationDetails[0].Id;
        this.locationCodeForReferer = location.LocationDetails[0].Code;
        this.sessionStorageService.setItem('location_code', location.LocationDetails[0].Id);
        this.globalVariables.OOFSelected.isOOFLocationAddProp = 'true';
        this.locationCode = location.LocationDetails[0].Id;
        this.productService.setCategories();
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  autoCompleteSearchCallback(data: any) {
    if (data) {
      if (data) {
        for (let i = 0; i < data.address_components.length; i++) {
          for (let j = 0; j < data.address_components[i].types.length; j++) {
            if (data.address_components[i].types[j] === 'postal_code') {
              this.selectedAddress.rawData = data;
              this.selectedAddress.zipcode = this.strAddress;
              this.validAddress = false;
              this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
            }
          }
        }
      }
    } else {
      this.sessionStorageService.deleteItem('location_code');
      this.sessionStorageService.deleteItem('selectedAddress');
    }
  }

  stateChangeHandler(event: any) {
    if (this.stateCode && this.stateCode !== event.key) {
      const alertData = {
        title: 'Error',
        // tslint:disable-next-line:max-line-length
        message: `Zipcode does not exist in selected state, Please select ${
          find(this.states, {
            key: this.stateCode,
          }).value
        } to proceed.`,
        alertIcon: 'fa fa-exclamation-circle red',
        cancelText: 'Cancel',
        confirmText: 'Ok',
      };
      this.showAlertModal(alertData);
    }
  }

  setContactType(type) {
    this.address.contactType = type;
    this.isClicked = false;
  }

  propertyValidate(answer: any) {
    this.isPropertyExist = answer;
    if (answer === 'no') {
      this.showPropForm = true;
      this.homeOwner.isHomeOwner = null;
      this.homeOwner.allowRental = null;
      this.homeOwner.offbillOnly = null;
    } else if (this.homeOwner.isHomeOwner === true) {
      this.showPropForm = true;
    } else {
      this.showPropForm = false;
    }
  }

  blurHandler(input: any) {
    input.isBlur = true;
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      if (input.name === 'phoneNumberExisting') {
        this.phoneNumberExistingErrorMsg = 'Please enter 10 digit number';
      } else if (input.name === 'businessPhone') {
        this.businessPhoneErrorMsg = 'Please enter 10 digit number';
      }
      return false;
    }
    if (input.name === 'phoneNumberExisting') {
      this.phoneNumberExistingErrorMsg = '';
    } else if (input.name === 'businessPhone') {
      this.businessPhoneErrorMsg = '';
    }

    return true;
  }

  keyPressHandler(input: any) {
    input.isBlur = false;
  }

  getSmartAddress(address, done: any) {
    this.crmProxyService.getSmartAddress(address.streetNumber, address.streetName || '', address.zipcode, address.city, address.state).subscribe(
      res => {
        const data = res.body;
        if (data.length && data[0].components) {
          const addressLine1 = data[0]?.delivery_line_1;
          const addressLine2 = data[0]?.delivery_line_2 ? `${data[0].delivery_line_2}, ` : '';
          const smartAddress = `${addressLine1},${addressLine2}${data[0]?.components?.city_name},`;
          const userAddress = `${address.streetNumber.trim()},${address.streetName ? `${address.streetName.trim()}, ` : ''}${address.city.trim()},`;
          if (
            !(
              smartAddress === userAddress &&
              address.zipcode === data[0].components.zipcode &&
              address.city.trim() === data[0].components.city_name &&
              address.state === data[0].components.state_abbreviation
            )
          ) {
            this.simpleModalService
              .addModal(
                AddressValidationModalComponent,
                {
                  inputAddress: {
                    streetNumber: address.streetNumber.trim(),
                    streetName: address.streetName.trim(),
                    city: address.city.trim(),
                    state: address.state.trim(),
                    zipcode: address.zipcode.trim(),
                  },
                  suggestedAddress: data[0],
                },
                {
                  wrapperClass: 'modal-main-class in',
                  closeOnClickOutside: false,
                },
              )
              .subscribe(isConfirmed => {
                if (isConfirmed) {
                  done(isConfirmed);
                } else if (
                  address.zipcode === data[0].components.zipcode &&
                  address.city.toLowerCase() === data[0].components.city_name.toLowerCase()
                ) {
                  done(isConfirmed);
                } else {
                  this.alertData.title = "We're sorry, but we could not locate the property address you have entered.";
                  const alertMsg = `Please contact Customer Service at <span class="app-txt">
                          ${this.customer_service_number}</span> to verfiy your coverage.`;
                  this.alertData.message = alertMsg;
                  this.alertData.alertIcon = 'fa fa-info-circle red';
                  this.alertData.cancelText = ' ';
                  this.alertData.confirmText = 'Continue';
                  this.showAlertModal(this.alertData);
                }
              });
          } else {
            done();
          }
        } else {
          const GAErrorId = 'ga_track_customer_address_validation_fail';
          this.alertData.title = '';
          const alertMsg =
            'Please check your address, our system indicates that this is not a valid service address. <br></br> If you are continuing to experience issues, please call 1-855-800-5195 to speak to a customer service representative.';
          this.alertData.message = alertMsg;
          this.alertData.alertIcon = 'fa fa-info-circle red';
          this.alertData.cancelText = ' ';
          this.alertData.confirmText = 'Continue';
          this.alertData.confirmBtnId = GAErrorId || 'close';
          this.showAlertModal(this.alertData);
        }
      },
      () => {
        done(undefined);
      },
    );
  }

  getSuggestion(event: any) {
    this.crmProxyService.getAddressSuggestion(event.target.value).subscribe(res => {
      this.suggestedAddress = res.body;
      this.showDropDown = true;
    });
  }

  closeDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  openDropDown() {
    this.showDropDown = false;
  }

  // -register component code start
  getSmartAddress2(strNo, strtName, state, city, zipcode, done: any) {
    this.crmProxyService.getSmartAddress(strNo, strtName, zipcode, city, state).subscribe(
      res => {
        const data = res.body;
        if (data.length && data[0].components) {
          const addressLine1 = data[0]?.delivery_line_1;
          const addressLine2 = data[0]?.delivery_line_2 ? `${data[0].delivery_line_2}, ` : '';
          const smartAddress: string = `${addressLine1},${addressLine2}${data[0]?.components?.city_name},`;
          const userAddress: string = `${strNo.trim()},${strtName ? `${strtName.trim()}, ` : ''}${city.trim()},`;
          if (
            !(
              smartAddress === userAddress &&
              zipcode === data[0].components.zipcode &&
              city.trim() === data[0].components.city_name &&
              state === data[0].components.state_abbreviation
            )
          ) {
            this.simpleModalService
              .addModal(
                AddressValidationModalComponent,
                {
                  inputAddress: {
                    streetNumber: strNo.trim(),
                    streetName: strtName.trim(),
                    city: city.trim(),
                    state: state.trim(),
                    zipcode: zipcode.trim(),
                  },
                  suggestedAddress: data[0],
                },
                {
                  wrapperClass: 'modal-main-class in',
                  closeOnClickOutside: false,
                },
              )
              .subscribe(isConfirmed => {
                done(isConfirmed);
              });
          } else {
            done();
          }
        } else {
          const GAErrorId = 'ga_track_customer_address_validation_fail';
          this.alertData.title = '';
          const alertMsg =
            'Please check your address, our system indicates that this is not a valid service address. <br></br> If you are continuing to experience issues, please call  1-855-800-5195 to speak to a customer service representative.';
          this.alertData.message = alertMsg;
          this.alertData.alertIcon = 'fa fa-info-circle red';
          this.alertData.cancelText = ' ';
          this.alertData.confirmText = 'Continue';
          this.alertData.confirmBtnId = GAErrorId || 'close';
          this.showAlertModal(this.alertData);
        }
      },
      () => {
        done(undefined);
      },
    );
  }

  shuffle(array) {
    let currentIndex = array.length;
    let temporaryValue = {};
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  callSearchApi(apiCall, searchApiCall) {
    searchApiCall.subscribe(data => {
      const customerData = data.body;
      this.existingCustomerData = customerData[0];
      this.setExistingCustomerData(this.existingCustomerData);
      this.showLoader = false;
    });
  }

  getZipcodeData(zipcodes, done: any) {
    forkJoin(zipcodes).subscribe(
      zipcodesData => {
        const zipcodeData: any = [];
        (zipcodesData as any).forEach(ele => {
          zipcodeData.push(ele.body);
        });
        done(zipcodeData);
      },
      () => {
        this.showLoader = false;
        this.showAlertModal({
          title: "We're sorry, there was a problem attempting to create your account.",
          message: `Please contact Customer Service at <span class="app-txt">
              ${this.customer_service_number}</span>`,
          alertIcon: 'fa fa-info-circle red',
          cancelText: ' ',
          confirmText: 'Ok',
        });
      },
    );
  }

  setExistingCustomerData(existingCustomer: any) {
    this.existingUser.accountnumber = existingCustomer.Customer_Number;

    if (existingCustomer.telephone) {
      this.existingUser.phoneNumber = existingCustomer.telephone.phoneNumber1;
      this.existingUser.phoneNumber2 = existingCustomer.telephone.phoneNumber2 || '';
    }

    if (existingCustomer.Email) {
      this.existingUser.email = existingCustomer.Email;
    } else if (existingCustomer.Email2) {
      this.existingUser.email = existingCustomer.Email2;
    } else if (existingCustomer.Email3) {
      this.existingUser.email = existingCustomer.Email3;
    }

    this.existingUser.firstName = existingCustomer.First_Name || '';
    this.existingUser.lastName = existingCustomer.Last_Name || '';
    this.existingUser.middleName = existingCustomer.Middle_Name || '';

    const contactType = existingCustomer.Contact_Type ? existingCustomer.Contact_Type : 1;
    let setContactType: any = '';
    if (contactType === 3 || contactType === 7) {
      setContactType = 'COMMERCIAL';
    } else {
      setContactType = 'RESIDENTIAL';
    }

    this.existingUser.address[0].customerNo = existingCustomer.Customer_Number;
    this.existingUser.address[0].customerGuid = existingCustomer.Customer;
    this.existingUser.address[0].waterBillOnOff = existingCustomer.WaterBillOnOff || true;
    const serviceStateVal = find(this.states, {
      key: existingCustomer.Service_address.Service_State.toString(),
    });
    const billingStateVal = find(this.states, {
      key: existingCustomer.Billing_Address.Billing_State.toString(),
    });
    this.existingUser.address[0].ownOrRentedValidated = existingCustomer.OwnOrRentValidated
      ? existingCustomer.OwnOrRentValidated.toString()
      : 'false';
    this.existingUser.address[0].noWaterBillConversion = existingCustomer.NoWaterBillConversion
      ? existingCustomer.NoWaterBillConversion.toString()
      : 'false';

    this.existingUser.address[0].serviceAddress = {
      streetName: existingCustomer.Service_address.Service_street2 || '',
      streetNumber: existingCustomer.Service_address.Service_Street_Number.toString(),
      city: existingCustomer.Service_address.Service_City,
      state: serviceStateVal.value,
      zipcode: existingCustomer.Service_address.Service_zipcode,
      contactType: setContactType,
    };

    this.existingUser.address[0].billingAddress = {
      streetName: existingCustomer.Billing_Address.Billing_street2 || '',
      streetNumber: existingCustomer.Billing_Address.Billing_Street_Number.toString(),
      city: existingCustomer.Billing_Address.Billing_City,
      state: billingStateVal.value,
      zipcode: existingCustomer.Billing_Address.Billing_zipcode,
      contactType: setContactType,
    };
    if (contactType === 3 || contactType === 7) {
      const commercialFields = {
        typeOfBusiness: this.businessTypeMapping[existingCustomer.Business_Type],
        businessName: existingCustomer.Business_Name,
        contactTitle: this.userTypeMapping[existingCustomer.User_Type],
        businessPhone: this.existingUser.phoneNumber,
      };
      Object.assign(this.existingUser.address[0].serviceAddress, commercialFields);
      Object.assign(this.existingUser.address[0].billingAddress, commercialFields);
    }

    const zipcodes = [];
    if (existingCustomer.Service_address.Service_zipcode === existingCustomer.Billing_Address.Billing_zipcode) {
      zipcodes.push(this.zipcodeService.getZipcodeId(existingCustomer.Service_address.Service_zipcode));
    } else {
      zipcodes.push(this.zipcodeService.getZipcodeId(existingCustomer.Service_address.Service_zipcode));
      zipcodes.push(this.zipcodeService.getZipcodeId(existingCustomer.Billing_Address.Billing_zipcode));
    }
    /* HWR-2485 Start */
    /* this.userService.verifyPhoneNumber(this.existingUser.phoneNumber).subscribe((data) => {
      if (data.status === 200) {
        this.showAlertModal({
          title: `We're sorry, we are having difficulty verifying your account information`,
          message: `Phone number is already exists in our system.<br>
          Please contact our customer service team at <span class="app-txt">${this.customer_service_number}</span>`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ` `,
          confirmText: `Ok`
        });
      }
    }, (error) => {

    }); */
    this.getZipcodeData(zipcodes, zipcodedata => {
      if (existingCustomer.NoWaterBillConversion && existingCustomer.NoWaterBillConversion === true) {
        const oofLocationCode = `${this.existingUser.address[0].serviceAddress.state}150`;
        this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLocation => {
          if (oofLocationCode !== dataForLocation.body.locationCode) {
            this.productService.getLocationCode(oofLocationCode).subscribe(dataForOofLocationCode => {
              zipcodedata[0].locationCodeId = dataForOofLocationCode.body[0].locationCodeId;
            });
          }
        });
      }

      this.existingUser.address[0].serviceAddress.zipcodeId = zipcodedata[0].zipCodeId;
      this.existingUser.address[0].serviceAddress.locationCodeId = zipcodedata[0].locationCodeId;
      if (zipcodedata.length === 1) {
        this.existingUser.address[0].billingAddress.zipcodeId = zipcodedata[0].zipCodeId;
        this.existingUser.address[0].billingAddress.locationCodeId = zipcodedata[0].locationCodeId;
      } else if (zipcodedata.length === 2) {
        this.existingUser.address[0].billingAddress.zipcodeId = zipcodedata[1].zipCodeId;
        this.existingUser.address[0].billingAddress.locationCodeId = zipcodedata[1].locationCodeId;
      }

      // Adding oracle ids
      if (existingCustomer.Cust_Id || existingCustomer.OracleCustId) {
        this.existingUser.address[0].oracleCustId = existingCustomer.Cust_Id || existingCustomer.OracleCustId;
      }
      if (existingCustomer.Account_Number || existingCustomer.OracleAccountNumber) {
        this.existingUser.address[0].oracleAccountNumber = existingCustomer.Account_Number || existingCustomer.OracleAccountNumber;
      }

      this.currentPageNumber += 1;
      this.step1Access = false;
      this.step2Access = true;
      this.step3Access = false;
      if (this.step2Access === true) {
        this.addNewServiceAddress2(existingCustomer);
      }
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        1000,
      );
    });
    /* HWR-2485 End */
  }

  showCustomerServiceValidationModal(data: any, existingCodes: any, apiCall = 0, modalFor = 0, title = '') {
    if (!title) {
      title = 'In order to better protect your account please select the program you are currently enrolled in:';
    }
    this.simpleModalService
      .addModal(
        CustomerServiceValidationModalComponent,
        {
          title,
          message: 'In progress',
          alertIcon: 'fa fa-info-circle red',
          cancelText: 'Cancel',
          confirmText: 'Continue',
          data,
          modalFor,
          existing: existingCodes,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed && !modalFor) {
          this.setExistingCustomerData(this.existingCustomerData);
        } else if (!isConfirmed && isConfirmed !== undefined && !modalFor) {
          this.showAlertModal({
            title: "We're sorry, the enrollment you selected does not match with the information on your account.",
            message: `Please contact Customer Service at <span class="app-txt">
                ${this.customer_service_number}</span> to confirm your enrollment to complete registration.`,
            alertIcon: 'fa fa-exclamation-circle red',
            cancelText: ' ',
            confirmText: 'Ok',
          });
        } else if (isConfirmed && modalFor) {
          this.callSearchApi(apiCall, this.searchApiCall);
        } else if (!isConfirmed && isConfirmed !== undefined && modalFor) {
          this.showAlertModal({
            title: "We're sorry, the name you selected does not match with the information on your account.",
            message: `Please contact Customer Service at <span class="app-txt">
                ${this.customer_service_number}</span> to confirm account holder information to complete registration.`,
            alertIcon: 'fa fa-exclamation-circle red',
            navigate: 'yes',
            cancelText: ' ',
            confirmText: 'Ok',
          });
        }
      });
  }

  generateName(apiCall, customerData) {
    const fakeName = `${this.chance.first()} ${this.chance.last()}`;
    const originalName = this.titlecasePipe.transform(`${customerData.First_Name} ${customerData.Last_Name}`);
    const title = 'Security is important to us. For your protection please verify the name on the account';
    const data = [
      {
        name: fakeName,
      },
      {
        name: originalName,
      },
      {
        name: this.titlecasePipe.transform(`${fakeName.substring(0, fakeName.indexOf(' '))} ${customerData.Last_Name}`),
      },
      {
        name: this.titlecasePipe.transform(`${customerData.First_Name} ${fakeName.substr(fakeName.indexOf(' ') + 1)}`),
      },
    ];
    this.showCustomerServiceValidationModal(this.shuffle(data), originalName, apiCall, 1, title);
  }

  selectNameModal(apiCall, apiResponse) {
    apiResponse.subscribe(
      data => {
        const customerData = data.body;
        if (customerData[0].Last_Name.toLowerCase() === this.customer.user.lastName.toLowerCase()) {
          this.generateName(apiCall, customerData[0]);
        } else {
          this.alertData.title = "We're sorry, but property doesn't match to your Account.";
          const alertMsg = `Please contact Customer Service at <span class="app-txt">
                ${this.customer_service_number}</span> to verfiy your coverage.`;
          this.alertData.message = alertMsg;
          this.alertData.alertIcon = 'fa fa-info-circle red';
          this.alertData.cancelText = ' ';
          this.alertData.confirmText = 'Continue';
          this.showAlertModal(this.alertData);
        }
        this.showLoader = false;
      },
      error => {
        let errorMassgae = '';
        let errorName = '';
        if (apiCall === 2) {
          const apiError = error.error;
          errorName = apiError.error.name;
        } else {
          errorName = '';
        }
        switch (errorName) {
          case 'NameNotFoundError':
            errorMassgae = `Account holder name does not match.
          Please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span>`;
            break;
          case 'MultipleCustomersFoundError':
            errorMassgae = `We're sorry, but we are having difficulty creating your account as our records indicate there are multiple
               accounts in your name. Please contact Customer Service at ${this.customer_service_number} to complete your registration.`;
            break;
          case 'AddressNotFoundError':
          case 'InvalidAddressError':
            errorMassgae = `Property address could not be verified.
          Please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span>`;
            break;
          default:
            errorMassgae = `Account holder name or Property address could not be verified.
          Please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span>`;
        }
        this.showAlertModal({
          title: "We're sorry, we are having difficulty verifying your account information.",
          message: errorMassgae,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Ok',
        });
        this.showLoader = false;
      },
    );
  }

  accountNumberKeyPressHandler(form) {
    if (form.valid) {
      let apiCall: number;
      this.showLoader = true;
      this.invalidForm = false;
      this.isClickedForm = false;
      // sending state name in query
      let query = form.value.accountNumber ? 'skip_schema_validation=yes' : '';
      if (!form.value.streetNameExisting) {
        form.value.streetNameExisting = '';
      }
      // let searchApiCall: any = '';
      if (form.value.accountNumber) {
        this.userService.searchExistingCustomerByCustomerNumber(form.value.accountNumber.trim()).subscribe(data => {
          this.customerExistInHOS = data.body.customerExist;
          if (!this.customerExistInHOS) {
            apiCall = 1;
            this.searchApiCall = this.userService.searchCustomerById(form.value.accountNumber.trim(), query);
            this.selectNameModal(apiCall, this.searchApiCall);
            // this.callSearchApi(apiCall, searchApiCall);
          }
          this.showLoader = false;
        });
      } else {
        apiCall = 2;
        this.getSmartAddress2(
          form.value.streetNumberExisting,
          form.value.streetNameExisting || '',
          this.addressLookup.stateName,
          form.value.cityExisting,
          this.addressLookup.zipcode,
          upDateAddress => {
            if (!upDateAddress) {
              query = `street=${encodeURIComponent(form.value.streetNameExisting.trim())}
    &street_number=${encodeURIComponent(form.value.streetNumberExisting.trim())}
    &city=${encodeURIComponent(form.value.cityExisting.trim())}&zip_code=${encodeURIComponent(this.addressLookup.zipcode.trim())}
    &state=${encodeURIComponent(this.addressLookup.stateName.trim())}`;
            } else {
              query = `street=${encodeURIComponent(upDateAddress.streetName.trim())}
    &street_number=${encodeURIComponent(upDateAddress.streetNumber.trim())}
    &city=${encodeURIComponent(upDateAddress.city.trim())}&zip_code=${encodeURIComponent(upDateAddress.zipcode.trim())}
    &state=${encodeURIComponent(upDateAddress.state.trim())}`;
            }
            this.searchApiCall = this.userService.searchCustomerByAddress(query);
            this.selectNameModal(apiCall, this.searchApiCall);
            // this.callSearchApi(apiCall, searchApiCall);
          },
        );
      }
    } else {
      this.showLoader = false;
    }
  }

  onZipcodeUpdatedLookup(data: any) {
    if (data) {
      this.addressLookup.zipcodeId = data.zipcodeId;
      this.addressLookup.locationCodeId = data.locationCodeId;
      this.addressLookup.state = data.stateCode;
      const stateName = find(this.states, {
        key: this.addressLookup.state,
      });
      this.addressLookup.stateName = stateName.value;
      this.zipcodeSet = true;
    } else {
      this.zipcodeSet = false;
      this.addressLookup.stateName = '';
    }
  }

  formKeyPressHandler(event: any, newProperty = false) {
    if (newProperty && this.customerNumberForm && this.customerNumberForm.form.controls.accountNumber.value === '') {
      this.customerNumberForm.form.markAsPristine();
    }
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.preventDefault();
      return false;
    }
  }

  // - new function for adding new service address
  addNewServiceAddress2(existing_user: any) {
    this.sessionStorageService.setItem('providerSelected', '1');
    this.sessionStorageService.setItem('isProvider', '1');
    this.isClicked = true;
    if (existing_user.Contact_Type === 7 || existing_user.Contact_Type === 3) {
      this.address.contactType = 'COMMERCIAL';
    }
    if (!this.address.streetName) {
      this.address.streetName = '';
    }
    this.address.streetNumber = this.existingUser.address[0].serviceAddress.streetNumber;
    this.address.streetName = this.existingUser.address[0].serviceAddress.streetName || '';
    this.address.zipcode = this.existingUser.address[0].serviceAddress.zipcode;
    this.address.city = this.existingUser.address[0].serviceAddress.city;
    this.address.state = this.existingUser.address[0].serviceAddress.state;
    this.address.zipcodeId = this.existingUser.address[0].serviceAddress.zipcodeId;

    this.showLoader = true;
    this.isClicked = false;
    if (this.existingUser.address[0].serviceAddress.contactType === 'COMMERCIAL') {
      this.address.typeOfBusiness = this.business.businessType;
      this.address.businessName = this.business.businessName;
      this.address.contactTitle = this.business.contactTitle;
      this.address.businessPhone = this.business.businessPhone;
    }
    this.address.locationCodeId = this.existingUser.address[0].serviceAddress.locationCodeId;
    const userAddress = JSON.parse(JSON.stringify(this.address));

    const item = this.states.find(p => p.value === this.address.state);
    this.newAddress = {
      ...this.address,
    };
    this.newAddress.stateId = item.key;
    const payloadValidateProperty = {
      serviceAddress: this.existingUser.address[0].serviceAddress,
      billingAddress: this.existingUser.address[0].billingAddress,
      isBillingAddressSameAsServiceAddress: true,
    };

    this.checkDuplicateProperty(payloadValidateProperty, status => {
      if (status) {
        const alertData = {
          title: '',
          message: `Our records indicate this property already exists in our system.
                          <b> Do you want to use this address?</b>`,
          alertIcon: 'fa fa-info-circle red',
          cancelText: 'NO',
          confirmText: 'YES',
        };
        this.showAlertModal(alertData);
        this.showLoader = false;
      } else {
        this.validateProperty(payloadValidateProperty, (dataStatus, data) => {
          this.showLoader = false;

          let ownOrRentedValidated = 'true';
          let noWaterBillConversion = 'false';

          if (this.isPropertyExist === 'no') {
            if (existing_user && existing_user.value && existing_user.value.rental === true && existing_user.value.tenantBill === true) {
              noWaterBillConversion = 'true';
            }
          } else if (data !== undefined && data.NoWaterBillConversion === true) {
            const oofLocationCode = `${this.address.state}150`;
            this.productService.getProviderContent(this.address.locationCodeId).subscribe(dataForLocation => {
              if (oofLocationCode !== dataForLocation.body.locationCode) {
                this.productService.getLocationCode(oofLocationCode).subscribe(dataForOofLocationCode => {
                  this.address.locationCodeId = dataForOofLocationCode.body[0].locationCodeId;
                });
              }
            });

            ownOrRentedValidated = data.OwnOrRentValidated.toString();
            noWaterBillConversion = data.NoWaterBillConversion.toString();
          }
          const payload: any = {
            serviceAddress: userAddress,
            billingAddress: userAddress,
            isBillingAddressSameAsServiceAddress: true,
            ownOrRentedValidated,
            noWaterBillConversion,
            // [HWR-2723 Feilds Added For Existing Customer Property Registration]
            firstName: this.existingUser.firstName || '',
            lastName: this.existingUser.lastName,
          };

          payload.providerAvailable = this.providerAvailable;
          if (this.providerAvailable) {
            payload.providerSelected = this.providerSelected;
          }

          if (dataStatus) {
            payload.customerNo = this.existingUser.address[0].customerNo;
            payload.waterBillOnOff = this.existingUser.address[0].waterBillOnOff;
            payload.customerGuid = existing_user.Customer;
            if (this.existingUser.address[0].oracleCustId) {
              payload.oracleCustId = this.existingUser.address[0].oracleCustId;
            }
            if (this.existingUser.address[0].oracleAccountNumber) {
              payload.oracleAccountNumber = this.existingUser.address[0].oracleAccountNumber;
            }
            /** *** HWR - 1445 */
            if (existing_user.LocationCodeId) {
              this.locationService.getLocationsContent(existing_user.LocationCodeId).subscribe(
                dataReceivedRes => {
                  const dataReceived = dataReceivedRes.body;
                  if (dataReceived && dataReceived.locationCode.includes('150')) {
                    payload.providerAvailable = true;
                    payload.providerSelected = true;
                  }
                  this.addCustomerAddress(payload, this.userDetails.user._id, existing_user, false);
                },
                () => {
                  this.showLoader = false;
                  this.alertService.error('Something went wrong. Please try again letter');
                },
              );
            } else {
              this.addCustomerAddress(payload, this.userDetails.user._id, existing_user, false);
            }
            /** *** HWR - 1445 */
            this.showLoader = false;
          } else {
            if (this.isPropertyExist === 'yes') {
              this.alertData.title = "We're sorry, but we could not locate the property address you have entered.";
              const alertMsg = `Please contact Customer Service at <span class="app-txt">
                                      ${this.customer_service_number}</span> to verfiy your coverage.`;
              this.alertData.message = alertMsg;
              this.alertData.alertIcon = 'fa fa-info-circle red';
              this.alertData.cancelText = ' ';
              this.alertData.confirmText = 'Continue';
              this.showAlertModal(this.alertData);
            }
            if (this.isPropertyExist === 'no') {
              this.addCustomerAddress(payload, this.userDetails.user._id, existing_user, true);
            }
          }
        });
      }
    });
  }

  selectValue(address1, city, form) {
    if (form === 1) {
      this.user.address[0].serviceAddress.streetNumber = address1;
      this.user.address[0].serviceAddress.city = city;
    } else {
      this.addressLookup.streetNumber = address1;
      this.addressLookup.city = city;
    }
    // this.stateForm.patchValue({"search": value});
    this.showDropDown = false;
  }

  // -register component code end
  allowRegistration(state, value) {
    switch (state) {
      case 'owner':
        this.homeOwner.isHomeOwner = false;
        if (value) {
          this.homeOwner.isHomeOwner = true;
          this.showPropForm = true;
        } else {
          this.homeOwner.allowRental = null;
          this.homeOwner.offbillOnly = null;
          this.showPropForm = false;
        }
        break;
      case 'rental':
        // On no Normal registration
        this.homeOwner.allowRental = false;
        if (value) {
          this.homeOwner.allowRental = true;
        } else {
          this.homeOwner.offbillOnly = null;
          this.enableProviderPopup = true;
          if (this.zipcode && this.onZipcodeUpdatedData) {
            this.checkForProviderZipUpdate(this.onZipcodeUpdatedData);
          }
        }
        break;
      default:
        // tenantBill
        // On no Normal registration
        // On YES only offbill Product available for purchase
        this.homeOwner.offbillOnly = false;
        if (value) {
          this.homeOwner.offbillOnly = true;
        }

        this.enableProviderPopup = true;
        if (this.zipcode && this.onZipcodeUpdatedData) {
          this.checkForProviderZipUpdate(this.onZipcodeUpdatedData);
        }
    }
  }

  updateDataForLocation(dataForLocation) {
    this.showLoader = false;
    this.locationCodeData = dataForLocation;
    this.sessionStorageService.setItem('websitePartnerName', dataForLocation.websitePartnerName);
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    this.providerAvailable = false;
    /* HWR-1445 Don't show provider prompt if entered zipcode matches with zipcode stored from provider check and providerSelected is true */
    const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
      ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
      : '';
    if (dataForLocation.websitePartnerName !== '' && this.sessionStorageService.getItem('providerSelected') !== '1') {
      this.openModalPopup(this.address.zipcode);
    } else {
      if (providerSelectionDetails && providerSelectionDetails.zipCode !== this.address.zipcode) {
        this.sessionStorageService.setItem('providerSelectionDetails', '{}');
      }
      if (this.address.state.toLowerCase() === 'ca') {
        this.ccpaUpdatesService.getCCPAUpdates();
      }
    }
  }

  getUser(): Object {
    const token = this.sessionStorageService.getItem('authHeader');
    let user;
    if (token && this.isBrowser) {
      user = JSON.parse(atob(token.split(' ')[1].split('.')[1]));
    }
    return user;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkForProviderZipUpdate(data: any) {
    this.onZipcodeUpdatedData = data;
    if (this.zipcode && this.enableProviderPopup) {
      this.onZipcodeUpdated(data);
    } else if (!this.zipcode) {
      this.onZipcodeUpdated(data);
    }
  }

  /** ********** HWR-3118: Maintain same header in My Profile and Add Property sections - Start ******** */
  pageSwitch() {
    this.globalVariables.myAccClicked = false;
    this.router.navigate(['/user/myaccount']);
    const selectedAddress = this.sessionStorageService.getItem('selectedAddress')
      ? JSON.parse(this.sessionStorageService.getItem('selectedAddress'))
      : '';
    const locationcode = this.sessionStorageService.getItem('location_code');
    const addressId = this.sessionStorageService.getItem('addressId');
    const providerState = this.sessionStorageService.getItem('providerState');
    if (selectedAddress && selectedAddress.zipcode && locationcode && addressId) {
      const queryString = `${providerState || ''}/user/myaccount?zipcode=${
        selectedAddress.zipcode
      }&location_code=${locationcode}&addressId=${addressId}`;
      this.location.go('/user/myaccount', queryString);
    }
  }

  addressHandler(event) {
    if (event) {
      this.productService.getMarketingCodes().subscribe(
        data => {
          if (data.status === 200) {
            this.locationService
              .getLocationsContent(event.location_code || this.sessionStorageService.getItem('location_code'))
              .subscribe(dataReceived => {
                this.locationCodeData = dataReceived.body;
                this.locationService.getMicroSiteForLocation(this.locationCodeData.locationCode).subscribe(dataMicroSiteResponse => {
                  const dataMicroSite = dataMicroSiteResponse.body;
                  if (dataMicroSite.level === 'default') {
                    if (this.sessionStorageService.getItem('providerState')) {
                      this.sessionStorageService.setItem('isMicroSite', '0');
                      this.sessionStorageService.deleteItem('baseUrlState');
                      this.sessionStorageService.deleteItem('providerState');
                      window.location.href = `/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                    } else {
                      this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
                    }
                  } else {
                    this.sessionStorageService.setItem('isMicroSite', '1');
                    if (dataMicroSite.site.siteName) {
                      dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
                    } else {
                      dataMicroSite.site.siteName = '';
                    }

                    let providerState = '';
                    providerState = this.sessionStorageService.getItem('providerState')
                      ? this.sessionStorageService.getItem('providerState').toLowerCase()
                      : '';
                    if (providerState === dataMicroSite.site.siteName) {
                      this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
                    } else {
                      if (dataMicroSite.site.siteName) {
                        dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
                      } else {
                        dataMicroSite.site.siteName = '';
                      }
                      this.sessionStorageService.setItem('providerState', dataMicroSite.site.siteName);
                      this.sessionStorageService.setItem('baseUrlState', dataMicroSite.site.siteName);
                      window.location.href = `/${dataMicroSite.site.siteName}/products/${
                        event.zipcode
                      }/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                    }
                  }
                });
              });
          } else if (data.status !== 200) {
            this.showErrorModal('Error', 'No service available in your vicinity.');
          }
        },
        () => {
          this.simpleModalService.addModal(
            ErrorModalComponent,
            {
              title: 'We are sorry. We do not currently offer services in your location.',
              message: 'Please check back soon for product and service availability in your area.',
              showButton: 'close',
              alertType: 'info-type',
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: true,
            },
          );
        },
      );
    } else {
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(''));
    }
  }

  showErrorModal(title: string, message: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }
  /** ********** HWR-3118: Maintain same header in My Profile and Add Property sections - End ********** */

  onCustomerNumberChange() {
    this.customerExistInHOS = false;
  }
}
