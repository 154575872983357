<div class="my-account-page pb-4" [ngClass]="{ 'property-page': !myaccountHome }">
  <!-- Banner  -->
  <hos-my-account-new-header
    [pageName]="myaccountHome"
    [cusNo]="selectedAddress?.customerNo"
    [selectedAddress]="selectedAddress"
    [content]="headerContent"
    [firstName]="selectedAddress?.firstName"
    [lastName]="selectedAddress?.lastName"
    (myAccClick)="pageSwitch()"
    (addressHandler)="addressHandler($event)"
  ></hos-my-account-new-header>
  <!-- Banner  -->

  <!-- Carousel -->
  <section class="carousel-section page-wrap padd-zero-carouselPoint" *ngIf="myaccountHome && stories">
    <div class="row no-gutters mb-4">
      <div class="col-12 col-md-12">
        <!-- IE banner content issue fix March 17 AP -->
        <ngu-carousel [inputs]="carouselBanner" [dataSource]="stories" [moveToSlide]="1">
          <ngu-tile *nguCarouselDef="let story of stories ? stories : []" class="white-space-ie item PD-0">
            <div
              class="bannerStyle"
              [style.background]="
                screenWidth < 768
                  ? 'url(' + dotCMSService.dotCMSImageById(story?.bannerMobileImageURL) + ')'
                  : screenWidth >= 768 && screenWidth <= 1024
                  ? 'url(' + dotCMSService.dotCMSImageById(story?.bannerTabletImageURL) + ')'
                  : 'url(' + (dotCMSService.dotCMSImageById(story?.bannerImageURL) || 'img/hero_slider/AW_Hero_02.jpg') + ')'
              "
            >
              <div class="banner-area">
                <!-- <div class="banner-title mb-3">{{story?.title}}</div>
                                <div class="banner-contain text-justify mb-4">{{story?.content | slice:0:150}}
                                    <span *ngIf="story?.content.length > 150">...</span>
                                </div> -->
                <div class="banner-title mb-3" [innerHTML]="story?.title"></div>
                <div class="banner-contain text-justify mb-4">
                  <span [innerHTML]="story?.summary | slice : 0 : 150"></span><span *ngIf="story?.summary.length > 150">...</span>
                </div>
                <div class="read-more">
                  <a [routerLink]="story | blogIdPipe">Read more</a>
                </div>
              </div>
            </div>
          </ngu-tile>

          <!-- <ngu-item NguCarouselItem>
                        <div class="bannerStyle" [style.background]="'url(' + bannerImageURL2 + ')'">
                            <div class="banner-area">
                                <div class="banner-title mb-4">It’s a big step</div>
                                <div class="banner-contain text-justify mb-4">Being Good Neighbors Virginia American
                                    Water Eastern
                                    district employees J.R. Fidler and Kent Sanders, utility technicians, were featured
                                    on local radio station 107.5FM where they presented a check for $1000 to “Christmas
                                </div>
                                <div class="read-more">Read more</div>
                            </div>
                        </div>
                    </ngu-item> -->
          <button NguCarouselPrev class="leftRs"></button>
          <button NguCarouselNext class="rightRs"></button>
        </ngu-carousel>
      </div>
    </div>
  </section>
  <!-- Carousel -->

  <!-- Recommended Services -->
  <section class="services-recommendation-section page-wrap" *ngIf="myaccountHome">
    <div class="container-fluid row p-0 m-0">
      <div class="col-12 col-md-12 p-0">
        <div class="recommended-services">
          <div class="col-12 col-md-12 title-search-block">
            <div class="row">
              <div class="col-12 col-md-6 service-title">SERVICES RECOMMENDATIONS</div>
              <div class="col-12 col-md-6 search-select">
                <span class="hideMob">Services available in</span>
                <select [(ngModel)]="selectedAddIndex" (change)="changeAddress(selectedAddIndex, serviceAddresses[selectedAddIndex])">
                  <option *ngIf="!serviceAddresses?.length" [value]="null" disabled>No Property Added</option>
                  <option *ngFor="let address of serviceAddresses; let i = index" [value]="i">
                    {{ address.serviceAddress.streetNumber }} {{ address.serviceAddress.streetName }}, {{ address.serviceAddress.city }},
                    {{ address.serviceAddress.state }},
                    {{ address.serviceAddress.zipcode }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 recommended-property-block">
            <hos-other-recommended-new-services
              [customerNum]="selectedAddress?.customerNo"
              [locationId]="selectedAddress?.serviceAddress?.locationCodeId"
              [selectedAddress]="selectedAddress"
              [zipcode]="selectedAddress?.serviceAddress?.zipcode"
              [services]="myServices"
              [locationCodeData]="locationCodeData"
            >
            </hos-other-recommended-new-services>
            <!-- <hos-other-recommended-services [customerNum]="selectedAddress?.customerNo"
                            [locationId]="selectedAddress?.serviceAddress?.locationCodeId"
                            [selectedAddress]="selectedAddress" [zipcode]="selectedAddress?.serviceAddress?.zipcode" [carouselRecommendService]="carouselRecommendedService">
                        </hos-other-recommended-services> -->
          </div>
          <div class="col-12 col-md-12 see-more-options">
            <a (click)="goToProducts()">See more options</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Recommended Services -->

  <!-- Save more & contact -->
  <section class="save-contact-section page-wrap mt-4" *ngIf="myaccountHome">
    <div class="container-fluid row p-0 m-0">
      <div class="col-12 col-md-6 p-0">
        <div class="save-more-block">
          <img class="img-responsive" [src]="my_account_fb_img_url" alt="Facebook" />
          <div class="home-img-overlay">
            <div class="title" [innerHTML]="saveMoreCont?.title"></div>
            <div class="col-12 col-md-12 block-height">
              <div class="save-more-contain">
                <span [innerHTML]="saveMoreCont?.content | slice : 0 : 100"></span><span *ngIf="saveMoreCont?.content.length > 100">...</span>
              </div>
            </div>
            <div class="learn-more-btn">
              <button class="btn" *ngIf="!saveMoreCont?.sourceFeed" [routerLink]="'/blog/' + saveMoreCont?.entryId">Learn More</button>
              <a *ngIf="saveMoreCont?.sourceFeed" [href]="saveMoreCont?.sourceFeed" target="_blank"><button class="btn">Learn More</button></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 p-0">
        <div class="contact-us-block">
          <img class="img-responsive" [src]="contactUsBg" [alt]="contactUsCont?.factDescription" />
          <div class="home-img-overlay cms-img-margin">
            <div class="title">{{ contactUsCont?.factDescription }}</div>
            <div class="col-12 col-md-12 block-height">
              <div class="contact-us-contain" [innerHTML]="contactUsCont?.content"></div>
            </div>
            <div class="col-12 col-md-12">
              <a class="outline-btn" [href]="contactUsCont?.sourceFeed">{{ contactUsCont?.subTitle }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Save more & contact -->

  <!-- My Properties -->
  <section *ngIf="!myaccountHome">
    <div class="my-properties-select">
      <div class="row page-wrap">
        <div class="col-12 col-md-6 title">
          <img class="property-icon ml-2" alt="My Property" src="img/my-account/icons/Property-House-Icon.svg" />
          <span class="property-text-align ml-3">My Properties</span>
        </div>
      </div>
    </div>
  </section>

  <!-- My Enrollments -->

  <section class="enrolled-services-section page-wrap" *ngIf="!myaccountHome && serviceAddresses?.length">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="enrolled-services">
          <div class="col-12 col-md-12 pt-3 pr-0 pl-0 recommended-property-block">
            <hos-my-account-services-redesign
              [services]="myServices"
              [user]="user"
              [renewedEnrollments]="renewedEnrollments"
              [allAddresses]="serviceAddresses"
              [selectedEnrollAddIndex]="selectedEnrollAddIndex"
              [locationCodeData]="locationCodeData"
              (changeAddressEmitter)="changeAddressFunctionCall($event)"
            ></hos-my-account-services-redesign>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- My Claims -->

  <!-- <section class="my-properties-section page-wrap mt-3" *ngIf="!myaccountHome && serviceAddresses?.length">
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="my-properties-select">
                    <div class="row">
                        <div class="col-12 col-md-6 title">
                            <div class="row">
                                <div class="col-2">
                                    <div class="property-icon p-2 d-flex">
                                        <img alt="My Property" src="img/my-account-claims/ClaimIconNoBackground.svg" />
                                    </div>
                                </div>
                                <div class="col-10 property-text-align">
                                    My Claims
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hos-my-account-claims [productServices]="myServices" [customerId]="customerIdData"></hos-my-account-claims>
            </div>
        </div>
    </section> -->
</div>
<div [ngClass]="{ loading: showLoader }"></div>
