import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { nanoid } from 'nanoid';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../user/services/user.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { AlertConfirmModalComponent } from '../alert-confirm-modal/alert-confirm-modal.component';
import { LocationService } from '../../../home/services/location.service';
import { GlobalVariables } from '../../services/global-variables.service';
import { CrmProxyService } from '../../services/crm-proxy.service';
import { WindowScrolling } from '../../services/window-scrolling.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { SessionStorageService } from '../../services/session-storage.service';
import { PaymentMethod, PaymentPayload, Stripe } from '../../../models/payment/stripe';
import { ManagePaymentComponent } from '../../../user/component/manage-payment/manage-payment.component';

export interface EnrollmentModel {
  crmEnrollmentGuid: String;
  locationDetailsForOneTime?: any;
  OtherGuid?: String;
  userDetailsCheckout?: any;
  isFromCheckout?: any;
  inputProducts?: any[];
}

@Component({
  selector: 'hos-one-time-payment',
  templateUrl: './one-time-payment-oracle.component.html',
  styleUrls: ['./one-time-payment-oracle.component.scss'],
})
export class OneTimePaymentOracleComponent extends SimpleModalComponent<EnrollmentModel, Boolean> implements EnrollmentModel, OnInit {
  cuid: String;

  crmPaymentPlanId: String;

  crmCustomerGuid: string;

  financialInstrumentToken: String;

  crmEnrollmentGuid: String;

  isLoggedIn: String = 'true';

  paymentCards: any = [];

  achCards: any = [];

  setCard: any;

  setCardRequired: Boolean = true;

  paymentType: String;

  products: any;

  user: any;

  showLoader: Boolean = false;

  customerServiceNumber: String;

  currentDate: Date = new Date();

  userDetails: any;

  isPaymentForOneTime: Boolean = false;

  paymentBoxArgDetails: any;

  newPaymentDetails: any;

  getProductsTaxApiData: any;

  locationDetailsForOneTime: any;

  OtherGuid: String;

  sessionTokenGenerated: boolean = true;

  currentpaymentMethod: String;

  currentBillingFrequency: String;

  newBillingFrequency: String;

  userDetailsCheckout: any;

  isFromCheckout: any;

  inputProducts = [];

  customerData;

  stripe: { oneTime: any; update: any } = { oneTime: undefined, update: undefined };

  constructor(
    private userService: UserService,
    private ordersService: OrdersService,
    private simpleModalService: SimpleModalService,
    private locationService: LocationService,
    private crmProxyService: CrmProxyService,
    private windowScrolling: WindowScrolling,
    private globalVariables: GlobalVariables,
    private router: Router,
    private sessionStorageService: SessionStorageService,
  ) {
    super();
    // this.windowScrolling.disable();
    this.customerServiceNumber = environment.customer_service_number;
  }

  /**
   * Load ETS payment box.
   * @param customerGuid
   * @param serviceAddress
   * @param zipcode
   * @param cusno
   */
  paymentBox(customerGuid: String, serviceAddress: any, zipcode: any, cusno: any) {
    let paymentMethods = [PaymentMethod.CARD];
    if (!this.isPaymentForOneTime) {
      paymentMethods = [PaymentMethod.CARD, PaymentMethod.ACH];
    }
    const s = new Stripe();
    const element = document.getElementById('stripe-payment');
    console.log({ d: this });
    const currentAddress = this.customerData?.address.find(p => p.customerGuid === this.crmCustomerGuid);
    const payload: PaymentPayload = {
      customer: {
        oracleCustomerId: currentAddress.oracleAccountNumber,
        postalCode: zipcode,
        fullName: `${this.user.firstName} ${this.user.lastName}`,
        email: this.user.email,
        oracleCustomerGuid: this.crmCustomerGuid,
      },
      amount: this.isPaymentForOneTime ? this.getProductsTaxApiData.totalPastDue : '0',
      mountNode: element,
      paymentMethods,
      enrollments: [
        {
          referenceNumber: `UPFRONT-${this.products[0].productDetails.marketingCode}-${currentAddress.oracleCustId}-01`,
          amount: this.isPaymentForOneTime ? this.getProductsTaxApiData.totalPastDue : '0',
        },
      ],
    };
    console.log({ payload });
    if (this.isPaymentForOneTime) {
      s.updatePaymentMethod(payload)
        .then(response => {
          this.afterStripePayment(customerGuid, serviceAddress, zipcode, cusno, response);
        })
        .catch(() => {
          this.sessionTokenGenerated = true;
          this.showErrorModal('Error', `We Are facing problem with the payment gateway, you won't be able to add new payment method.`);
        });
    } else {
      s.updatePaymentMethod(payload)
        .then(response => {
          s.destroy();
          this.afterStripePayment(customerGuid, serviceAddress, zipcode, cusno, response);
        })
        .catch(() => {
          this.sessionTokenGenerated = true;
          this.showErrorModal('Error', `We Are facing problem with the payment gateway, you won't be able to add new payment method.`);
        });
    }
  }

  private afterStripePayment(customerGuid: String, serviceAddress: any, zipcode: any, cusno: any, response: any[]) {
    if (response[0].error) {
      this.sessionTokenGenerated = true;
      this.showErrorModal('Error', `We Are facing problem with the payment gateway, you won't be able to add new payment method.`);
      return;
    }

    this.showLoader = true;
    const paymentReqdData = {
      customerGuid,
      serviceAddress,
      zipcode,
      cusno,
    };
    this.paymentBoxArgDetails = paymentReqdData;
    this.suspendedEnrollmentPayment(response[0], paymentReqdData, false);
  }

  getOrderDetails(enrollmentId: String) {
    const guestToken = this.sessionStorageService.getItem('guestToken');
    this.ordersService.getOrderBycrmEnrollmentGuid(enrollmentId, guestToken || '').subscribe(async data => {
      if (data.status === 200) {
        this.products = data.body;
        this.currentpaymentMethod = this.products[0].paymentMethod === 'ACH' ? 'ACH Direct Debit' : 'credit card';
        if (this.products[0].billingFrequency === 'Monthly') {
          this.currentBillingFrequency = `a ${this.products[0].billingFrequency.toLowerCase()}`;
        } else if (this.products[0].billingFrequency === 'Annual') {
          this.currentBillingFrequency = `an ${this.products[0].billingFrequency.toLowerCase()}`;
        } else {
          this.currentBillingFrequency = this.products[0].billingFrequency;
        }
        this.newBillingFrequency = this.products[0].billingFrequency.toLowerCase();
        if (this.products.length > 0) {
          try {
            // replace this api with past due API
            const getProductsTaxApicall = await this.getProductsTaxApi();
            if (getProductsTaxApicall.body) {
              this.getProductsTaxApiData = getProductsTaxApicall.body.data;
            }
          } catch (err) {
            console.log(err);
            this.close();
            this.showUpdatePaymentPopup();
            return;
          }

          if (this.products.length === 0) {
            this.showErrorAlert();
          } else {
            this.crmPaymentPlanId = this.products[0].crmPaymentPlanId ? this.products[0].crmPaymentPlanId : '';
            this.crmCustomerGuid = this.products[0].crmCustomerGuid ? this.products[0].crmCustomerGuid : '';
            this.financialInstrumentToken =
              this.products[0].paymentData && this.products[0].paymentData.financialInstrumentToken
                ? this.products[0].paymentData.financialInstrumentToken
                : '';
            // eslint-disable-next-line no-underscore-dangle
            if (this.user._id !== this.products[0].customerId) {
              this.showErrorAlert();
            }
          }
        }
      }

      if (this.products.length !== 0) {
        // this.getCustomerPayments((error, status) => {
        //   if (error) {
        //     this.showLoader = false;
        //     this.showErrorAlert();
        //   }
        // });
        const serviceAddress = `${this.products[0].address.serviceAddress.streetNumber} ${this.products[0].address.serviceAddress.streetName}, ${this.products[0].address.serviceAddress.city} ${this.products[0].address.serviceAddress.state} ${this.products[0].address.serviceAddress.zipcode}`;

        this.paymentBox(this.crmCustomerGuid, serviceAddress, this.products[0].address.serviceAddress.zipcode, this.products[0].address.customerNo);
        this.showLoader = false;
      }
    });
  }

  showUpdatePaymentPopup() {
    this.simpleModalService.addModal(
      ManagePaymentComponent,
      {
        crmEnrollmentGuid: this.crmEnrollmentGuid,
        OtherGuid: this.OtherGuid,
        userAllDetails: this.customerData,
        userDetailsCheckout: this.userDetailsCheckout,
        isFromCheckout: this.isFromCheckout,
        inputProducts: this.inputProducts,
      },
      {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true,
      },
    );
  }

  showFailureAlert() {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'Payment Failed',
          message: `<p style="margin-top:-20px;margin-bottom:0;">There was a problem during payment. Please contact Customer Service for further assistance. <b><a href="tel:${this.customerServiceNumber}">${this.customerServiceNumber}</a></b></p>`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Close Message',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.closeOneTimePayment();
          window.location.reload();
        }
      });
  }

  showErrorAlert() {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'We have encountered a problem with your enrollment',
          message: `<p style="margin-top:-20px;margin-bottom:0;">Please call customer service to ensure your enrollment has completed and you are protected. <b><a href="tel:${this.customerServiceNumber}">${this.customerServiceNumber}</a></b></p>`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Close Message',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.closeOneTimePayment();
        }
      });
  }

  showSuccessModel(isFromCheckout?) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'Payment Success',
          message:
            'Thank you for your payment and for continuing to be a valued customer. Your enrollment will be reactivated once fully processed in our systems.',
          confirmText: 'Close',
          alertType: 'info-type',
          confirmBtnId: 'updatedPayment',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.closeOneTimePayment();
          if (isFromCheckout) {
            const queryParam = {
              queryParams: {
                enrollmentId: this.crmEnrollmentGuid,
              },
            };
            this.globalVariables.customerAccountOrderCuid = '';
            this.globalVariables.customerAccountEnrollmentGuid = this.crmEnrollmentGuid;
            this.globalVariables.showCheckoutPaymentReponsePage = true;
            const redirectPath: any = window.location.pathname;
            this.router.navigate([redirectPath], queryParam);
          } else if (!this.isFromCheckout) {
            window.location.reload();
          } else if (this.inputProducts.length <= 1) {
            window.location.reload();
          } else {
            this.globalVariables.oneTimeSuccessEvent.emit(true);
          }
        }
      });
  }

  /**
   * Format expiry date.
   * @param str
   * @param index
   * @param value
   */
  changeExpDate(str, index, value) {
    const getCurrentYearPrefix = new Date().getFullYear().toString().slice(0, 2);
    return str.substr(0, index) + value + getCurrentYearPrefix + str.substr(index);
  }

  getMaskedAccountNumber(string) {
    return string.substr(string.length - 4, 4);
  }

  /**
   * Get customer existing cards and ACH accouts.
   * @param done
   */
  getCustomerPayments(done?: any) {
    this.paymentCards = [];
    this.achCards = [];
    this.getEFTCustomerAccount(this.crmCustomerGuid)
      .then((results: any) => {
        let customerGuid;
        if (
          results &&
          results.response &&
          results.response.GetCustomerAccountResponse &&
          results.response.GetCustomerAccountResponse.GetCustomerAccountResult &&
          results.response.GetCustomerAccountResponse.GetCustomerAccountResult.Id
        ) {
          customerGuid = results.response.GetCustomerAccountResponse.GetCustomerAccountResult.Id;
        } else {
          customerGuid = this.crmCustomerGuid;
        }
        if (customerGuid) {
          this.userService.getCustomerCards(customerGuid).subscribe(
            data1 => {
              const results1 = data1.body;
              this.paymentCards = results1.data.creditCardAccounts.creditCardModel;
              this.achCards = results1.data.achAccounts.AchAccountModel;
              if (!!this.paymentCards && this.paymentCards.length > 0) {
                for (let i = 0; i < this.paymentCards.length; i++) {
                  this.paymentCards[i].ExpirationDate = this.changeExpDate(this.paymentCards[i].ExpirationDate.toString(), 2, '/');
                  this.paymentCards[i].AccountNumberMasked = this.getMaskedAccountNumber(this.paymentCards[i].AccountNumberMasked);
                }
              }
              if ((!!this.paymentCards && this.paymentCards.length > 0) || (!!this.achCards && this.achCards.length > 0)) {
                //
              } else {
                this.setCard = 'newCard';
                this.setCardRequired = false;
              }
              if (done) {
                done(false, true);
              }
            },
            () => {
              this.setCard = 'newCard';
              this.setCardRequired = false;
              if (done) {
                done(true, null);
              }
            },
          );
        } else {
          this.setCard = 'newCard';
          this.setCardRequired = false;
        }
        this.showLoader = false;
      })
      .catch(() => {
        this.setCard = 'newCard';
        this.setCardRequired = false;
        if (done) {
          done(true, null);
        }
      });
  }

  useExistCard() {
    // this.oneTimePayment(this.setCard, 'exist');
    let response: any = {};
    response = this.setCard;
    response.paymentType = this.paymentType;
    const serviceAddress = `${this.products[0].address.serviceAddress.streetNumber} ${this.products[0].address.serviceAddress.streetName}, ${this.products[0].address.serviceAddress.city} ${this.products[0].address.serviceAddress.state} ${this.products[0].address.serviceAddress.zipcode}`;

    const paymentReqdData = {
      customerGuid: this.crmCustomerGuid,
      serviceAddress,
      zipcode: this.products[0].address.serviceAddress.zipcode,
      cusno: this.products[0].address.customerNo,
    };
    this.paymentBoxArgDetails = paymentReqdData;
    this.suspendedEnrollmentPayment(response, this.paymentBoxArgDetails, true);
  }

  // new cc and new ach is handled.
  oneTimePayment(response, type) {
    let payload: any = {};
    const order = this.products[0];
    payload = {
      customerId: order.customerId,
      address: order.address,
      enrollmentId: order.crmEnrollmentGuid,
      contactId: order.crmCustomerGuid,
      marketingCodeId: order.enrollmentPrice.marketingCodeId,
      programId: order.productDetails.program.programId,
      totalPastDue: this.getProductsTaxApiData.totalPastDue,
      totalChargeDue: this.getProductsTaxApiData.totalChargeDue,
      totalTaxDue: this.getProductsTaxApiData.totalTaxDue,
      transactionDetails: this.getProductsTaxApiData.transactionDetails,
      crmEnrollmentGuid: order.crmEnrollmentGuid,
      oracleCustId: this.customerData?.address.find(p => p.customerGuid === this.crmCustomerGuid)?.oracleCustId,
      oracleAccountNumber: this.customerData?.oracleAccountNumber,
    };
    if (type === 'new') {
      payload.oneTimePayment = {
        ETSCustomerId: response.instrument_id,
        ETSPaymentId: response.customer_token,
        ETSAccount: response.customer_name,
        ETSCCType: response.instrument_type,
        ETSCCNumber: response.instrument_last_four,
        ETSCCExpiry: response.instrument_expiration_date,
      };
      if (this.newPaymentDetails && (this.newPaymentDetails.paymentType === 'ACH' || this.newPaymentDetails.instrument_type === 'ACH')) {
        payload.scheduledPayment = {
          paymentMethod: this.newPaymentDetails.paymentType === 'ACH' || this.newPaymentDetails.instrument_type === 'ACH' ? 'ACH' : 'Credit Card',
          ETSCustomerId: response.instrument_id,
          ETSPaymentId:
            this.newPaymentDetails.AchAccountToken !== undefined ? this.newPaymentDetails.AchAccountToken : this.newPaymentDetails.customer_token,
          ETSAccount:
            this.newPaymentDetails.customer_name !== undefined ? this.newPaymentDetails.customer_name : this.newPaymentDetails.AccountHolderName,
          ETSCCType: null,
          ETSCCNumber: null,
          ETSCCExpiry: null,
          ETSBankRoutingNo:
            this.newPaymentDetails.instrument_routing_last_four !== undefined
              ? this.newPaymentDetails.instrument_routing_last_four
              : this.newPaymentDetails.RoutingNumberMasked.substr(this.newPaymentDetails.RoutingNumberMasked.length - 4),
          ETSBankAccountNo:
            this.newPaymentDetails.instrument_last_four !== undefined
              ? this.newPaymentDetails.instrument_last_four
              : this.newPaymentDetails.AccountNumberMasked.substr(this.newPaymentDetails.AccountNumberMasked.length - 4),
        };
      } else {
        payload.scheduledPayment = {
          paymentMethod: 'Credit Card',
          ETSCustomerId: this.newPaymentDetails.instrument_id,
          ETSPaymentId: this.newPaymentDetails.customer_token,
          ETSAccount: this.newPaymentDetails.customer_name,
          ETSCCType: this.newPaymentDetails.instrument_type,
          ETSCCNumber: this.newPaymentDetails.instrument_last_four,
          ETSCCExpiry: this.newPaymentDetails.instrument_expiration_date,
          ETSBankRoutingNo: null,
          ETSBankAccountNo: null,
        };
      }
    } else if (type === 'exist') {
      payload.oneTimePayment = {
        ETSCustomerId: response.CustomerToken,
        ETSPaymentId: response.CreditCardToken,
        ETSAccount: response.AccountHolderName,
        ETSCCType: response.CardType,
        ETSCCNumber: response.AccountNumberMasked,
        ETSCCExpiry: response.ExpirationDate,
      };
      if (this.newPaymentDetails && (this.newPaymentDetails.paymentType === 'ACH' || this.newPaymentDetails.instrument_type === 'ACH')) {
        payload.scheduledPayment = {
          paymentMethod: this.newPaymentDetails.paymentType === 'ACH' || this.newPaymentDetails.instrument_type === 'ACH' ? 'ACH' : 'Credit Card',
          ETSCustomerId: response.CustomerToken, // customer_token
          ETSPaymentId:
            this.newPaymentDetails.AchAccountToken !== undefined ? this.newPaymentDetails.AchAccountToken : this.newPaymentDetails.instrument_id,
          ETSAccount:
            this.newPaymentDetails.customer_name !== undefined ? this.newPaymentDetails.customer_name : this.newPaymentDetails.AccountHolderName,
          ETSCCType: null,
          ETSCCNumber: null,
          ETSCCExpiry: null,
          ETSBankRoutingNo:
            this.newPaymentDetails.instrument_routing_last_four !== undefined
              ? this.newPaymentDetails.instrument_routing_last_four
              : this.newPaymentDetails.RoutingNumberMasked.substr(this.newPaymentDetails.RoutingNumberMasked.length - 4),
          ETSBankAccountNo:
            this.newPaymentDetails.instrument_last_four !== undefined
              ? this.newPaymentDetails.instrument_last_four
              : this.newPaymentDetails.AccountNumberMasked.substr(this.newPaymentDetails.AccountNumberMasked.length - 4),
        };
      } else {
        payload.scheduledPayment = {
          paymentMethod: 'Credit Card',
          ETSCustomerId: response.CustomerToken,
          ETSPaymentId: response.CreditCardToken,
          ETSAccount: response.AccountHolderName,
          ETSCCType: response.CardType,
          ETSCCNumber: response.AccountNumberMasked,
          ETSCCExpiry: response.ExpirationDate,
          ETSBankRoutingNo: null,
          ETSBankAccountNo: null,
        };
      }
    }

    payload.oneTimePayment = {
      ...payload.oneTimePayment,
      fingerprint: this.stripe.oneTime.fingerprint,
      bankAccountType: this.stripe.oneTime.bankAccountType,
      ETSCCType: response.CardType,
    };
    payload.scheduledPayment = {
      ...payload.scheduledPayment,
      fingerprint: this.stripe.update.fingerprint,
      bankAccountType: this.stripe.update.bankAccountType,
      ETSCCType: response.CardType,
    };

    this.showLoader = true;
    // replace this api with new suspended oracle API
    this.ordersService
      .oneTimePaymentOracle(payload, this.userDetailsCheckout && this.userDetailsCheckout.authHeader ? this.userDetailsCheckout.authHeader : '')
      .subscribe(
        data => {
          this.showLoader = false;
          this.isPaymentForOneTime = false;
          if (data.status === 200) {
            this.showSuccessModel(this.isFromCheckout);
          }
        },
        () => {
          this.showLoader = false;
          this.showFailureAlert();
        },
      );
  }

  ngOnInit() {
    this.cuid = nanoid();
    this.showLoader = true;
    this.userDetails = this.userService.getSignedInUser();
    if (!this.isFromCheckout) {
      this.user = this.userDetails.user;
    } else {
      this.user = this.userDetailsCheckout.user;
    }
    const guestToken = this.sessionStorageService.getItem('guestToken');
    this.userService.getCustomerDetails(this.user._id, guestToken).subscribe({
      next: data => {
        console.log({ d: data.body });
        this.customerData = data.body;
        // hotfix to avoid race condition
        this.getOrderDetails(this.crmEnrollmentGuid);
      },
      error: () => {
        this.sessionTokenGenerated = true;
        this.showLoader = false;
        this.showErrorModal('Error', `We Are facing problem with the payment gateway, you won't be able to add new payment method.`);
      },
    });
  }

  closeOneTimePayment() {
    this.windowScrolling.enable();
    this.close();
  }

  suspendedEnrollmentPayment(response, paymentReqdData, isExisting) {
    if (!this.isPaymentForOneTime) {
      console.log({ response });
      const newpaymentMethod = response.instrument_type === 'ACH' ? 'ACH Direct Debit' : 'credit card';
      this.simpleModalService
        .addModal(
          AlertConfirmModalComponent,
          {
            message: `<div class="one-time-ach"><p>Our records indicate that your previous payment was processed through ${this.currentBillingFrequency} ${this.currentpaymentMethod}. Please confirm that future payments should be made through a NEW ${this.newBillingFrequency} ${newpaymentMethod}.
            </p> </br>
            <p>Note: A one-time credit card payment is required to re-activate your current coverage, before future payments can be processed through the newly designated ${newpaymentMethod} </p></br>
            <p>You will be prompted to enter the new payment information on the next page.</p></div>`,
            title: '',
            alertIcon: 'fa fa-info-circle',
            cancelText: 'Cancel',
            confirmText: 'I Agree (Continue)',
          },
          {
            wrapperClass: 'modal-main-class in',
            closeOnClickOutside: false,
          },
        )
        .subscribe(isConfirmed => {
          if (isConfirmed) {
            this.stripe.update = response;
            this.showLoader = false;
            this.isPaymentForOneTime = true;
            this.newPaymentDetails = response;
            this.paymentBox(paymentReqdData.customerGuid, paymentReqdData.serviceAddress, paymentReqdData.zipcode, paymentReqdData.cusno);
          } else {
            this.showLoader = false;
            this.closeOneTimePayment();
          }
        });
    } else if (this.isPaymentForOneTime) {
      this.stripe.oneTime = response;
      this.oneTimePayment(response, isExisting ? 'exist' : 'new');
    }
  }

  // API for past due
  public getProductsTaxApi(): Promise<any> {
    return this.crmProxyService.getPastDue(this.crmEnrollmentGuid, this.isFromCheckout ? this.userDetailsCheckout.authHeader : '').toPromise();
  }

  /**
   * Show error message.
   * @param title
   * @param message
   */
  showErrorModal(title: string, message: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  getEFTCustomerAccount(customerGuid) {
    return new Promise(resolve => {
      let results = {};
      this.userService.getCustomerAccounts(customerGuid).subscribe(
        data => {
          results = data.body.data;
          resolve({
            error: false,
            response: results,
          });
        },
        () => {
          resolve({
            error: true,
            response: {},
          });
        },
      );
    });
  }
}
