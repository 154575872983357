import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpService } from '../../core/services/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ResyncEnrollmentService {
  constructor(private http: HttpService) {}

  resyncEnrollment(custNo: string, custId: string, token?: string) {
    const options = token ? { headers: { authorization: token } } : undefined;
    const resyncURL = `${environment.Customer_service_API_Endpoint}customer/customerno/${custNo}/${custId}`;
    return this.http.put(resyncURL, '', options).pipe(catchError(this.handleError));
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
