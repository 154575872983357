<!-- Property redesign for desktop view - Start -->
<section class="desktop-view" *ngIf="isDesktopView || isTabletView">
  <div class="row fs-restrict-dead-clicks">
    <div
      class="pt-3"
      id="card-{{ selectedAddIndex }}"
      [ngClass]="isTabletView ? 'col-12' : 'col-6'"
      *ngFor="let add of allAddresses; let selectedAddIndex = index"
    >
      <div class="property-image position-absolute d-flex">
        <img src="img/my-account/icons/Property-House.svg" class="img-responsive" alt="Property House" />
      </div>
      <div class="property-block" (click)="changeAddress(selectedAddIndex, allAddresses[selectedAddIndex])">
        <div class="property-address-block">
          <div
            class="property-elipsis d-inline-block"
            title="{{ add.serviceAddress.streetNumber }} {{ add.serviceAddress.streetName }}, {{ add.serviceAddress.city }}, {{
              add.serviceAddress.state
            }}, {{ add.serviceAddress.zipcode }}"
          >
            {{ add.serviceAddress.streetNumber }} {{ add.serviceAddress.streetName }}, {{ add.serviceAddress.city }}, {{ add.serviceAddress.state }},
            {{ add.serviceAddress.zipcode }}
          </div>
          <div (click)="deleteServiceAddress($event, add)" class="address-remove-btn d-inline-block mx-1">Remove Property</div>
        </div>
        <div class="align-center property-details-block" [ngClass]="{ 'enrollment-selected': selectedAddIndex === selectedEnrollAddIndex }">
          <div
            class="icons-div"
            *ngIf="
              add?.customerNo &&
              propertiesInfo &&
              propertiesInfo[selectedAddIndex] &&
              propertiesInfo[selectedAddIndex][add?.customerNo] &&
              propertiesInfo[selectedAddIndex][add?.customerNo]['icons']
            "
          >
            <ng-container *ngFor="let icon of propertiesInfo[selectedAddIndex][add?.customerNo]['icons']; let i = index">
              <img class="mr-1" *ngIf="i < 5" [src]="getImage(imageBaseUrl + icon)" />
            </ng-container>
            <div
              class="icon-count align-center mr-1"
              *ngIf="propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length > 5"
              [style.font-size]="propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length > 14 ? '10px' : '14px'"
            >
              +{{ propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length - 5 }}
            </div>
          </div>

          <div
            [ngClass]="propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length >= 1 ? 'ml-2' : 'ml-0'"
            class="plan-count-block align-center"
            *ngIf="
              add?.customerNo &&
              propertiesInfo &&
              propertiesInfo[selectedAddIndex] &&
              propertiesInfo[selectedAddIndex][add?.customerNo] &&
              propertiesInfo[selectedAddIndex][add?.customerNo]['icons']
            "
          >
            {{
              propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length > 1
                ? propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length + ' Plans'
                : propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length === 1
                ? propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length + ' Plan'
                : 'No Active Plans'
            }}
          </div>

          <span
            class="alert-text"
            *ngIf="
              add?.customerNo &&
              propertiesInfo &&
              propertiesInfo[selectedAddIndex] &&
              propertiesInfo[selectedAddIndex][add?.customerNo] &&
              propertiesInfo[selectedAddIndex][add?.customerNo]['isActionRequired']
            "
            >Attention Required</span
          >
          <span class="details-txt" [ngClass]="{ 'text-white': selectedAddIndex === selectedEnrollAddIndex }">{{
            selectedAddIndex === selectedEnrollAddIndex ? 'Hide Details' : 'View Details'
          }}</span>
        </div>
      </div>
      <div [ngClass]="{ arrow: selectedAddIndex === selectedEnrollAddIndex }"></div>

      <ng-container *ngIf="selectedAddIndex === selectedEnrollAddIndex">
        <ng-container *ngIf="services?.length > 0 && !showLoader">
          <div class="enrollment-details-div">
            <div *ngFor="let service of services; let i = index" table-responsive class="container-services pl-0 pr-0">
              <div class="bg-white">
                <div class="float-left">
                  <img
                    [src]="getImage(imageBaseUrl + service.productDetails?.programIconUrl)"
                    [alt]="service.productDetails?.webProgramName || service.productDetails?.customerProgramName"
                    class="productIcon"
                    style.background="linear-gradient(270deg, #FFFFFF 0%, {{ service.colorConfigStyle }} 100%)"
                  />
                  <span class="productText">{{ service.productDetails?.webProgramName || service.productDetails?.customerProgramName }}</span>
                  <span class="btn-danger p-1 action-requried" role="alert" *ngIf="isActionRequried(service)"
                    ><i class="fa fa-exclamation-triangle fa-exclamation-triangle-color mr-2"></i>Attention Required</span
                  >
                </div>
                <ng-container
                  *ngIf="
                    service.crmStatus === '2' &&
                    service.renewNow === true &&
                    !service.isAutoRenew &&
                    service.productDetails.program.programCode !== 'LDDP' &&
                    allowOffbillRenew(service) &&
                    (!service.isAlreadyEnrolled || renewedEnrollments?.length === 0)
                  "
                >
                  <button type="button" class="btn btn-sm btn-property btn-renew float-right" (click)="renewNowService(service)">Renew</button>
                </ng-container>
                <ng-container
                  *ngIf="
                    service.crmStatus === '2' &&
                    (service.crmEnrollmentGuid ? true : false) &&
                    showAutorenew(service.renewalDate) &&
                    !service.isAutoRenew &&
                    checkBillingType(service) &&
                    service.productDetails.program.programCode !== 'LDDP' &&
                    isStateValid(service)
                  "
                >
                  <button type="button" class="btn btn-sm btn-property btn-renew float-right" (click)="autorenewService(service)">Auto Renew</button>
                </ng-container>
                <button
                  type="button"
                  *ngIf="
                    service.expiredIn > 0 &&
                    service.crmStatus === '6' &&
                    service.productDetails.program.programCode !== 'LDDP' &&
                    allowOffbillRenew(service)
                  "
                  class="btn btn-sm btn-property btn-danger float-right"
                  (click)="oneTimePayment(service)"
                >
                  Re-activate Coverage
                </button>
                <ng-container
                  *ngIf="
                    service.crmStatus === '2' &&
                    (service.crmEnrollmentGuid ? true : false) &&
                    showUpgrade(service.createdAtInCRM) &&
                    (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today
                  "
                >
                  <button type="button" class="btn btn-sm btn-property btn-upgrade float-right" (click)="upgradeService(service)">Upgrade</button>
                </ng-container>
                <ng-container
                  *ngIf="
                    (service.expiredIn < 0 || service.crmStatus === '4') &&
                    service.productDetails.program.programCode !== 'LDDP' &&
                    !disAllowRenroll(service)
                  "
                >
                  <button type="button" id="ga-reenroll-product" class="btn btn-sm btn-property btn-renew float-right" (click)="reEnroll(service)">
                    Re-Enroll
                  </button>
                </ng-container>
              </div>
              <div class="p-1 bg-white">
                <ng-container class="MT-15 desktop-only" *ngIf="reviewIndex === i">
                  <hos-success-alert life="3000"></hos-success-alert>
                </ng-container>
                <table class="table table-hover table-responsive-lg mb-0 order-detail-list">
                  <thead>
                    <tr>
                      <th>Claims</th>
                      <th>Status</th>
                      <th class="text-in-single-line">Enrollment Number</th>
                      <th>
                        <img
                          src="../../../../img/my-account/font-awesome-icons/calendar-check-solid.svg"
                          alt="Purchase-icon"
                          class="th-icon mb-1 mr-1"
                        />Purchase
                      </th>
                      <th>
                        <img
                          src="../../../../img/my-account/font-awesome-icons/calendar-alt-solid.svg"
                          alt="Coverage-icon"
                          class="th-icon mb-1 mr-1"
                        />{{ service.crmStatus === '2' && service.productDetails.program.programCode === 'LDDP' ? 'Term End Date' : 'Coverage' }}
                      </th>
                      <th>
                        <img
                          src="../../../../img/my-account/font-awesome-icons/calendar-times-solid.svg"
                          alt="Renewal-icon"
                          class="th-icon mb-1 mr-1"
                        />{{
                          (service.crmStatus === '2' || service.crmStatus === '999') &&
                          (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today &&
                          service.productDetails.program.programCode !== 'LDDP'
                            ? 'Renewal'
                            : (service.crmStatus === '2' || service.crmStatus === '999') && service.productDetails.program.programCode === 'LDDP'
                            ? 'Term'
                            : 'Expiration'
                        }}
                      </th>
                      <th>
                        <img
                          src="../../../../img/my-account/font-awesome-icons/file-invoice-dollar-solid.svg"
                          alt="Billing-icon"
                          class="th-icon bill-icon mr-1"
                        />Billing
                      </th>
                      <th><i class="fa fa-usd th-icon"></i>&nbsp;Payment</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="body-text bg-white" id="{{ selectedAddIndex }}-enr-detail-height-{{ i }}">
                      <td class="position-relative">
                        <!-- uncomment to show disable plus icon on no claims desktop view -->
                        <!-- <i *ngIf="service?.claimCount === 0"class="fa fa-plus fa-claims-toggle fa-disable" aria-hidden="true"></i> -->
                        <ng-container *ngIf="!hide_fileClaimButton.includes(service.productDetails.program.programId); else claimsNotApplicable">
                          <i
                            *ngIf="service?.claimCount > 0"
                            class="fa fa-plus fa-claims-toggle"
                            [ngClass]="{ 'fa-minus': claimExpanded && claimIndex === i }"
                            (click)="loadMyClaimsNewComponent(i)"
                            style="cursor: pointer"
                          >
                            <div
                              *ngIf="showVerticalAfterClaims && claimExpanded && claimIndex === i"
                              class="position-absolute"
                              id="vline"
                              [style.height.px]="claimsLineHeight ? claimsLineHeight : 0"
                              [ngClass]="{
                                'vertical-line': claimExpanded && claimIndex === i,
                                'vertical-line-top-55': isEnrHasRenewNow,
                                'vertical-line-top-50': isEnrHasUpdatePayment
                              }"
                            ></div> </i
                          >&nbsp;
                          <a *ngIf="service?.claimCount > 0" class="claim-button" (click)="loadMyClaimsNewComponent(i)" style="cursor: pointer">{{
                            service?.claimCount === 1 ? service?.claimCount + ' Claim' : service?.claimCount + ' Claims'
                          }}</a>
                          <span *ngIf="service?.claimCount === 0" class="no-claims-text">No Claims</span>
                        </ng-container>
                        <ng-template #claimsNotApplicable> N/A </ng-template>
                      </td>
                      <td class="clscrmStatus">
                        <span
                          class="crm-status-icon icon-status-active"
                          *ngIf="
                            service.expiredIn > 0 &&
                            service.crmStatus === '2' &&
                            (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today
                          "
                          ><i class="status-icon fa fa-check-circle fa-lg" aria-hidden="true"></i
                        ></span>
                        <span class="crm-status-icon icon-status-pending" *ngIf="service.crmStatus === '999' || service.crmStatus === '3'"
                          ><i class="status-icon fa fa-check-circle fa-lg" aria-hidden="true"></i
                        ></span>
                        <span
                          class="crm-status-icon icon-status-expired"
                          *ngIf="(service.expiredIn < 0 && service.crmStatus !== '4') || service.crmStatus === '5'"
                          ><i class="status-icon fa fa-exclamation-circle fa-lg" aria-hidden="true"></i
                        ></span>
                        <span class="crm-status-icon icon-status-suspended" *ngIf="service.expiredIn > 0 && service.crmStatus === '6'"
                          ><i class="status-icon fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i
                        ></span>
                        <span class="crm-status-icon icon-status-cancelled" *ngIf="service.crmStatus === '4'"
                          ><i class="status-icon fa fa-times-circle fa-lg" aria-hidden="true"></i></span
                        ><span class="crm-status-text" [tooltip]="service | enrollmentStatusText">
                          {{
                            service.crmStatus === '4'
                              ? 'Cancelled'
                              : service.crmStatus === '999' || service.crmStatus === '3'
                              ? 'Pending'
                              : service.isAlreadyEnrolled && service.renewNow === true && service.productDetails.program.programCode !== 'LDDP'
                              ? 'Renewed'
                              : service.expiredIn > 0 &&
                                service.crmStatus === '2' &&
                                (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today
                              ? 'Active'
                              : service.expiredIn < 0
                              ? 'Expired'
                              : service.crmStatus === '6'
                              ? 'Suspended'
                              : service.crmStatus === '5'
                              ? 'Closed'
                              : ''
                          }}
                        </span>
                      </td>
                      <td class="text-in-single-line enrollment-number">
                        <span *ngIf="service?.crmStatus === '999' || service?.crmStatus === '3'">Pending</span>
                        <span class="notranslate" *ngIf="!(service?.crmStatus === '999' || service?.crmStatus === '3')">{{
                          '#' + service?.crmEnrollmentNumber
                        }}</span>
                      </td>
                      <td>
                        <!-- Purchase Date Change condition show crmchargedate for both paymentMethod  -->
                        <span class="p-0" *ngIf="service.billingType === 'onbill' && service.origin === 'HOS'; else showCrmChargeDate">{{
                          service?.createdAt ? (service?.createdAt.split('T')[0] | date : 'MM/dd/yy') : '-'
                        }}</span>
                        <ng-template #showCrmChargeDate
                          ><span class="p-0">{{
                            service?.enrollmentDate
                              ? (service?.enrollmentDate.split('T')[0] | date : 'MM/dd/yy')
                              : service?.crmChargeDate
                              ? (service?.crmChargeDate.split('T')[0] | date : 'MM/dd/yy')
                              : '-'
                          }}</span>
                        </ng-template>
                      </td>
                      <!--- start code for Coverage-->
                      <td>
                        <!--- start code for LDDP-->
                        <ng-container *ngIf="service.crmStatus === '2' && service.productDetails.program.programCode === 'LDDP'">
                          <span *ngIf="service.crmFrequency === 'Upfront'"> N/A </span>
                          <span *ngIf="service.crmFrequency === 'Monthly'">
                            {{ service?.renewalDate ? (service?.renewalDate.split('T')[0] | date : 'MM/dd/yy') : 'N/A' }}
                          </span>
                        </ng-container>
                        <ng-container *ngIf="service.crmStatus === '3' && service.productDetails.program.programCode === 'LDDP'"> N/A </ng-container>
                        <!--- end code for LDDP-->
                        <ng-container *ngIf="service.productDetails.program.programCode !== 'LDDP'">
                          {{ service?.programEffectiveDate ? (service?.programEffectiveDate.split('T')[0] | date : 'MM/dd/yy') : '-' }}
                        </ng-container>
                      </td>
                      <!--- end code for Coverage-->
                      <!--- start code for Expiration-->
                      <td
                        [ngClass]="{
                          'renew-cls':
                            service.crmStatus === '2' &&
                            service.renewNow === true &&
                            !service.isAutoRenew &&
                            service.productDetails.program.programCode !== 'LDDP' &&
                            allowOffbillRenew(service) &&
                            (!service.isAlreadyEnrolled || renewedEnrollments?.length === 0),
                          'expired-alert-text': service.expiredIn < 0 && service?.renewalDate,
                          'cancel-alert-text': service.crmStatus === '4'
                        }"
                      >
                        <i
                          *ngIf="
                            service.crmStatus === '2' &&
                            service.renewNow === true &&
                            !service.isAutoRenew &&
                            service.productDetails.program.programCode !== 'LDDP' &&
                            allowOffbillRenew(service) &&
                            (!service.isAlreadyEnrolled || renewedEnrollments?.length === 0)
                          "
                          class="fa fa-exclamation-triangle fa-exclamation-triangle-color mr-1"
                        ></i>
                        <!--- start code for LDDP-->
                        <ng-container *ngIf="service.crmStatus === '2' && service.productDetails.program.programCode === 'LDDP'">
                          <span *ngIf="service.crmFrequency === 'Monthly'" class="p-0">
                            <span *ngIf="service?.enrollmentPrice?.TaxDetails?.PaymentTerm === 'TwoYears'"> 24 Months </span>
                            <span *ngIf="service?.enrollmentPrice?.TaxDetails?.PaymentTerm === 'FiveYears'"> 60 Months </span>
                          </span>
                          <span *ngIf="service.crmFrequency === 'Upfront'" class="p-0"> N/A </span>
                        </ng-container>
                        <ng-container *ngIf="service.crmStatus === '3' && service.productDetails.program.programCode === 'LDDP'"> N/A </ng-container>
                        <!--- End code for LDDP-->
                        <ng-container
                          *ngIf="
                            service.statusReasonId &&
                            service.statusReasonId.toLowerCase() === statusReasonId.toLowerCase() &&
                            service.crmStatus === '4'
                          "
                        >
                          {{ service?.cancellationDate ? (service?.cancellationDate.split('T')[0] | date : 'MM/dd/yy') : '-' }}
                        </ng-container>
                        <ng-container
                          *ngIf="
                            service.productDetails.program.programCode !== 'LDDP' &&
                            !(
                              service.statusReasonId &&
                              service.statusReasonId.toLowerCase() === statusReasonId.toLowerCase() &&
                              service.crmStatus === '4'
                            )
                          "
                        >
                          {{ service?.renewalDate ? (service?.renewalDate.split('T')[0] | date : 'MM/dd/yy') : '-' }}
                        </ng-container>
                        <span
                          *ngIf="
                            service.crmStatus === '2' &&
                            service.renewNow === true &&
                            !service.isAutoRenew &&
                            service.productDetails.program.programCode !== 'LDDP' &&
                            allowOffbillRenew(service) &&
                            (!service.isAlreadyEnrolled || renewedEnrollments?.length === 0)
                          "
                        >
                          <a role="button" id="{{ selectedAddIndex }}-renew-now-id-{{ i }}" class="renew-now-text" (click)="renewNowService(service)"
                            >Renew Now</a
                          >
                        </span>
                        <span *ngIf="service.crmStatus === '4'" class="cancel-text">Cancelation Date</span>
                      </td>
                      <!--- end code for Expiration-->
                      <td class="text-in-single-line">
                        <span class="clsprice notranslate">$ {{ service?.enrollmentPrice.TaxDetails.EnrollmentPrice | number : '1.2-2' }}</span>
                        <span *ngIf="!(service.productDetails.program.programCode === 'LDDP')"
                          >/{{ service && service.billingFrequency === 'Annual' ? 'Annually' : service.billingFrequency }}
                        </span>
                        <span class="one-time-payment-txt" *ngIf="service.productDetails.program.programCode === 'LDDP'">{{
                          service.crmFrequency === 'Upfront' ? 'One- time payment' : '/Monthly'
                        }}</span>
                      </td>
                      <td>
                        <span class="clspaymentmethod" *ngIf="service.billingType === 'onbill'">On Utility Bill</span>
                        <span class="clspaymentmethod" *ngIf="service.billingType === 'offbill'">{{
                          service.paymentMethod === undefined || service.paymentMethod === 'CreditCard' ? 'Credit Card' : service.paymentMethod
                        }}</span>
                        <span
                          class="d-none"
                          *ngIf="
                            service.statusReasonId &&
                            service.statusReasonId.toLowerCase() === statusReasonId.toLowerCase() &&
                            service.crmStatus === '4' &&
                            service?.refundAmount &&
                            service?.refundAmount > 0
                          "
                        >
                          ${{ service?.refundAmount | number : '1.2-2' }} Refund
                        </span>
                        <span
                          class="d-none"
                          *ngIf="
                            service.statusReasonId &&
                            service.statusReasonId.toLowerCase() === statusReasonId.toLowerCase() &&
                            service.crmStatus === '4' &&
                            (service?.refundAmount === 0 || !service?.hasOwnProperty('refundAmount'))
                          "
                        >
                          $0.00 Refund
                        </span>
                      </td>
                      <!--- Start code for Confirmation Email-->
                      <td class="text-in-single-line pl-0 pr-0 no-header">
                        <ng-container *ngIf="service.origin === 'HOS' && service.productDetails.program.programCode !== 'LDDP'">
                          <i *ngIf="service.crmStatus !== '3' && service.crmStatus !== '999'" class="fa fa-envelope-open-o th-link-icon"></i>
                          <a
                            *ngIf="service.crmStatus !== '3' && service.crmStatus !== '999'"
                            class="grey-links ml-1"
                            (click)="downloadEnrolledPdf(service)"
                            >Confirmation Email</a
                          >
                        </ng-container>
                        <!-- No Confirmation Email Start -->
                        <span
                          *ngIf="service.productDetails.program.programCode !== 'LDDP' && (service.crmStatus === '3' || service.origin === 'ORACLE')"
                          class="not-avail-text no-confirmation"
                          >-</span
                        >
                        <!-- No Confirmation Email end -->
                        <!--- start code for LDDP-->
                        <ng-container
                          *ngIf="
                            (service.origin === 'HOS' &&
                              service.crmStatus === '3' &&
                              service.productDetails.programId === leakDetectionProgramId &&
                              service.crmFrequency === 'Monthly') ||
                            (service.origin === 'HOS' &&
                              service.crmStatus === '2' &&
                              service.productDetails.program.programCode === 'LDDP' &&
                              service.crmFrequency === 'Monthly')
                          "
                        >
                          <i class="fa fa-file-pdf-o th-link-icon"></i>
                          <a class="grey-links ml-1" (click)="downloadContract(service)">Installment Contract</a>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            (service.origin !== 'HOS' &&
                              service.crmStatus === '3' &&
                              service.productDetails.programId === leakDetectionProgramId &&
                              service.crmFrequency === 'Monthly') ||
                            (service.origin !== 'HOS' &&
                              service.crmStatus === '2' &&
                              service.productDetails.program.programCode === 'LDDP' &&
                              service.crmFrequency === 'Monthly')
                          "
                        >
                          <a class="grey-links ml-1">-</a>
                        </ng-container>
                        <ng-container *ngIf="service.origin === 'HOS' && service.crmStatus === '2' && service.crmFrequency === 'Upfront'">
                          <span class="not-avail-text">No Contract</span>
                        </ng-container>
                        <ng-container *ngIf="service.origin !== 'HOS' && service.crmStatus === '2' && service.crmFrequency === 'Upfront'">
                          <span class="not-avail-text">-</span>
                        </ng-container>
                        <!--- End code for LDDP-->
                      </td>
                      <!--- End code for Confirmation Email-->
                      <!--- start code for T&C / Contract-->
                      <td class="text-in-single-line pl-0 pr-0 no-header">
                        <ng-container *ngIf="service.crmStatus !== '3'">
                          <i class="fa fa-file-pdf-o th-link-icon"></i>
                          <a class="grey-links ml-1" (click)="goToTerms(service)">Terms & Conditions</a>
                        </ng-container>
                        <ng-container *ngIf="service.crmStatus === '3'">
                          <span class="not-avail-text">-</span>
                        </ng-container>
                      </td>
                      <!--- End code for T&C / Contract-->
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td [attr.colspan]="10" class="cls-footer p-0">
                        <div class="footer-link-cls">
                          <!-- Review -->
                          <div
                            class="review-link-cls"
                            *ngIf="service.crmStatus === '2' && (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today"
                            (click)="openCreateForm(service, i)"
                          >
                            Leave a Review
                          </div>
                          <div
                            class="cancel-enrollment-cls"
                            *ngIf="
                              service?.paymentMethod &&
                              service?.paymentMethod !== 'Check' &&
                              service?.paymentMethod !== 'eCheck' &&
                              service?.billingFrequency !== 'Upfront' &&
                              service?.crmStatus === '2' &&
                              service?.productDetails?.program?.programCode !== 'LDDP' &&
                              (service?.renewalDate | date : 'yyyy-MM-ddHH:mm:s' : 'UTC') >= today &&
                              service?.paymentMethod !== 'onbill'
                            "
                          >
                            <a
                              id="{{ selectedAddIndex }}-update-payment-{{ i }}"
                              class="update-link-button"
                              (click)="onUpdatePaymentClick(service.billingType, service.paymentMethod, service.crmEnrollmentGuid, service.productId)"
                              >Update Payment</a
                            >
                          </div>
                          <div
                            class="cancel-enrollment-cls"
                            *ngIf="service.crmStatus === '2' && (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today"
                            (click)="cancelEnrollment(service, i)"
                          >
                            Cancel Enrollment
                          </div>
                          <!-- Cancel Enrollment -->
                          <!-- <div class="cancel-enrollment" *ngIf="(service.crmStatus === '2') && ((service?.renewalDate | date:'yyyy-MM-dd HH:mm:s':'UTC') >= today)">
                                                    <a (click)="cancelEnrollment(service, i)">Cancel Enrollment</a>
                                                </div> -->
                          <!-- Social Share -->
                          <div class="social social-show" #socailmenu *ngIf="shareIcon && shareIndex === i">
                            <ul class="social-list">
                              <li class="social-icons social-twitter" (click)="learnMoreNativeLink(service, 'twitter')">
                                <a>
                                  <i class="fa fa-twitter" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="social-icons social-facebook" (click)="learnMoreNativeLink(service, 'fb')">
                                <a>
                                  <i class="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="social-icons social-linkedin" (click)="learnMoreNativeLink(service, 'linkedin')">
                                <a>
                                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="social-icons social-pinterest" (click)="learnMoreNativeLink(service, 'pinterest')">
                                <a>
                                  <i class="fa fa-pinterest" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="social-icons social-mail" (click)="learnMoreNativeLink(service, 'mail')">
                                <a>
                                  <i class="fa fa-envelope" aria-hidden="true"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="icon-circle desktop-icon-circle mr-2 d-none" tooltip="Share" #toggleButton (click)="showSocialIcons($event, i)">
                            <i class="fa fa-share fa-lg" aria-hidden="true"></i>
                          </div>
                          <!-- Claim button  -->
                          <ng-container
                            *ngIf="
                              service.crmStatus === '2' &&
                              (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today &&
                              (!service?.programEffectiveDate || (service?.programEffectiveDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') <= today) &&
                              !hide_fileClaimButton.includes(service.productDetails.program.programId)
                            "
                          >
                            <div (click)="fileClaim(i)" onkeydown="return false" class="fileClaimsBtn mr-2 ml-3">
                              <img class="fileClaimsIcon position-relative" src="../../../../img/my-account-claims/ClaimIcon.svg" alt="claims-icon" />
                              <span class="fileClaimsText position-relative">Submit a Claim</span>
                            </div>
                          </ng-container>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div *ngIf="claimExpanded && selectedAddIndex === (selectedEnrollAddIndex ? selectedEnrollAddIndex : 0) && claimIndex === i">
                <hos-my-account-new-claims
                  [isDesktopView]="isDesktopView"
                  [enrollmentId]="service?.crmEnrollmentGuid"
                  (claimsLineEmitter)="claimsLineEmitterCall($event, selectedAddIndex)"
                  [selectedAddIndex]="selectedAddIndex"
                ></hos-my-account-new-claims>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!services?.length && !showLoader">
          <h5 class="no-service-box"><img src="img/my-account/No_Services.svg" alt="No Service Image" /> No protection on this property</h5>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>
<!-- Property redesign for desktop view - End -->

<!-- Section for Mobile View -->
<ng-container *ngIf="!isDesktopView && !isTabletView">
  <div class="row fs-restrict-dead-clicks">
    <div class="col-12 p-0" id="card-{{ selectedAddIndex }}" *ngFor="let add of allAddresses; let selectedAddIndex = index">
      <div class="mob-property-block" (click)="changeAddress(selectedAddIndex, allAddresses[selectedAddIndex])">
        <div class="mob-property-address-block">
          <div
            class="property-elipsis d-inline-block"
            title="{{ add.serviceAddress.streetNumber }} {{ add.serviceAddress.streetName }}, {{ add.serviceAddress.city }}, {{
              add.serviceAddress.state
            }}, {{ add.serviceAddress.zipcode }}"
          >
            {{ add.serviceAddress.streetNumber }} {{ add.serviceAddress.streetName }}, {{ add.serviceAddress.city }}, {{ add.serviceAddress.state }},
            {{ add.serviceAddress.zipcode }}
          </div>
          <div (click)="deleteServiceAddress($event, add)" class="address-remove-btn d-inline-block mx-1">Remove Property</div>
        </div>
        <div class="mob-property-details-block" [ngClass]="{ 'mob-enrollment-selected': selectedAddIndex === selectedEnrollAddIndex }">
          <span
            class="mob-plan-count-block ml-2"
            *ngIf="
              add?.customerNo &&
              propertiesInfo &&
              propertiesInfo[selectedAddIndex] &&
              propertiesInfo[selectedAddIndex][add?.customerNo] &&
              propertiesInfo[selectedAddIndex][add?.customerNo]['icons']
            "
            >{{
              propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length > 1
                ? propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length + ' Plans'
                : propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length === 1
                ? propertiesInfo[selectedAddIndex][add?.customerNo]['icons']?.length + ' Plan'
                : 'No Active Plans'
            }}</span
          >

          <span
            class="mob-alert-text"
            *ngIf="
              add?.customerNo &&
              propertiesInfo &&
              propertiesInfo[selectedAddIndex] &&
              propertiesInfo[selectedAddIndex][add?.customerNo] &&
              propertiesInfo[selectedAddIndex][add?.customerNo]['isActionRequired']
            "
            >Attention Required</span
          >

          <span class="mob-details-txt" [ngClass]="{ 'text-white': selectedAddIndex === selectedEnrollAddIndex }">{{
            selectedAddIndex === selectedEnrollAddIndex ? 'Hide Details' : 'View Details'
          }}</span>
        </div>
      </div>
      <div class="mob-enrollment-block" *ngIf="selectedAddIndex === selectedEnrollAddIndex">
        <ng-container *ngIf="services.length && !showLoader">
          <!-- Inner Accordion Start-->
          <div class="accordion" id="mob-enrollment-accordion" role="tablist" aria-multiselectable="false">
            <div class="card" *ngFor="let service of services; let i = index">
              <div class="card-header pl-0" role="tab" id="{{ 'enrollment' + i }}" (click)="changeEnrollment(i)">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#mob-enrollment-accordion"
                  href="#enrollment_{{ selectedAddIndex }}_{{ i }}"
                  [attr.aria-expanded]="i === selectedEnrollAddIndexMOB"
                  class="mob-enrollment-link"
                >
                  <div class="mb-0 mob-enrollment-link-text d-flex align-items-center">
                    <img
                      [src]="getImage(imageBaseUrl + service.productDetails?.programIconUrl)"
                      [alt]="service.productDetails?.webProgramName || service.productDetails?.customerProgramName"
                      class="productIcon ml-2 mr-3"
                    />
                    {{ service.productDetails?.webProgramName || service.productDetails?.customerProgramName }}
                  </div>
                </a>
                <span class="btn-danger action-requried" role="alert" *ngIf="isActionRequried(service)"
                  ><i class="fa fa-exclamation-triangle fa-exclamation-triangle-color mr-2"></i>Attention Required</span
                >
              </div>
              <!-- Card body -->
              <div
                id="enrollment_{{ selectedAddIndex }}_{{ i }}"
                class="collapse"
                role="tabpanel"
                data-parent="#mob-enrollment-accordion"
                *ngIf="i === (selectedEnrollAddIndexMOB ? selectedEnrollAddIndexMOB : 0)"
              >
                <ng-container class="MT-15 mobile-only" *ngIf="reviewIndex === i">
                  <hos-success-alert life="3000"></hos-success-alert>
                </ng-container>
                <div class="card-body p-0">
                  <div class="container">
                    <!-- first row buttons start -->
                    <div class="row justify-content-center">
                      <ng-container
                        *ngIf="
                          service.crmStatus === '2' &&
                          (service.crmEnrollmentGuid ? true : false) &&
                          showUpgrade(service.createdAtInCRM) &&
                          (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today
                        "
                      >
                        <button type="button" class="btn btn-sm btn-property btn-upgrade mb-3" (click)="upgradeService(service)">Upgrade</button>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          service.crmStatus === '2' &&
                          service.renewNow === true &&
                          !service.isAutoRenew &&
                          service.productDetails.program.programCode !== 'LDDP' &&
                          allowOffbillRenew(service) &&
                          (!service.isAlreadyEnrolled || renewedEnrollments?.length === 0)
                        "
                      >
                        <button type="button" class="btn btn-sm btn-property btn-renew mb-3" (click)="renewNowService(service)">Renew</button>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          service.crmStatus === '2' &&
                          (service.crmEnrollmentGuid ? true : false) &&
                          showAutorenew(service.renewalDate) &&
                          !service.isAutoRenew &&
                          checkBillingType(service) &&
                          service.productDetails.program.programCode !== 'LDDP' &&
                          isStateValid(service)
                        "
                      >
                        <button type="button" class="btn btn-sm btn-property btn-renew mb-3" (click)="autorenewService(service)">Auto Renew</button>
                      </ng-container>
                      <button
                        type="button"
                        *ngIf="
                          service.expiredIn > 0 &&
                          service.crmStatus === '6' &&
                          service.productDetails.program.programCode !== 'LDDP' &&
                          allowOffbillRenew(service)
                        "
                        class="btn btn-sm btn-property btn-danger mb-3"
                        (click)="oneTimePayment(service)"
                      >
                        Re-activate Coverage
                      </button>
                      <ng-container
                        *ngIf="
                          (service.expiredIn < 0 || service.crmStatus === '4') &&
                          service.productDetails.program.programCode !== 'LDDP' &&
                          !disAllowRenroll(service)
                        "
                      >
                        <button type="button" id="ga-reenroll-product" class="btn btn-sm btn-property btn-renew mb-3" (click)="reEnroll(service)">
                          Re-Enroll
                        </button>
                      </ng-container>
                    </div>
                    <!-- first row buttons End -->
                    <!-- Second row buttons start -->
                    <div class="row justify-content-center">
                      <!-- Social Share -->
                      <div class="icon-circle mob-icon-circle mb-3 mr-2 d-none" (click)="showSocialIcons($event, i)">
                        <i class="fa fa-share fa-lg" aria-hidden="true"></i>
                      </div>
                      <div class="social social-show" *ngIf="shareIcon && shareIndex === i">
                        <ul class="social-list">
                          <li class="social-icons social-twitter" (click)="learnMoreNativeLink(service, 'twitter')">
                            <a>
                              <i class="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li class="social-icons social-facebook" (click)="learnMoreNativeLink(service, 'fb')">
                            <a>
                              <i class="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li class="social-icons social-linkedin" (click)="learnMoreNativeLink(service, 'linkedin')">
                            <a>
                              <i class="fa fa-linkedin" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li class="social-icons social-pinterest" (click)="learnMoreNativeLink(service, 'pinterest')">
                            <a>
                              <i class="fa fa-pinterest" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li class="social-icons social-mail" (click)="learnMoreNativeLink(service, 'mail')">
                            <a>
                              <i class="fa fa-envelope" aria-hidden="true"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <!-- Claim button  -->
                      <ng-container
                        *ngIf="
                          service.crmStatus === '2' &&
                          (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today &&
                          (!service?.programEffectiveDate || (service?.programEffectiveDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') <= today) &&
                          !hide_fileClaimButton.includes(service.productDetails.program.programId)
                        "
                      >
                        <div (click)="fileClaim(i)" onkeydown="return false" class="fileClaimsBtn mb-3">
                          <img class="fileClaimsIcon position-relative" src="../../../../img/my-account-claims/ClaimIcon.svg" alt="claims-icon" />
                          <span class="fileClaimsText position-relative">Submit a Claim</span>
                        </div>
                      </ng-container>
                    </div>
                    <!-- Review -->
                    <div class="row justify-content-center MB-10">
                      <div
                        class="review-link-cls"
                        *ngIf="service.crmStatus === '2' && (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today"
                        (click)="openCreateForm(service, i)"
                      >
                        Leave a Review
                      </div>
                    </div>
                    <div class="row justify-content-center MB-10">
                      <div
                        class="cancel-enrollment-cls"
                        *ngIf="
                          service?.paymentMethod &&
                          service?.paymentMethod !== 'Check' &&
                          service?.paymentMethod !== 'eCheck' &&
                          service?.billingFrequency !== 'Upfront' &&
                          service?.crmStatus === '2' &&
                          service?.productDetails?.program?.programCode !== 'LDDP' &&
                          (service?.renewalDate | date : 'yyyy-MM-ddHH:mm:s' : 'UTC') >= today &&
                          service?.paymentMethod !== 'onbill'
                        "
                        (click)="onUpdatePaymentClick(service.billingType, service.paymentMethod, service.crmEnrollmentGuid, service.productId)"
                      >
                        Update Payment
                      </div>
                    </div>
                    <div class="row justify-content-center MB-10">
                      <div
                        class="cancel-enrollment-cls"
                        *ngIf="service.crmStatus === '2' && (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today"
                        (click)="cancelEnrollment(service, i)"
                      >
                        Cancel Enrollment
                      </div>
                      <!-- Cancel Enrollment -->
                      <!-- <div class="cancel-enrollment" *ngIf="(service.crmStatus === '2') && ((service?.renewalDate | date:'yyyy-MM-dd HH:mm:s':'UTC') >= today)">
                                                <a (click)="cancelEnrollment(service, i)">Cancel Enrollment</a>
                                            </div> -->
                    </div>
                    <!-- Second row buttons End -->
                    <!-- remaining rows Start -->
                    <div class="row row-format">
                      <div class="col column-heading">
                        <i
                          *ngIf="service?.claimCount === 0 || hide_fileClaimButton.includes(service.productDetails.program.programId)"
                          class="fa fa-plus fa-claims-toggle fa-disable mr-3"
                          aria-hidden="true"
                          style="cursor: pointer"
                        ></i>
                        <i
                          *ngIf="
                            service?.claimCount > 0 && claimIndexArray && !hide_fileClaimButton.includes(service.productDetails.program.programId)
                          "
                          class="fa fa-plus fa-claims-toggle mr-3"
                          [ngClass]="{
                            'fa-minus':
                              claimExpanded && selectedAddIndex === (selectedEnrollAddIndex ? selectedEnrollAddIndex : 0) && claimIndex === i
                          }"
                          (click)="loadMyClaimsNewComponent(i)"
                        ></i
                        >&nbsp;Claims
                        <div
                          id="vline"
                          [style.height.px]="claimsLineHeight ? claimsLineHeight : 0"
                          *ngIf="showVerticalAfterClaims && claimExpanded && claimIndex === i"
                          [ngClass]="{ 'vertical-line': claimExpanded && claimIndex === i }"
                        ></div>
                      </div>
                      <div class="col enroll-col-txt">
                        <ng-container *ngIf="!hide_fileClaimButton.includes(service.productDetails.program.programId); else claimsNotApplicable">
                          <a *ngIf="service?.claimCount > 0" class="claim-button" (click)="loadMyClaimsNewComponent(i)" style="cursor: pointer">{{
                            service?.claimCount === 1 ? service?.claimCount + ' Claim' : service?.claimCount + ' Claims'
                          }}</a>
                          <span *ngIf="service?.claimCount === 0">&nbsp;No Claims</span>
                        </ng-container>
                        <ng-template #claimsNotApplicable> N/A </ng-template>
                      </div>
                    </div>
                    <div *ngIf="claimExpanded && i === (selectedEnrollAddIndexMOB ? selectedEnrollAddIndexMOB : 0) && claimIndex === i">
                      <hos-my-account-new-claims
                        [isDesktopView]="isDesktopView"
                        [enrollmentId]="service?.crmEnrollmentGuid"
                        (claimsLineEmitter)="claimsLineEmitterCall($event, selectedAddIndex)"
                        [selectedAddIndex]="selectedAddIndex"
                      >
                      </hos-my-account-new-claims>

                      <div class="row row-format">
                        <a class="view-all ml-5" (click)="onViewAllClick(i)">View All</a>
                      </div>
                    </div>

                    <ng-container *ngIf="!claimExpanded && i === (selectedEnrollAddIndexMOB ? selectedEnrollAddIndexMOB : 0)">
                      <!-- Enrollment Status row start -->
                      <div class="row row-format">
                        <div class="col column-heading">
                          <span class="pl">Status</span>
                        </div>
                        <div class="col enroll-col-txt clscrmStatus">
                          <span
                            class="crm-status-icon icon-status-active mr-2"
                            *ngIf="
                              service.expiredIn > 0 &&
                              service.crmStatus === '2' &&
                              (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today
                            "
                            ><i class="fa fa-check-circle fa-lg" aria-hidden="true"></i
                          ></span>
                          <span class="crm-status-icon icon-status-pending mr-2" *ngIf="service.crmStatus === '999' || service.crmStatus === '3'"
                            ><i class="fa fa-check-circle fa-lg" aria-hidden="true"></i
                          ></span>

                          <span
                            class="crm-status-icon icon-status-expired mr-2"
                            *ngIf="(service.expiredIn < 0 && service.crmStatus !== '4') || service.crmStatus === '5'"
                            ><i class="fa fa-exclamation-circle fa-lg" aria-hidden="true"></i
                          ></span>
                          <span class="crm-status-icon icon-status-suspended mr-2" *ngIf="service.expiredIn > 0 && service.crmStatus === '6'"
                            ><i class="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i
                          ></span>
                          <span class="crm-status-icon icon-status-cancelled mr-2" *ngIf="service.crmStatus === '4'"
                            ><i class="fa fa-times-circle fa-lg" aria-hidden="true"></i
                          ></span>
                          <span class="crm-status-text" [tooltip]="service | enrollmentStatusText" triggers="click">{{
                            service.crmStatus === '4'
                              ? 'Cancelled'
                              : service.crmStatus === '999' || service.crmStatus === '3'
                              ? 'Pending'
                              : service.isAlreadyEnrolled && service.renewNow === true && service.productDetails.program.programCode !== 'LDDP'
                              ? 'Renewed'
                              : service.expiredIn > 0 &&
                                service.crmStatus === '2' &&
                                (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today
                              ? 'Active'
                              : service.expiredIn < 0
                              ? 'Expired'
                              : service.crmStatus === '6'
                              ? 'Suspended'
                              : service.crmStatus === '5'
                              ? 'Closed'
                              : ''
                          }}</span>
                        </div>
                      </div>
                      <!-- Enrollment Status row end -->
                      <div class="row row-format">
                        <div class="col column-heading">
                          <span class="pl">Enrollment No</span>
                        </div>
                        <div class="col enroll-col-txt">
                          <span *ngIf="service?.crmStatus === '999' || service?.crmStatus === '3'">Pending</span>
                          <span class="notranslate" *ngIf="!(service?.crmStatus === '999' || service?.crmStatus === '3')">{{
                            '#' + service?.crmEnrollmentNumber
                          }}</span>
                        </div>
                      </div>
                      <div class="row row-format">
                        <div class="col column-heading">
                          <img
                            src="../../../../img/my-account/font-awesome-icons/calendar-check-solid.svg"
                            alt="Purchase-icon"
                            class="th-icon mb-1 mr-4"
                          />Purchase
                        </div>
                        <div class="col enroll-col-txt">
                          <!-- Purchase Date Change condition show crmchargedate for both paymentMethod  -->
                          <span class="p-0" *ngIf="service.billingType === 'onbill' && service.origin === 'HOS'; else showCrmChargeDate">{{
                            service?.createdAt ? (service?.createdAt | date : 'MM/dd/yy') : '-'
                          }}</span>
                          <ng-template #showCrmChargeDate
                            ><span class="p-0">{{
                              service?.enrollmentDate
                                ? (service?.enrollmentDate.split('T')[0] | date : 'MM/dd/yy')
                                : service?.crmChargeDate
                                ? (service?.crmChargeDate.split('T')[0] | date : 'MM/dd/yy')
                                : '-'
                            }}</span>
                          </ng-template>
                        </div>
                      </div>
                      <!--- start code for Coverage -->
                      <div class="row row-format">
                        <div class="col column-heading">
                          <img
                            src="../../../../img/my-account/font-awesome-icons/calendar-alt-solid.svg"
                            alt="Coverage-icon"
                            class="th-icon mb-1 mr-4"
                          />{{ service.crmStatus === '2' && service.productDetails.program.programCode === 'LDDP' ? 'Term End Date' : 'Coverage' }}
                        </div>
                        <div class="col enroll-col-txt">
                          <!--- start code for LDDP-->
                          <ng-container *ngIf="service.crmStatus === '2' && service.productDetails.program.programCode === 'LDDP'">
                            <span *ngIf="service.crmFrequency === 'Upfront'"> N/A </span>
                            <span *ngIf="service.crmFrequency === 'Monthly'">
                              {{ service?.renewalDate ? (service?.renewalDate.split('T')[0] | date : 'MM/dd/yy') : 'N/A' }}
                            </span>
                          </ng-container>
                          <ng-container *ngIf="service.crmStatus === '3' && service.productDetails.program.programCode === 'LDDP'">
                            N/A
                          </ng-container>
                          <!--- end code for LDDP-->
                          <ng-container *ngIf="service.productDetails.program.programCode !== 'LDDP'">
                            {{ service?.programEffectiveDate ? (service?.programEffectiveDate.split('T')[0] | date : 'MM/dd/yy') : '-' }}
                          </ng-container>
                        </div>
                      </div>
                      <!--- end code for Coverage -->
                      <!--- start code for Expiration -->
                      <div
                        class="row row-format"
                        [ngClass]="{
                          'renew-cls':
                            service.crmStatus === '2' &&
                            service.renewNow === true &&
                            !service.isAutoRenew &&
                            service.productDetails.program.programCode !== 'LDDP' &&
                            allowOffbillRenew(service) &&
                            (!service.isAlreadyEnrolled || renewedEnrollments?.length === 0)
                        }"
                      >
                        <div class="col-6 column-heading">
                          <img
                            src="../../../../img/my-account/font-awesome-icons/calendar-times-solid.svg"
                            alt="Renewal-icon"
                            class="th-icon mb-1 mr-4"
                          />{{
                            (service.crmStatus === '2' || service.crmStatus === '999') &&
                            (service?.renewalDate | date : 'yyyy-MM-dd HH:mm:s' : 'UTC') >= today &&
                            service.productDetails.program.programCode !== 'LDDP'
                              ? 'Renewal'
                              : (service.crmStatus === '2' || service.crmStatus === '999') && service.productDetails.program.programCode === 'LDDP'
                              ? 'Term'
                              : 'Expiration'
                          }}
                        </div>
                        <div
                          class="col-6 enroll-col-txt"
                          [ngClass]="{
                            'expired-alert-text': service.expiredIn < 0 && service?.renewalDate,
                            'cancel-alert-text': service.crmStatus === '4'
                          }"
                        >
                          <i
                            class="fa fa-exclamation-triangle fa-exclamation-triangle-color"
                            *ngIf="
                              service.crmStatus === '2' &&
                              service.renewNow === true &&
                              !service.isAutoRenew &&
                              service.productDetails.program.programCode !== 'LDDP' &&
                              allowOffbillRenew(service) &&
                              (!service.isAlreadyEnrolled || renewedEnrollments?.length === 0)
                            "
                          ></i>
                          <!--- start code for LDDP-->
                          <ng-container *ngIf="service.crmStatus === '2' && service.productDetails.program.programCode === 'LDDP'">
                            <span *ngIf="service.crmFrequency === 'Monthly'" class="p-0">
                              <span *ngIf="service?.enrollmentPrice?.TaxDetails?.PaymentTerm === 'TwoYears'"> 24 Months </span>
                              <span *ngIf="service?.enrollmentPrice?.TaxDetails?.PaymentTerm === 'FiveYears'"> 60 Months </span>
                            </span>
                            <span *ngIf="service.crmFrequency === 'Upfront'" class="p-0"> N/A </span>
                          </ng-container>
                          <ng-container *ngIf="service.crmStatus === '3' && service.productDetails.program.programCode === 'LDDP'">
                            N/A
                          </ng-container>
                          <!--- End code for LDDP-->
                          <ng-container
                            *ngIf="
                              service.statusReasonId &&
                              service.statusReasonId.toLowerCase() === statusReasonId.toLowerCase() &&
                              service.crmStatus === '4'
                            "
                          >
                            {{ service?.cancellationDate ? (service?.cancellationDate.split('T')[0] | date : 'MM/dd/yy') : '-' }}
                          </ng-container>
                          <ng-container
                            *ngIf="
                              service.productDetails.program.programCode !== 'LDDP' &&
                              !(
                                service.statusReasonId &&
                                service.statusReasonId.toLowerCase() === statusReasonId.toLowerCase() &&
                                service.crmStatus === '4'
                              )
                            "
                          >
                            {{ service?.renewalDate ? (service?.renewalDate.split('T')[0] | date : 'MM/dd/yy') : '-' }}
                          </ng-container>
                          <span
                            *ngIf="
                              service.crmStatus === '2' &&
                              service.renewNow === true &&
                              !service.isAutoRenew &&
                              service.productDetails.program.programCode !== 'LDDP' &&
                              allowOffbillRenew(service) &&
                              (!service.isAlreadyEnrolled || renewedEnrollments?.length === 0)
                            "
                          >
                            <a role="button" class="renew-now-text ml-1" (click)="renewNowService(service)">Renew Now</a>
                          </span>
                          <span *ngIf="service.crmStatus === '4'" class="font-weight-bold cancel-text">Cancelation Date</span>
                        </div>
                        <div
                          class="col-12 expire-alert text-center"
                          *ngIf="
                            service.crmStatus === '2' &&
                            service.renewNow === true &&
                            !service.isAutoRenew &&
                            service.productDetails.program.programCode !== 'LDDP' &&
                            allowOffbillRenew(service) &&
                            (!service.isAlreadyEnrolled || renewedEnrollments?.length === 0)
                          "
                        >
                          Expires Soon!
                        </div>
                      </div>
                      <!--- end code for Expiration -->
                      <!--- start code for Billing-->
                      <div
                        class="row row-format"
                        [ngClass]="{ upfront: service.crmFrequency === 'Upfront' && service.productDetails.program.programCode === 'LDDP' }"
                      >
                        <div class="col column-heading">
                          <img
                            src="../../../../img/my-account/font-awesome-icons/file-invoice-dollar-solid.svg"
                            alt="Billing-icon"
                            class="th-icon bill-icon mr-4"
                          />Billing
                        </div>
                        <div class="col enroll-col-txt">
                          <span class="clsprice font-weight-bold notranslate"
                            >$ {{ service?.enrollmentPrice.TaxDetails.EnrollmentPrice | number : '1.2-2' }}</span
                          >
                          <!--- start code for LDDP-->
                          <span *ngIf="!(service.productDetails.program.programCode === 'LDDP')"
                            >/{{ service && service.billingFrequency === 'Annual' ? 'Annually' : service.billingFrequency }}
                          </span>
                          <span *ngIf="service.productDetails.program.programCode === 'LDDP'" class="one-time-payment-txt">{{
                            service.crmFrequency === 'Upfront' ? 'One-time payment' : '/Monthly'
                          }}</span>
                          <!--- End code for LDDP-->
                        </div>
                      </div>
                      <!--- end code for Billing-->
                      <!--- start code for Payment-->
                      <div class="row row-format row-payment">
                        <div class="col column-heading"><i class="fa fa-usd th-icon payment-icon mr-4"></i>Payment</div>
                        <div class="col enroll-col-txt">
                          <span class="text font-weight-bold" *ngIf="service.billingType === 'onbill'">Utility Bill</span>
                          <span class="text font-weight-bold" *ngIf="service.billingType === 'offbill'">{{
                            service.paymentMethod === undefined || service.paymentMethod === 'CreditCard' ? 'Credit Card' : service.paymentMethod
                          }}</span>
                          <span
                            class="d-none"
                            *ngIf="
                              service.statusReasonId &&
                              service.statusReasonId.toLowerCase() === statusReasonId.toLowerCase() &&
                              service.crmStatus === '4' &&
                              service?.refundAmount &&
                              service?.refundAmount > 0
                            "
                          >
                            ${{ service?.refundAmount | number : '1.2-2' }} Refund
                          </span>
                          <span
                            class="d-none"
                            *ngIf="
                              service.statusReasonId &&
                              service.statusReasonId.toLowerCase() === statusReasonId.toLowerCase() &&
                              service.crmStatus === '4' &&
                              (service?.refundAmount === 0 || !service?.hasOwnProperty('refundAmount'))
                            "
                          >
                            $0.00 Refund
                          </span>
                        </div>
                      </div>
                      <!--- end code for Payment-->
                      <div class="row row-format d-flex align-items-center text-center">
                        <!--- start code for Confirmation Email-->
                        <div class="col">
                          <ng-container
                            *ngIf="
                              service.origin === 'HOS' &&
                              service.productDetails.program.programCode !== 'LDDP' &&
                              service.crmStatus !== '3' &&
                              service.crmStatus !== '999'
                            "
                          >
                            <a class="grey-links float-right ml-2 mt-1" (click)="downloadEnrolledPdf(service)">Confirmation Email</a
                            ><i class="fa fa-envelope-open-o th-icon float-right"></i>
                          </ng-container>
                          <!-- No Confirmation Email Start -->
                          <span
                            *ngIf="
                              service.productDetails.program.programCode !== 'LDDP' && (service.crmStatus === '3' || service.origin === 'ORACLE')
                            "
                            class="not-avail-text no-confirmation"
                            >-</span
                          >
                          <!-- No Confirmation Email end -->
                          <!--- start code for LDDP-->
                          <ng-container
                            *ngIf="
                              (service.origin === 'HOS' &&
                                service.crmStatus === '3' &&
                                service.productDetails.programId === leakDetectionProgramId &&
                                service.crmFrequency === 'Monthly') ||
                              (service.origin === 'HOS' &&
                                service.crmStatus === '2' &&
                                service.productDetails.program.programCode === 'LDDP' &&
                                service.crmFrequency === 'Monthly')
                            "
                          >
                            <i class="fa fa-envelope-open-o th-icon"></i>
                            <a class="grey-links float-right ml-2 mt-1" (click)="downloadContract(service)">Installment Contract</a>
                          </ng-container>
                          <ng-container
                            *ngIf="
                              (service.origin !== 'HOS' &&
                                service.crmStatus === '3' &&
                                service.productDetails.programId === leakDetectionProgramId &&
                                service.crmFrequency === 'Monthly') ||
                              (service.origin !== 'HOS' &&
                                service.crmStatus === '2' &&
                                service.productDetails.program.programCode === 'LDDP' &&
                                service.crmFrequency === 'Monthly')
                            "
                          >
                            <a class="grey-links float-right ml-2 mt-1">-</a>
                          </ng-container>
                          <ng-container *ngIf="service.origin === 'HOS' && service.crmStatus === '2' && service.crmFrequency === 'Upfront'">
                            <span class="not-avail-text">No Contract</span>
                          </ng-container>
                          <ng-container *ngIf="service.origin !== 'HOS' && service.crmStatus === '2' && service.crmFrequency === 'Upfront'">
                            <span class="not-avail-text">-</span>
                          </ng-container>
                          <!--- End code for LDDP-->
                        </div>
                        <!--- End code for Confirmation Email-->
                        <!--- start code for T&C / Contract-->
                        <div class="col">
                          <ng-container *ngIf="service.crmStatus !== '3'">
                            <i class="fa fa-file-pdf-o th-icon float-left mt-1"></i
                            ><a class="grey-links float-left ml-2 mt-1" (click)="goToTerms(service)">Terms & Conditions</a>
                          </ng-container>
                          <ng-container *ngIf="service.crmStatus === '3'">
                            <span class="not-avail-text">-</span>
                          </ng-container>
                        </div>
                        <!--- start code for T&C / Contract-->
                      </div>
                    </ng-container>

                    <!-- remaining rows End -->
                  </div>
                </div>
              </div>
            </div>
            <!-- Accordion card -->
          </div>
        </ng-container>
        <ng-container *ngIf="!services?.length && !showLoader">
          <div class="no-service-box"><img src="img/my-account/No_Services.svg" alt="No Service Image" /> No protection on this property</div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<div [ngClass]="{ loading: showLoader || isRenewNowClick }"></div>
