import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { nanoid } from 'nanoid';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../user/services/user.service';
import { GlobalVariables } from '../../../services/global-variables.service';
import { PaymentMethod, PaymentPayload, Stripe } from '../../../../models/payment/stripe';

declare let hp: any;

@Component({
  selector: 'hos-claims-payment-component',
  templateUrl: './claims-payment.component.html',
  styleUrls: ['./claims-payment.component.scss'],
})
export class ClaimsPaymentComponent implements OnInit, OnChanges {
  @Input() userInfo: any;

  @Input() claimsServiceFeeData: any;

  @Input() serviceFeeQuestNumber: any;

  @Input() selectedAddress: any;

  @Input() product: any;

  @Output() serviceFeeOptionAns = new EventEmitter();

  @Output() paymentData = new EventEmitter();

  @Output() cardSelected = new EventEmitter();

  isServiceFeeQuestOne: boolean = false;

  isServiceFeeQuestTwo: boolean = false;

  input: any = {
    inputArray: ['Yes', 'No'],
    answer: '',
  };

  radioMultiClrConfig: any = [];

  setCard: any;

  paymentType: any;

  paymentCards: any[];

  achCards: any[];

  setCardRequired: Boolean = true;

  showLoader: Boolean = false;

  cuid: String;

  serviceFees: number;

  sessionTokenGenerated: boolean = true;

  constructor(private userService: UserService, public globalVariables: GlobalVariables) {}

  ngOnInit() {
    this.cuid = nanoid();
    this.radioMultiClrConfig.colorConfigStyle = [];
    this.serviceFees = this.claimsServiceFeeData.ClaimsServiceFee.ServiceFee;
    this.input.inputArray.forEach(() => {
      this.radioMultiClrConfig.colorConfigStyle.push('#FFFFFF');
    });
    this.stripeInit();
  }

  ngOnChanges() {
    // this.radioMultiClrConfig.colorConfigStyle = [];
    // this.input.inputArray.forEach(data => {
    //     this.radioMultiClrConfig.colorConfigStyle.push('#FFFFFF');
    // });
    if (this.serviceFeeQuestNumber === 1) {
      this.isServiceFeeQuestOne = true;
      this.isServiceFeeQuestTwo = false;
    } else if (this.serviceFeeQuestNumber === 2) {
      this.isServiceFeeQuestTwo = true;
      this.isServiceFeeQuestOne = false;
    }
  }

  private stripeInit() {
    const paymentMethods = [PaymentMethod.CARD];
    console.log({ data: this });
    const element = document.getElementById('stripe-claim');
    const stripe = new Stripe();
    const current = this.userInfo?.address.find(p => p.customerGuid === this.selectedAddress.customerGuid);
    const oracleCustId = current?.oracleCustId;
    const payload: PaymentPayload = {
      customer: {
        oracleCustomerId: current.oracleAccountNumber,
        postalCode: this.selectedAddress.serviceAddress.zipcode,
        fullName: `${this.userInfo.firstName ?? ''} ${this.userInfo.lastName ?? ''}`,
        email: this.userInfo.email,
        oracleCustomerGuid: current?.customerGuid,
      },
      amount: this.serviceFees.toString(),
      mountNode: element,
      paymentMethods,
      enrollments: [
        {
          referenceNumber: `CLAIM SERVICE FEE-${this.product.productDetails.marketingCode}-${oracleCustId}-01`,
          amount: this.serviceFees,
        },
      ],
    };
    console.log({ payload });
    stripe
      .updatePaymentMethod(payload)
      .then(p => this.afterStripePayment(p))
      .catch(error => this.errorPaymentCallback(error));
  }

  afterStripePayment(response: any[]): any {
    if (response[0].error) {
      this.sessionTokenGenerated = true;
      this.errorPaymentCallback(response[0].error);
      return;
    }
    this.submitEtsPaymentInfo(response[0], 'new');
  }

  changeBackgroundClr(indexVal) {
    this.radioMultiClrConfig.colorConfigStyle.forEach((data, index) => {
      if (index === indexVal) {
        this.radioMultiClrConfig.colorConfigStyle[index] = '#C1E6FF';
      } else {
        this.radioMultiClrConfig.colorConfigStyle[index] = '#FFFFFF';
      }
    });
  }

  optionValueChange(Event: any) {
    const inputValue = Event;
    this.serviceFeeOptionAns.emit(inputValue);
  }

  changeExpDate(str, index, value) {
    const getCurrentYearPrefix = new Date().getFullYear().toString().slice(0, 2);
    return str.substr(0, index) + value + getCurrentYearPrefix + str.substr(index);
  }

  getMaskedAccountNumber(string) {
    return string.substr(string.length - 4, 4);
  }

  loadPaymentbox(customerGuid?: String, serviceAddress?: any, zipcode?: any, cusno?: any) {
    this.sessionTokenGenerated = false;
    this.userService.etsSignInToken().subscribe(
      data => {
        this.sessionTokenGenerated = true;
        const response = data.body;
        if (response.data && response.data.signInResponse && response.data.signInResponse.token) {
          const pluginOptions = {
            baseUrl: environment.hosted_payments_base_url,
            apiKey: response.data.signInResponse.token,
            paymentService: hp.PaymentService.TOKEN,
            instrumentId: customerGuid,
            paymentTypeOrder: [0],
            saveCustomer: true,
            correlationId: cusno,
            billingAddress: {
              addressLine1: serviceAddress,
              postalCode: zipcode,
            },
            transactionId: this.guid(),
            // eslint-disable-next-line @typescript-eslint/no-shadow
            successCallback: response => {
              this.submitEtsPaymentInfo(response, 'new');
              // add payment box
              /* this.loadPaymentbox(customerGuid, serviceAddress, zipcode, cusno); */
            },
            // eslint-disable-next-line @typescript-eslint/no-shadow
            errorCallback: response => {
              this.errorPaymentCallback(response);
            },
          };
          /* IE loader issue AP March 16 2020 */
          const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
          if (isIEOrEdge) {
            // eslint-disable-next-line no-console
            console.log('isIEOrEdge', isIEOrEdge);
          }
          (<any>$('#hp')).hp(pluginOptions);
          hp.Utils.reset(pluginOptions);
        }
      },
      () => {
        this.sessionTokenGenerated = true;
        this.errorPaymentCallback(
          'We are currently facing technical difficulties, please try to add your payment method again. Sorry for any inconvenience.',
        );
      },
    );
  }

  getCustomerPayments(done?: any) {
    this.paymentCards = [];
    this.achCards = [];
    this.userService.getCustomerAccounts(this.selectedAddress.customerGuid).subscribe(
      data => {
        const results = data.body;
        if (
          results &&
          results.data &&
          results.data.GetCustomerAccountResponse &&
          results.data.GetCustomerAccountResponse.GetCustomerAccountResult &&
          results.data.GetCustomerAccountResponse.GetCustomerAccountResult.Id !== undefined
        ) {
          this.userService.getCustomerCards(results.data.GetCustomerAccountResponse.GetCustomerAccountResult.Id).subscribe(
            data1 => {
              const results1 = data1.body;
              this.paymentCards = results1.data.creditCardAccounts.creditCardModel;
              this.achCards = results1.data.achAccounts.AchAccountModel;
              if (!!this.paymentCards && this.paymentCards.length > 0) {
                for (let i = 0; i < this.paymentCards.length; i++) {
                  this.paymentCards[i].ExpirationDate = this.changeExpDate(this.paymentCards[i].ExpirationDate.toString(), 2, '/');
                  this.paymentCards[i].AccountNumberMasked = this.getMaskedAccountNumber(this.paymentCards[i].AccountNumberMasked);
                }
              }
              if ((!!this.paymentCards && this.paymentCards.length > 0) || (!!this.achCards && this.achCards.length > 0)) {
                //
              } else {
                this.setCard = 'newCard';
                this.setCardRequired = false;
              }
              if (done) {
                done(false, true);
              }
            },
            () => {
              this.setCard = 'newCard';
              this.setCardRequired = false;
              if (done) {
                done(true, null);
              }
            },
          );
        } else {
          this.setCard = 'newCard';
          this.setCardRequired = false;
        }
      },
      () => {
        this.setCard = 'newCard';
        this.setCardRequired = false;
        if (done) {
          done(true, null);
        }
      },
    );
  }

  /**
   * Generate GUID.
   */
  guid() {
    return `${this.s4() + this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`;
  }

  /**
   * Random 4 character string.
   * @param product
   */
  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  /**
   * Submit ETS payment info.
   * @param res
   * @param cardType
   * @param paymentType
   */
  submitEtsPaymentInfo(res: any, cardType?: string, paymentType?: string) {
    const payload: any = {};
    this.showLoader = true;
    payload.orderId = this.cuid;
    // eslint-disable-next-line no-underscore-dangle
    payload.customerId = this.userInfo._id;

    if (cardType !== 'new') {
      payload.PaymentType = paymentType;
      payload.FinancialInstrumentToken = res.CreditCardToken;
      payload.Last4Digits_CC = res.AccountNumberMasked.substr(res.AccountNumberMasked.length - 4);
      payload.ExpirationDate = res.ExpirationDate;
      payload.CardType = res.CardType;
      payload.ETSCustomerId = res.CustomerToken;
    } else {
      payload.PaymentType = 'CC';
      payload.Last4Digits_CC = res.instrument_last_four;
      payload.ExpirationDate = res.instrument_expiration_date;
      payload.CardType = res.instrument_type;
      payload.FinancialInstrumentToken = res.instrument_id;
      payload.ETSCustomerId = res.customer_token;
    }

    payload.upfrontPaymentToken = res.upfrontPaymentToken;
    payload.fingerprint = res.fingerprint;
    payload.bankAccountType = res.bankAccountType;
    payload.oracleCustId = this.userInfo?.address.find(p => p.customerGuid === this.selectedAddress.customerGuid)?.oracleCustId;
    payload.oracleAccountNumber = this.userInfo.oracleAccountNumber;
    payload.CardType = res.CardType;
    payload.billingType = 'offbill';
    payload.orders = [];
    this.paymentData.emit({
      error: false,
      data: payload,
    });
  }

  /**
   * Error payment callback.
   * @param res
   */
  errorPaymentCallback(err: any) {
    this.paymentData.emit({
      error: true,
      data: err,
    });
  }
}
