<div>
  <div class="row ML-0 MR-0 MB-20">
    <div class="col-lg-12 pad-desktop larger-tab-width-cls">
      <section class="content-section content-block register-section">
        <div class="row page-wrap no-gutters MB-0 PD-0">
          <div class="right-form-box row col-12 no-gutters PL-0">
            <div class="row col-12 no-gutters">
              <!-- General information left side area -->
              <div class="general-information left-side step-1 col-12 PR-0">
                <div class="form-border">
                  <div class="form-header row m-0">
                    <img src="/img/MyProfile/Profile-Ico-01.svg" class="mt-auto px-4" />
                    <span class="my-auto">General Information</span>
                  </div>
                  <!-- <div class="py-4 px-4">
                    <form #customerForm="ngForm" novalidate (keypress)="guestFormService.formKeyPressHandler($event)">
                      <div class="row">
                        <div class="head-spacing col-12 col-md-12">
                          <span class="hr-line heading-text">
                            Already have a web account? Click <a (click)="reEnableCartIcon()" routerLink="/user/login">here</a> to login and
                            renew/update your enrollments.</span
                          ><br /><br />
                          <span class="hr-line heading-text">
                            Please enter your Customer Number in the field below to update your enrollment(s).</span
                          >
                        </div>
                        <div class="form-group col-12 col-md-6">
                          <label for="selectType" class="hr-line"
                            >Customer Number
                            <span class="pull-right tooltip-container cust-no-tooltip">
                              <i
                                class="fa fa-question-circle"
                                aria-hidden="true"
                                placement="top"
                                tooltip="Don't know your account number, please call customer service {{ guestFormService.customer_service_number }}"
                              ></i>
                            </span>
                          </label>
                          <input
                            type="text"
                            class="form-control round-form"
                            name="accountNumber"
                            [pattern]="guestFormService.customer_number_regex"
                            ngModel
                            #accountNumber="ngModel"
                            maxlength="100"
                            [ngClass]="{ 'is-invalid': (accountNumber.dirty || guestFormService.invalidForm) && !accountNumber.valid }"
                            (keyup)="guestFormService.onCustomerNumberChange()"
                            required
                            placeholder="CUS-XXXXXXXXXXXXXXXX"
                          />
                          <small class="form-text text-danger" *ngIf="(accountNumber.dirty || guestFormService.invalidForm) && !accountNumber.valid"
                            >Please enter a properly formatted Customer Number - CUS-XXXXXXXXXXXXX</small
                          >
                          <!-- <small class="form-text text-danger"
                                                        *ngIf="accountNumber.dirty && guestFormService.customerExistInHOS">This
                                                        customer number you have entered is already registered.</small>
                        </div>
                        <div class="form-group col-12 col-md-6 text-right p-0 mt-auto">
                          <button
                            type="submit"
                            class="btn primary-btn block full-width m-b mr-3"
                            (click)="guestFormService.invalidForm = true; guestFormService.accountNumberKeyPressHandler(customerForm)"
                          >
                            Next >
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div class="px-4 hr-content">
                    <span class="heading-text">If you do not know your customer number, please enter your information in the fields below.</span>
                  </div> -->

                  <form #registerForm="ngForm" novalidate (keypress)="guestFormService.keyPressHandler($event)">
                    <div class="py-4 px-4">
                      <div [ngClass]="{ 'pointer-events-none': false }">
                        <div [ngClass]="{ 'disable-cls': false }">
                          <div class="row" id="name">
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">First Name<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': guestFormService.isClicked && !firstName.valid }"
                                name="firstName"
                                required
                                [(ngModel)]="guestFormService.user.firstName"
                                #firstName="ngModel"
                                maxlength="80"
                                [pattern]="guestFormService.blockUnicodeRegex"
                                (change)="guestFormService.user.firstName = guestFormService.user.firstName.trim()"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="firstName.touched && firstName.errors?.required"
                                >First name is required.</small
                              >
                              <small id="emailHelp" class="form-text text-danger" *ngIf="firstName.errors && firstName.errors.pattern"
                                >Please remove special characters.</small
                              >
                            </div>

                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Last Name<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': guestFormService.isClicked && !lastName.valid }"
                                name="lastName"
                                required
                                [(ngModel)]="guestFormService.user.lastName"
                                #lastName="ngModel"
                                maxlength="80"
                                [pattern]="guestFormService.blockUnicodeRegex"
                                (change)="guestFormService.user.lastName = guestFormService.user.lastName.trim()"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="lastName.touched && lastName.errors?.required"
                                >Last name is required.</small
                              >
                              <small id="emailHelp" class="form-text text-danger" *ngIf="lastName.errors && lastName.errors.pattern"
                                >Please remove special characters.</small
                              >
                            </div>
                          </div>
                          <hr class="margin-em-hr" />

                          <div class="row" id="email">
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Email<sup class="text-danger">*</sup></label>
                              <input
                                type="email"
                                class="form-control round-form"
                                (keypress)="guestFormService.keyPressHandler(email, 'email')"
                                (blur)="guestFormService.emailBlurHandler(email)"
                                [ngClass]="{ 'is-invalid': (email.isBlur || guestFormService.isClicked) && !email.valid }"
                                [pattern]="guestFormService.email_regex"
                                name="email"
                                required
                                [(ngModel)]="guestFormService.formEmail"
                                #email="ngModel"
                                maxlength="100"
                              />

                              <!-- <small id="emailHelp" class="form-text text-danger"
                                                                *ngIf="isExistingUser && email.valid">The email address
                                                                you
                                                                have
                                                                entered is already registered</small> -->
                              <small id="emailHelp" class="form-text text-danger" *ngIf="email.isBlur && !email.valid"
                                >Please enter valid email address.</small
                              >
                              <small
                                id="emailHelp"
                                class="form-text text-danger"
                                *ngIf="(guestFormService.isClicked && !email.valid && !email.touched) || guestFormService.isDisposableEmail"
                                >Please enter valid email address.</small
                              >
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Confirm Email</label>
                              <input
                                type="email"
                                class="form-control round-form"
                                (keypress)="guestFormService.keyPressHandler(confirmEmail)"
                                (blur)="guestFormService.blurHandler(confirmEmail)"
                                name="confirmEmail"
                                [(ngModel)]="guestFormService.confirmEmailId"
                                #confirmEmail="ngModel"
                                maxlength="100"
                              />
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Phone Number<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                class="form-control round-form"
                                (blur)="guestFormService.phoneBlurHandler(phoneNumber)"
                                (keypress)="guestFormService.keyPressHandler(phoneNumber, 'phoneNumber'); guestFormService.numberOnly($event)"
                                maxlength="10"
                                minlength="10"
                                [ngClass]="{ 'is-invalid': guestFormService.isClicked && !phoneNumber.valid }"
                                [pattern]="guestFormService.phone_number_regex"
                                name="phoneNumber"
                                required
                                [(ngModel)]="guestFormService.user.phoneNumber"
                                #phoneNumber="ngModel"
                              />
                              <small
                                id="emailHelp"
                                class="form-text text-danger"
                                *ngIf="guestFormService.isClicked && !guestFormService.homePhoneErrorMsg && !phoneNumber.valid"
                                >Please enter valid phone number.</small
                              >
                              <small id="emailHelp" class="form-text text-danger" *ngIf="phoneNumber.isBlur || guestFormService.isClicked">{{
                                homePhoneErrorMsg
                              }}</small>
                              <!-- <small id="emailHelp" *ngIf="showPhoneError" class="form-text text-danger">This phone number already exists.</small> -->
                              <!-- HWR-2485 -->
                            </div>
                          </div>
                          <hr class="margin-em-hr" />
                          <div class="row">
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Address<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                autocomplete="off"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': (streetNumber.dirty || guestFormService.isClicked) && !streetNumber.valid }"
                                pattern="{{ guestFormService.streetNumberValidation }}"
                                name="streetNumber"
                                required
                                [(ngModel)]="guestFormService.user.address[0].serviceAddress.streetNumber"
                                (keyup)="guestFormService.getSuggestion($event)"
                                #streetNumber="ngModel"
                                maxlength="100"
                              />
                              <small
                                id="emailHelp"
                                class="form-text text-danger"
                                *ngIf="(streetNumber.dirty || guestFormService.isClicked) && !streetNumber.valid"
                                ><span *ngIf="streetNumber.errors?.required">Address is required.</span
                                ><span *ngIf="streetNumber.errors?.pattern">Address is invalid.</span></small
                              >
                              <div class="showDropDownWrapper">
                                <div *ngIf="guestFormService.showDropDown" class="search-drop-down">
                                  <div
                                    (click)="guestFormService.selectValue(addr.street_line, addr.city, 1)"
                                    class="search-results"
                                    *ngFor="let addr of guestFormService.suggestedAddress.suggestions"
                                  >
                                    <a [innerHTML]="addr.street_line + ', ' + addr.city"> {{ addr.street_line }}, {{ addr.city }} </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">Apt., Unit, PO Box, etc.</label>
                              <input
                                type="text"
                                autocomplete="off"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': guestFormService.isClicked && !streetName.valid }"
                                name="streetName"
                                [(ngModel)]="guestFormService.user.address[0].serviceAddress.streetName"
                                #streetName="ngModel"
                                maxlength="100"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="guestFormService.isClicked && !streetName.valid"
                                >Apt., Unit, PO Box, etc. is required.</small
                              >
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">City<sup class="text-danger">*</sup></label>
                              <input
                                type="text"
                                class="form-control round-form"
                                [ngClass]="{ 'is-invalid': guestFormService.isClicked && !city.valid }"
                                name="city"
                                required
                                [(ngModel)]="guestFormService.user.address[0].serviceAddress.city"
                                #city="ngModel"
                                maxlength="80"
                              />
                              <small id="emailHelp" class="form-text text-danger" *ngIf="guestFormService.isClicked && !city.valid"
                                >City address is required.</small
                              >
                            </div>
                            <div class="form-group col-12 col-md-6" style="cursor: not-allowed">
                              <label for="selectType">ZIP Code<sup class="text-danger">*</sup></label>
                              <hos-zipcode
                                [isClicked]="false"
                                [address]="guestFormService.address"
                                (notify)="guestFormService.onZipcodeUpdated($event)"
                                [isFromCheckout]="false"
                              ></hos-zipcode>
                            </div>
                            <div class="form-group col-12 col-md-6">
                              <label for="selectType">State</label>
                              <ng-select
                                [disabled]="true"
                                [items]="guestFormService.states"
                                bindLabel="value"
                                bindValue="value"
                                (change)="guestFormService.stateChangeHandler($event)"
                                [(ngModel)]="guestFormService.user.address[0].serviceAddress.state"
                                name="state"
                                #state="ngModel"
                                class="skiptranslate state-arrow"
                                required
                              >
                              </ng-select>
                            </div>
                          </div>
                          <!-- </form> -->
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div *ngIf="config.hasHomeOwnerCheckbox" id="service-address" class="col-12 col-md-12 home-owner">
    <div class="custom-checkbox highlight-checkbox">
      <input
        type="checkbox"
        id="authorizeinfo-check"
        [disabled]="globalVariables?.cartCheckout?.items?.length === 0 || (cartProducts && cartProducts.length === 0)"
        name="authorizeinfo-check"
        [checked]="
          (globalVariables?.cartCheckout?.items?.length > 0 ||
            productsByBillingType?.offbill?.products?.length > 0 ||
            productsByBillingType?.onbill?.products?.length > 0) &&
          isAuthorizedToViewForm
        "
        (click)="checkboxFunction()"
        autocomplete="off"
      />
      <label for="authorizeinfo-check" class="authorizeinfo-check"
        >By clicking the checkbox you are confirming that you are the homeowner, and your name is on the water bill.</label
      >
    </div>
  </div>

  <div class="col-12 flex-center-cls pad-btn-checkout">
    <button
      class="btn btn-sm nav-btn enroll-now skiptranslate submit-btm"
      (click)="guestFormService.goToStep(registerForm, $event)"
      title="Proceed To Checkout"
    >
      {{ config.submitButtonText }}
    </button>
  </div>

  <div class="noteTextEnroll pad-5-20" *ngIf="config.hasTerms">
    <!-- HWR-2190 Jan 9 2020 changes start -AP -->
    <p class="text-justify">
      <u>Protection Program</u>. If I have one or more Protection Programs in my cart above then by clicking &ldquo;Enroll Now&rdquo; above I
      acknowledge and agree (i) to the Terms and Conditions for the Program(s), which can be found at
      <u><a target="_blank" href="https://www.awrusa.com/terms">awrusa.com/terms</a></u> (ii) that the Program(s) I selected will auto-renew on an
      annual basis until cancelled by me in accordance with the Terms and Conditions of the Program(s); (iii) that I meet the eligibility requirements
      in the Terms and Conditions; and (iv) that there is a 30 day waiting period before I can make a claim. There is NO 30-Day waiting period if you
      are renewing your existing Protection Program(s) before the expiration date. To opt out of auto-renewal, click the &ldquo;Edit&rdquo; link above
      and select &ldquo;Do Not Auto Renew&rdquo;.
    </p>
    <p>All amounts are in US dollars.</p>
    <!-- HWR-2190 Jan 9 2020 changes end -AP -->
  </div>
</div>
<div [ngClass]="{ loading: guestFormService.showLoader }"></div>
