import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ElementRef,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  Optional,
  HostListener,
  EventEmitter,
  Output,
  ViewChild,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import { DatePipe, isPlatformBrowser, Location, isPlatformServer } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { find, difference } from 'lodash-es';
import { SimpleModalService } from 'ngx-simple-modal';
import { UpdateServiceDetailComponent } from '../update-service-detail/update-service-detail.component';
import { AutoRenewModalComponent } from '../auto-renew-modal/auto-renew-modal.component';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { environment } from '../../../../environments/environment';
import { ProductService } from '../../../product/services/product.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { CrmProxyService } from '../../../core/services/crm-proxy.service';
import { LocationService } from '../../../home/services/location.service';
import { UtilsService } from '../../../core/services/utils.service';
import { UserService } from '../../services/user.service';
import { ClaimsQuestionModalComponent } from '../../../core/component/claims-question-modal/claims-question-modal.component';
import { RenewNowModalComponent } from '../renew-now-modal/renew-now-modal.component';
import { SiteContentService } from '../../../core/services/siteContents';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { ProductReviewFormComponent } from '../../../product-details/component/product-review-form/product-review-form.component';
import { AlertService } from '../../../core/services/alert.service';
import { ClaimsService } from '../../../core/services/claims.service';
import { WindowScrolling } from '../../../core/services/window-scrolling.service';
import { ManagePaymentComponent } from '../manage-payment/manage-payment.component';
import { OneTimePaymentOracleComponent } from '../../../core/component/one-time-payment-oracle/one-time-payment-oracle.component';
import { CancelEnrollmentComponent } from '../../../core/component/cancel-enrollment-modal/cancel-enrollment-modal.component';
import { ResyncEnrollmentService } from '../../services/resync-enrollment.service';
import { CartService } from '../../../cart/services/cart.service';
import { CustomerServiceValidationModalComponent } from '../customer-service-validation-modal/customer-service-validation-modal.component';

@Component({
  selector: 'hos-my-account-services-redesign',
  templateUrl: './my-account-services-redesign.component.html',
  styleUrls: ['./my-account-services-redesign.component.scss'],
})
export class MyAccountServicesRedesignComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() services: any;

  @Input() user: any;

  userDetails: any;

  @Input() renewedEnrollments: any;

  termsLink: string;

  imageBaseUrl: string;

  baseUrlLoc: string;

  today: any;

  partnerName: any;

  locData: any[];

  websitePartnerName: String;

  statusReasonId: String = environment.statusReasonId;

  math = Math;

  marketing_code_id: any;

  billingData: any;

  taxData: any;

  siteContent: any;

  leakDetectionProgramId: string = environment.leakDetectionProgramId;

  hide_fileClaimButton: any = environment.hide_fileClaimButton;

  @Input() allAddresses: any;

  shareIcon: Boolean = false;

  shareIndex = 0;

  claimIndex = 0;

  siteNameParam: any;

  isbrowser = true;

  isserver = false;

  claimPreviousIndex: any;

  claimExpanded: Boolean = false;

  claimIndexArray: any = [];

  isDesktopView: any;

  showLoader = false;

  locationDetailsForOneTime: any;

  claimCountBasedOnEnrGuid: any;

  customer_service_number: any;

  reviewIndex: any;

  @ViewChild('socailmenu') menu?: ElementRef;

  @ViewChild('toggleButton') toggleButton?: ElementRef;

  @Input() locationCodeData: any;

  @Input() selectedEnrollAddIndex: any;

  @Output() changeAddressEmitter = new EventEmitter();

  claimsLineHeight: any;

  selectedEnrollAddIndexMOB: any = null;

  showVerticalAfterClaims: boolean = false;

  isEnrHasUpdatePayment: boolean = false;

  isEnrHasRenewNow: boolean = false;

  propertiesInfo: any;

  isTabletView: any;

  isHome: any;

  parentEnrolments: any = [];

  isRenewNowClick: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isDesktopView = event.target.innerWidth > 1023;
    this.isTabletView = !!(event.target.innerWidth > 767 && event.target.innerWidth <= 1023);
  }

  constructor(
    private simpleModalService: SimpleModalService,
    private orderService: OrdersService,
    private datePipe: DatePipe,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private productService: ProductService,
    private crmProxyService: CrmProxyService,
    private utilsService: UtilsService,
    private siteContentService: SiteContentService,
    private locationService: LocationService,
    private cdRef: ChangeDetectorRef,
    public globalVariables: GlobalVariables,
    private location: Location,
    private alertService: AlertService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject('serverUrl') protected origin: string,
    private route: ActivatedRoute,
    private claimsService: ClaimsService,
    private windowScrolling: WindowScrolling,
    private renderer: Renderer2,
    private resyncEnrollment: ResyncEnrollmentService,
    private router: Router,
    private cartService: CartService,
  ) {
    this.websitePartnerName = this.sessionStorageService.getItem('websitePartnerName');
    this.isbrowser = isPlatformBrowser(platformId);
    this.isserver = isPlatformServer(platformId);
    this.renderer.listen('window', 'click', (e: Event) => {
      e.stopPropagation();
      if (this.menu && e.target !== this.toggleButton.nativeElement && e.target !== this.menu.nativeElement) {
        this.shareIcon = false;
        this.shareIndex = 0;
      }
    });
    this.route.queryParams.subscribe(params => {
      this.isHome = params.home;
    });
  }

  ngOnInit() {
    this.customer_service_number = environment.customer_service_number;

    this.isDesktopView = window.screen.width > 1023;
    this.isTabletView = !!(window.screen.width > 767 && window.screen.width <= 1023);

    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:s', 'UTC');
    this.userService.getUserProfile(this.user.user._id).subscribe(data => {
      const userProfile = data.body;
      this.userDetails = userProfile;
    });
    this.imageBaseUrl = `${environment.Image_API_Endpoint}${environment.Product_service_API_Endpoint}`;
    this.baseUrlLoc = `${environment.Image_API_Endpoint}${environment.Location_service_API_Endpoint}`;
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    if (this.partnerName) {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(
        locationData => {
          this.locData = locationData.body;
          this.locationDetailsForOneTime = locationData.body;
        },
        () => {
          this.locData = [];
        },
      );
    } else {
      this.productService.getProviderContent(this.sessionStorageService.getItem('location_code')).subscribe(
        data => {
          this.sessionStorageService.setItem('websitePartnerName', data.body.websitePartnerName);
          this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
          if (this.partnerName) {
            this.locData = data.body;
          }
          this.locationDetailsForOneTime = data.body;
        },
        () => {
          this.locData = [];
        },
      );
    }
    if (this.isHome === 'false') {
      if (this.allAddresses[0] && this.allAddresses[0].customerNo && this.user.user._id) {
        const customernumber = this.allAddresses[0].customerNo;
        this.resyncEnrollment.resyncEnrollment(customernumber, this.user.user._id).subscribe();
      }
    }
    this.getAlertsWithEnrolledProdIcons();
  }

  ngAfterViewInit() {
    this.scrollToSection();
  }

  ngOnChanges() {
    this.getClaimsInfo();
  }

  goToTerms(service): void {
    if (service.productDetails) {
      const program = service.productDetails.program.programCode;
      const isSafari = !!navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream;
      let newTab: any;
      let isCombo = 0;
      if (service.productDetails.program.products.length > 1) {
        isCombo = 1;
      }
      if (iOS && isSafari) {
        newTab = window.open();
      }

      this.locationService.getLocationCode(service.address.serviceAddress.zipcode).subscribe(result => {
        const locationData = result.body;
        if (result.status === 200) {
          const zipcodeCheck =
            locationData.locationCodeId === service.address.serviceAddress.locationCodeId ? service.address.serviceAddress.zipcode : '0';
          this.productService
            .getTermsAndConditionsForProduct(
              'english',
              service.address.serviceAddress.state,
              zipcodeCheck,
              program,
              service.productDetails.contactType,
              isCombo,
              service.productDetails.coverageType,
              service.address.serviceAddress.locationCodeId,
            )
            .subscribe(
              response => {
                this.termsLink = `${environment.Image_API_Endpoint + environment.TERMS_SERVICE_ENDPOINT}/${
                  response.body.result && response.body.result.length ? response.body.result[0].pdfUrl : ''
                }`;
                if (iOS && isSafari) {
                  newTab.location.href = this.termsLink;
                } else {
                  window.open(this.termsLink, '_blank');
                }
              },
              error => {
                if (error.status === 404) {
                  this.showErrorModal('Error', 'File is not available, Please try later.');
                }
              },
            );
        }
      });
    } else {
      this.showErrorModal('Error', 'File is not available, Please try later.');
    }
  }

  downloadEnrolledPdf(service) {
    // If origin is ORACLE don't display confirmation email
    if (service.origin === 'HOS') {
      let event;
      const link = document.createElement('a');
      if (document.createEvent) {
        event = document.createEvent('MouseEvent');
        event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      } else {
        event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
      }
      const isSafari = !!navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream;
      let newTab: any;
      if (iOS && isSafari) {
        newTab = window.open();
      }
      this.getTemplateId(service.orderId, service.crmEnrollmentNumber, service.webEnrollmentId, templateId => {
        if (templateId) {
          if (iOS && isSafari) {
            // tslint:disable-next-line:max-line-length
            newTab.location.href = this.getImage(
              `${environment.Image_API_Endpoint}${environment.Template_service_API_Endpoint}download/${templateId}`,
            );
          } else {
            // tslint:disable-next-line:max-line-length
            link.href = this.getImage(`${environment.Image_API_Endpoint}${environment.Template_service_API_Endpoint}download/${templateId}`);
            link.target = '_blank';
            link.download = `${templateId}.pdf`;
            link.dispatchEvent(event);
          }
        } else {
          this.showErrorModal('Error', 'Enrollment confirmation not available.', 'ok', 'fa fa-exclamation-triangle');
        }
      });
    }
  }

  getTemplateId(enrollId: any, enrollNo: any, webEnrollmentId: any, done: any) {
    this.orderService.getOrderTemplate(enrollId, this.user.user._id, enrollNo, webEnrollmentId).subscribe(
      data => {
        const templateData = data.body;
        if (templateData.length && templateData[0] && templateData[0].downloadId) {
          const enrollFound = find(templateData, temp => temp.enrollmentNumber === enrollNo);
          if (enrollFound) {
            done(enrollFound.downloadId);
          } else {
            done(templateData[0].downloadId);
          }
        } else {
          done(null);
        }
      },
      () => {
        done(null);
      },
    );
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string, alertIcon?: string, trackAPIGATracking?: any) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
          alertIcon,
          confirmBtnId: trackAPIGATracking || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  downloadContract(service) {
    // If origin is ORACLE don't display contract
    if (service.origin === 'HOS') {
      let event;
      const link = document.createElement('a');
      if (document.createEvent) {
        event = document.createEvent('MouseEvent');
        event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      } else {
        event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
      }
      const isSafari = !!navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream;
      let newTab: any;
      if (iOS && isSafari) {
        newTab = window.open();
      }

      if (iOS && isSafari) {
        // tslint:disable-next-line:max-line-length
        newTab.location.href = this.getImage(
          `${environment.Image_API_Endpoint}${environment.Template_service_API_Endpoint}downloadcontract/${service.orderId}`,
        );
      } else {
        // tslint:disable-next-line:max-line-length
        link.href = this.getImage(`${environment.Image_API_Endpoint}${environment.Template_service_API_Endpoint}downloadcontract/${service.orderId}`);
        link.target = '_blank';
        link.download = `${service.orderId}.pdf`;
        link.dispatchEvent(event);
      }
    }
  }

  upgradeService(serviceDetail: any) {
    let validEnrollments = [];
    if (this.services?.length > 0) {
      validEnrollments = this.services.filter(enrollment => {
        const date1 = new Date();
        const date2 = new Date(enrollment.renewalDate);
        const expiredIn = date2.getTime() - date1.getTime();
        if (expiredIn > 0) {
          return enrollment;
        }
      });
    }

    this.simpleModalService
      .addModal(
        UpdateServiceDetailComponent,
        {
          service: serviceDetail,
          enrollServices: validEnrollments,
          upsell: false,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  showUpgrade(createdAt) {
    const d1 = new Date(createdAt);
    const d2 = new Date();
    const diff = (d2.getTime() - d1.getTime()) / 1000;
    if (diff > environment.upgrade_enable) {
      return true;
    }
    return false;
  }

  // File Claims Button
  fileClaim(index) {
    this.simpleModalService.addModal(
      ClaimsQuestionModalComponent,
      {
        product: this.services[index],
        user: this.userDetails,
      },
      {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: false,
      },
    );
  }

  renewNowService(serviceDetail: any) {
    this.productService
      .getDefaultCodeForRenewal(
        serviceDetail.productDetails.marketingCodeId,
        serviceDetail.address.serviceAddress.locationCodeId,
        serviceDetail.productDetails.coverageType,
        serviceDetail.productDetails.contactType,
        serviceDetail.productDetails.programId,
        serviceDetail.address.customerNo,
        serviceDetail.evo_newmarketingcode,
      )
      .subscribe(data => {
        const productData = data.body;
        this.renewNow(productData, serviceDetail);
      });
  }

  renewNow(productData: any, serviceDetail: any) {
    this.isRenewNowClick = true;
    serviceDetail = JSON.parse(JSON.stringify(serviceDetail));
    this.marketing_code_id = productData.marketingCodeId;
    const paymentmethod = 'Check';
    if (productData.defaultLocationCodeId === null) {
      productData.defaultLocationCodeId = this.sessionStorageService.getItem('location_code');
    }
    this.locationService.getLocationsContent(productData.defaultLocationCodeId).subscribe(data => {
      const locationCode = data.body.locationCode;
      const frequencyVal = serviceDetail.billingFrequency === 'Monthly' ? 'Monthly' : 'Annually';
      const getProductBillingType = this.crmProxyService.getProductBillTypes(
        productData.defaultLocationCodeId,
        productData.program.programId,
        this.marketing_code_id,
      );
      const getProducttaxAmount = this.crmProxyService.getProductsTax(
        serviceDetail.address.customerGuid,
        productData.marketingCodeId,
        productData.program.programId,
        locationCode,
        paymentmethod,
        frequencyVal,
      );

      forkJoin([getProductBillingType, getProducttaxAmount]).subscribe(
        productsData => {
          this.billingData = productsData[0].body.data;
          this.taxData = productsData[1].body.data;
          serviceDetail.productDetails = productData;
          const billingType =
            this.billingData[0].operations.OnBill || (this.billingData[0].operations.OnBill && this.billingData[0].operations.OffBill)
              ? 'onbill'
              : 'offbill';
          if (billingType !== serviceDetail.billingType) {
            const strError = `We are unable to process your renewal at this time. Please contact Customer Service at <span class="app-txt">${environment.renewCustomerCareNumber}</span> for assistance. Thank you.`;
            this.showErrorModal("We're sorry", strError, '', '');
            this.isRenewNowClick = false;
            return;
          }
          this.isRenewNowClick = false;
          this.simpleModalService
            .addModal(
              RenewNowModalComponent,
              {
                title: '',
                message: '',
                serviceData: serviceDetail,
                billingData: this.billingData,
                taxData: this.taxData,
                marketing_code_id: this.marketing_code_id,
                description: 'Click continue to select your payment option',
              },
              {
                wrapperClass: 'modal-main-class in',
                closeOnClickOutside: false,
              },
            )
            .subscribe(() => {
              /* if (isRenew) {
                            serviceDetail.renew = false;
                        } */
            });
        },
        () => {
          this.isRenewNowClick = false;
          const errorTitle = "We're sorry, we are experiencing technical difficulties.";
          const strError = `Please try again. If you continue to experience issues, please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span> for assistance. Thank you.`;
          const trackAPIGATracking = 'trackProductTaxBillingAPIFail';
          this.showErrorModal(errorTitle, strError, '', '', trackAPIGATracking);
        },
      );
    });
  }

  showAutorenew(expiryDate) {
    const d1 = new Date(expiryDate);
    const d2 = new Date();
    // const diff = (d2.getTime() - d1.getTime()) / 1000;
    // To calculate the time difference of two dates
    const diff = d1.getTime() - d2.getTime();

    // To calculate the no. of days between two dates
    const diffindays = diff / (1000 * 3600 * 24);
    if (diffindays > environment.auto_renew_enable) {
      return true;
    }
    return false;
  }

  checkBillingType(service) {
    if (
      service.billingType === 'onbill' ||
      !Object.prototype.hasOwnProperty.call(service, 'isAutoRenew') ||
      service.paymentMethod === 'Check' ||
      service.paymentMethod === 'eCheck'
    ) {
      return false;
    }
    return true;
  }

  allowOffbillRenew(service) {
    if (service.billingType === 'onbill') {
      return false;
    }
    return true;
  }

  isStateValid(service) {
    if (environment.hide_autorenew_states.includes(service.address.serviceAddress.state)) {
      return false;
    }
    return true;
  }

  autorenewService(serviceDetail: any) {
    this.utilsService.getPartnerDetails$().subscribe(
      data => {
        this.siteContent = data.body.site.header;
        this.simpleModalService
          .addModal(
            AutoRenewModalComponent,
            {
              title: '',
              message: '',
              service: serviceDetail,
              enrollServices: this.services,
              siteContent: this.siteContent,
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: false,
            },
          )
          .subscribe(isConfirmed => {
            if (isConfirmed) {
              serviceDetail.isAutoRenew = true;
            }
          });
      },
      () => {
        this.showErrorModal('Error', 'Data is not available at this moment, Please try again later.');
      },
    );
  }

  /** ********* HWR-2967 - Start ******************** */

  async getClaimsInfo() {
    if (this.services && this.services.length > 0) {
      this.claimIndexArray = [];
      this.showLoader = true;
      const custcrmEnrollmentGuids = this.services.map(ele => ele.crmEnrollmentGuid);
      if (custcrmEnrollmentGuids && custcrmEnrollmentGuids.length > 0) {
        const claimDataApi = await this.getClaimsApi(custcrmEnrollmentGuids);
        if (claimDataApi.status === 200) {
          this.claimCountBasedOnEnrGuid = claimDataApi.body;
          const guidUndefinedIndex: any = [];
          custcrmEnrollmentGuids.filter((ele, index) => {
            if (ele === undefined) {
              guidUndefinedIndex.push(index);
            }
          });
          guidUndefinedIndex.forEach(ind => {
            const obj: any = {};
            obj.enrollmentId = undefined;
            obj.count = 0;
            this.claimCountBasedOnEnrGuid.splice(ind, 0, obj);
          });
        } else {
          this.claimCountBasedOnEnrGuid = [];
        }
      } else {
        this.claimCountBasedOnEnrGuid = [];
      }
      this.services.forEach((element, index) => {
        if (
          this.claimCountBasedOnEnrGuid.length > 0 &&
          this.claimCountBasedOnEnrGuid[index] &&
          this.claimCountBasedOnEnrGuid[index].enrollmentId !== undefined &&
          element.crmEnrollmentGuid === this.claimCountBasedOnEnrGuid[index].enrollmentId
        ) {
          element.claimCount = this.claimCountBasedOnEnrGuid[index].count;
        } else {
          element.claimCount = 0;
        }
        this.claimIndexArray.push({
          value: false,
        });
      });
      this.showLoader = false;
    }
  }

  public getClaimsApi(cust_crmEnrollmentGuids): Promise<any> {
    cust_crmEnrollmentGuids = cust_crmEnrollmentGuids.filter(x => x !== undefined);
    return this.claimsService.getTotalClaimsByEnrollmentGuids(cust_crmEnrollmentGuids).toPromise();
  }

  changeAddress(index, address) {
    this.services = [];
    const prevIndex = this.selectedEnrollAddIndex;
    this.claimExpanded = false;
    this.selectedEnrollAddIndex = index;
    const data = {
      index,
      address,
      isAccordionChanged: true,
    };
    this.globalVariables.isAccordionChanged = true;
    this.selectedEnrollAddIndexMOB = null;
    if (prevIndex !== index) {
      this.changeAddressEmitter.emit(data);
    } else {
      this.selectedEnrollAddIndex = null;
    }
  }

  changeEnrollment(index) {
    this.claimExpanded = false;
    this.selectedEnrollAddIndexMOB = index;
  }

  claimsLineEmitterCall(claimsData, selectedAddIndex?) {
    const offsetHeight = document.getElementById(`${this.selectedEnrollAddIndex}-claims-list`)
      ? document.getElementById(`${this.selectedEnrollAddIndex}-claims-list`).offsetHeight
      : 0;
    this.isEnrHasUpdatePayment = false;
    this.isEnrHasRenewNow = false;
    const enrDetailHt: any = document.getElementById(`${selectedAddIndex}-enr-detail-height-${this.claimIndex}`)
      ? document.getElementById(`${selectedAddIndex}-enr-detail-height-${this.claimIndex}`).offsetHeight
      : 0;
    const singleDivClaim: any = document.getElementById('single-div-claim') ? document.getElementById('single-div-claim').offsetHeight : 0;
    if (window.screen.width < 375) {
      this.claimsLineHeight = offsetHeight - 128;
    } else if (window.screen.width <= 768 && window.screen.width >= 375) {
      this.claimsLineHeight = offsetHeight - 130;
    } else if (window.screen.width > 768) {
      this.claimsLineHeight = offsetHeight + enrDetailHt - singleDivClaim / 3.55;
    }
    const renewNow: any = !!document.getElementById(`${selectedAddIndex}-renew-now-id-${this.claimIndex}`);
    if (renewNow) {
      this.isEnrHasRenewNow = true;
    }
    const updatePayment: any = !!document.getElementById(`${selectedAddIndex}-update-payment-${this.claimIndex}`);
    if (updatePayment) {
      this.isEnrHasUpdatePayment = true;
    }
    if (claimsData) {
      this.showVerticalAfterClaims = true;
      this.cdRef.detectChanges();
    }
  }

  showAlertModal(data?: any, done?) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: data.title,
          message: data.message,
          alertIcon: data.alertIcon,
          cancelText: data.cancelText,
          confirmText: data.confirmText,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          if (done) {
            done(false);
          }
        }
      });
  }

  /* Dialog Modal Open Review Form */
  openCreateForm(reviewProduct, index?) {
    this.reviewIndex = index;
    const programName =
      reviewProduct.productDetails.coverageType && reviewProduct.productDetails.coverageType === 'UNLIMITED'
        ? `Unlimited ${reviewProduct.productDetails.programName}`
        : reviewProduct.productDetails.programName
        ? reviewProduct.productDetails.programName
        : ' ';
    const programId =
      reviewProduct.productDetails.coverageType && reviewProduct.productDetails.coverageType === 'UNLIMITED'
        ? `unlimited-${reviewProduct.productDetails.programId}`
        : reviewProduct.productDetails.programId;
    this.simpleModalService
      .addModal(
        ProductReviewFormComponent,
        {
          programName,
          programId,
          reviews: [], // this.reviews
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(result => {
        if (result === true && result !== undefined) {
          this.alertService.success('Review Has Been Submitted Successfully!');
        } else if (result !== undefined) {
          this.alertService.error(`${result}`);
        }
        this.windowScrolling.enable();
      });
  }

  /* New Claims Component - Start */
  loadMyClaimsNewComponent(index) {
    this.showVerticalAfterClaims = false;

    if (index !== this.claimIndex) {
      this.claimIndex = index;
      this.claimExpanded = false;
    }
    this.claimExpanded = !this.claimExpanded;
    this.claimPreviousIndex = index;
  }
  /* New Claims Component - End */

  /* Social Share - Start */
  showSocialIcons($event, index) {
    $event.stopPropagation();
    if (index !== this.shareIndex) {
      this.shareIndex = index;
      this.shareIcon = false;
    }
    this.shareIcon = !this.shareIcon;
  }

  learnMoreNativeLink(service: any, link: any) {
    let url;
    let originUrl;
    const zipcodeParams = service.address.serviceAddress.zipcode;
    const typeParams = service.productDetails.contactType === 1 ? 'residential' : 'commercial';
    const locationcodeParams = this.route.snapshot.params.locationcode || this.locationCodeData.locationCode.toLowerCase();
    this.siteNameParam = this.sessionStorageService.getItem('providerState');
    if (this.isbrowser) {
      originUrl = window.location.origin;
    }
    if (this.isserver) {
      originUrl = this.origin;
    }
    if (this.siteNameParam) {
      url = `${originUrl}/${this.siteNameParam}/product/${zipcodeParams}/${locationcodeParams}/${typeParams}/${service.productDetails.webProgramName
        .toString()
        .split(' ')
        .join('-')
        .split(',')
        .join('')
        .toLowerCase()}`;
    } else {
      url = `${originUrl}/product/${zipcodeParams}/${locationcodeParams}/${typeParams}/${service.productDetails.webProgramName
        .toString()
        .split(' ')
        .join('-')
        .split(',')
        .join('')
        .toLowerCase()}`;
    }
    const args = {
      url,
      title: 'Protect your family’s home with this affordable protection plan!',
      description: '',
      image: '',
      via: '',
      tags: '',
    };
    const width = 820;
    const height = 500;
    const left = Math.floor((screen.width - width) / 2);
    const top = Math.floor((screen.height - height) / 4);
    let params = `width=${width}, height=${height}`;
    params += `, top=${top}, left=${left}`;
    params += ', directories=no';
    params += ', location=no';
    params += ', menubar=no';
    params += ', resizable=no';
    params += ', scrollbars=no';
    params += ', status=no';
    params += ', toolbar=no';
    switch (link) {
      case 'fb':
        this.popupfacebook(args, params);
        break;
      case 'twitter':
        this.popuptweet(args, params);
        break;
      case 'linkedin':
        this.popuplinkedin(args, params);
        break;
      case 'pinterest':
        this.popupPinterest(args, params);
        break;
      case 'mail':
        this.popupMail(args);
        break;
      default:
    }
  }

  /* Facebook Share Popup */
  popupfacebook(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */
    let shareUrl = 'https://www.facebook.com/sharer/sharer.php';
    shareUrl += `?u=${args.url}`;
    if (args.title) {
      shareUrl += `&title=${args.title}`;
    }
    if (args.description) {
      shareUrl += `&description=${args.description}`;
    }
    if (args.image) {
      shareUrl += `&picture=${args.image}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Twitter Share Popup */
  popuptweet(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */
    let shareUrl = 'https://twitter.com/intent/tweet';
    shareUrl += `?url=${encodeURIComponent(args.url)}`;
    if (args.title) {
      shareUrl += `&text=${args.title}`;
    }
    if (args.via) {
      shareUrl += `&via=${args.via}`;
    }
    if (args.tags) {
      shareUrl += `&hashtags=${args.tags}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Linked In Share Popup */
  popuplinkedin(args, screenParams) {
    /* Passing encodeURIComponent as fix of truncate url */

    let shareUrl = 'http://www.linkedin.com/shareArticle';
    shareUrl += `?url=${args.url}`;
    if (args.title) {
      shareUrl += `&title=${args.title}`;
    }
    if (args.description) {
      shareUrl += `&summary=${args.description}`;
    }
    const newwindow = window.open(shareUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Pinterest Save Popup */
  popupPinterest(args, screenParams) {
    /* Need to add a visible image for it to show in pnterest (Append the url in media query parameter) */
    /* Passing encodeURIComponent as fix of truncate url */
    let pinterestUrl = `https://pinterest.com/pin/create/button/?url=${args.url}`;
    // if text is not provided, pin button won't work.
    if (args.description) {
      pinterestUrl += `&description=${args.description}`;
    } else if (document) {
      const descElm = document.querySelector('meta[property="og:description"]');
      if (descElm) {
        pinterestUrl += `&description=${descElm.getAttribute('content')}`;
      }
    }
    if (args.image) {
      pinterestUrl += `&media=${args.image}`;
    } else if (document) {
      const imageElm = document.querySelector('meta[property="og:image"]');
      if (imageElm) {
        pinterestUrl += `&media=${imageElm.getAttribute('content')}`;
      }
    }
    const newwindow = window.open(pinterestUrl, 'name', screenParams);
    if (window.focus) {
      newwindow.focus();
    }
  }

  /* Mail to Popup */
  popupMail(args) {
    /* Passing encodeURIComponent as fix of truncate url */
    const mailUrl = `mailto:?subject=Check out this product&body=Protect your family%27s home with this affordable protection plan%21  %3A  ${encodeURIComponent(
      args.url,
    )}`;
    const newwindow = window.open(mailUrl);
    if (window.focus) {
      newwindow.focus();
    }
  }
  /* Social Share - End */

  /* Update Payment - Start */
  onUpdatePaymentClick(billingType, cardType, crmEnrollmentGuid: String, productId) {
    const OtherGuid: String = this.services
      .filter(x => {
        if (x.renewalDate) {
          const renewalDateUTC: any = this.datePipe.transform(new Date(x.renewalDate), 'yyyy-MM-dd HH:mm:s', 'UTC');
          if (
            x.productId === productId &&
            Date.parse(renewalDateUTC) >= Date.parse(this.today) &&
            x.crmEnrollmentGuid !== crmEnrollmentGuid &&
            x.crmStatus !== '4'
          ) {
            return x.crmEnrollmentGuid;
          }
        }
      })
      .map(obj => obj.crmEnrollmentGuid);
    if (OtherGuid.length > 0) {
      this.showErrorModal(
        'Sorry for Inconvenience',
        `<p style="margin-top:-20px;margin-bottom:0;">There was a problem processing your payment update. Please contact Customer Service for further assistance. <b><a href="tel:${this.customer_service_number}">${this.customer_service_number}</a></b></p>`,
        'ok',
        'fa fa-exclamation-triangle',
      );
    } else if (billingType === 'offbill') {
      this.simpleModalService.addModal(
        ManagePaymentComponent,
        {
          crmEnrollmentGuid,
          OtherGuid,
          userAllDetails: this.userDetails,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      );
    }
  }
  /* Update Payment - End */

  onViewAllClick(index) {
    this.claimIndexArray[index].value = false;
    this.claimExpanded = false;
  }

  showUpdatePayment(lastupdatedat) {
    if (!lastupdatedat) {
      return true;
    }
    const d1 = new Date(lastupdatedat);
    const d2 = new Date();
    const diff = d2.getTime() - d1.getTime();
    const diffinhours = diff / (1000 * 3600);
    if (diffinhours > environment.update_paymentplan_hours) {
      return true;
    }
    return false;
  }

  oneTimePayment(service) {
    const OtherGuid: String = this.services
      .filter(x => {
        if (x.renewalDate) {
          const renewalDateUTC: any = this.datePipe.transform(new Date(x.renewalDate), 'yyyy-MM-dd HH:mm:s', 'UTC');
          if (
            x.productId === service.productId &&
            Date.parse(renewalDateUTC) >= Date.parse(this.today) &&
            x.crmEnrollmentGuid !== service.crmEnrollmentGuid
          ) {
            return x.crmEnrollmentGuid;
          }
        }
      })
      .map(obj => obj.crmEnrollmentGuid);

    // if (OtherGuid.length > 0) {
    //     this.showErrorModal('Sorry for Inconvenience', `Please contact Customer Service at <span class="app-txt"> ${this.customer_service_number}</span>`, 'ok', 'fa fa-exclamation-triangle');
    // } else {
    this.simpleModalService.addModal(
      OneTimePaymentOracleComponent,
      {
        crmEnrollmentGuid: service.crmEnrollmentGuid,
        locationDetailsForOneTime: this.locationDetailsForOneTime,
        OtherGuid,
      },
      {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: false,
      },
    );
    // }
  }

  isActionRequried(service) {
    let returnValue = false;
    if (!service) {
      return returnValue;
    }
    if (new Date(service.renewalDate) <= new Date()) {
      return returnValue;
    }
    if (
      service.crmStatus === '2' &&
      service.renewNow === true &&
      !service.isAutoRenew &&
      service.productDetails.program.programCode !== 'LDDP' &&
      this.allowOffbillRenew(service) &&
      (!service.isAlreadyEnrolled || !this.renewedEnrollments.length)
    ) {
      // Renew
      returnValue = true;
    } else if (service.crmStatus === '6' && service.productDetails.program.programCode !== 'LDDP' && this.allowOffbillRenew(service)) {
      // Suspended
      returnValue = true;
    } /* else if (service.crmStatus === '2' && (service.crmEnrollmentGuid ? true : false) && this.showUpgrade(service.createdAtInCRM) && service.isUpgradeAuthorized && (renewalDate >= this.today)) {
            returnValue = true;
        }

        */
    return returnValue;
  }

  scrollToSection() {
    // if first and second property selected scroll to top for desktop, for tablet and mobile screen scroll to top on first property selection
    if (
      this.isbrowser &&
      ((this.isDesktopView && this.selectedEnrollAddIndex !== 0 && this.selectedEnrollAddIndex !== 1) ||
        (this.isTabletView && this.selectedEnrollAddIndex !== 0) ||
        (!this.isDesktopView && !this.isTabletView && this.selectedEnrollAddIndex !== 0))
    ) {
      const selectedIndex = this.selectedEnrollAddIndex;
      document.getElementById(`card-${selectedIndex}`).scrollIntoView();
    } else {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        'slow',
      );
    }
  }
  /** ********* HWR-2967 - End ******************** */

  getAlertsWithEnrolledProdIcons() {
    this.orderService.getProductIcons(this.user.user._id).subscribe(
      results => {
        this.showLoader = true;
        if (results.status === 200) {
          const response = results.body.data;

          const allCustomerNumbers = this.allAddresses.map(address => {
            if (![undefined, 'undefined', null, 'null', ''].includes(address.customerNo)) {
              return address.customerNo;
            }
            address.customerNo = 'no_custNo';
            return address.customerNo;
          });

          const enrolledProductsCustomerNumber = response.map(data => {
            if (![undefined, 'undefined', null, 'null', ''].includes(data)) {
              return Object.keys(data)[0];
            }
          });

          const missingCustomerNumbers: string[] = difference(allCustomerNumbers, enrolledProductsCustomerNumber);
          if (missingCustomerNumbers.length > 0) {
            missingCustomerNumbers.forEach(custNumber => {
              const obj = {};
              if (![undefined, 'undefined', null, 'null', ''].includes(custNumber)) {
                obj[custNumber] = {
                  icons: [],
                  isActionRequired: false,
                };
                const index = allCustomerNumbers.indexOf(custNumber);
                if (index !== -1) {
                  response.splice(index, 0, obj);
                }
              }
            });
          }

          const arr = [];
          allCustomerNumbers.forEach(custNumber => {
            const data = find(response, custNumber);
            data[custNumber].icons = Array.from(new Set(data[custNumber].icons)).reverse();
            arr.push(data);
          });
          this.propertiesInfo = arr;
          this.showLoader = false;
        }
      },
      () => {
        this.showLoader = false;
        this.propertiesInfo = [];
      },
    );
  }

  cancelEnrollment(service: any, index: any) {
    if (this.isbrowser) {
      if (window && (window as any).lpTag) {
        (window as any).lpTag.newPage(window.location.href);
      }
    }
    if (
      this.services[index].cancelEnrollmentDetails &&
      this.services[index].cancelEnrollmentDetails.isFormSubmitted &&
      this.services[index].cancelEnrollmentDetails.isEmailSent
    ) {
      this.showAlertModal({
        title: 'Your have already requested to cancel enrollment. Our representative will get back to you.',
        message: '',
        alertIcon: 'fa fa-info-circle red',
        cancelText: ' ',
        confirmText: 'OK',
      });
      return;
    }
    if (this.isbrowser) {
      if (window && (window as any).lpTag) {
        const address = service.address.billingAddress;
        (window as any).lpTag.sdes.send([
          {
            type: 'personal',
            personal: {
              firstname: this.userDetails.firstName,
              lastname: this.userDetails.lastName,
              contacts: [
                {
                  address: {
                    region: `${address.streetNumber} ${address.streetName || ''}, ${address.city}, ${address.state}, ${address.zipcode}`,
                  },
                },
              ],
            },
          },

          {
            type: 'ctmrinfo',
            info: {
              accountName: service.productDetails.webProgramName || service.productDetails.customerProgramName,
              storeNumber: service.crmEnrollmentNumber,
              customerId: service.crmCustomerNumber,
            },
          },
        ]);
        (window as any).lpTag.newPage(window.location.href);
      }
    }
    this.simpleModalService
      .addModal(
        CancelEnrollmentComponent,
        {
          confirmText: 'Submit',
          serviceDetails: service,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe((data: any) => {
        if (data) {
          this.services[index].cancelEnrollmentDetails = data.cancelEnrollmentDetails;
        }
      });
  }

  showAuthenticationModal(payload: any, existingCodes: any, title = '', modalFor = 0, emailpayload = {}) {
    this.simpleModalService
      .addModal(
        CustomerServiceValidationModalComponent,
        {
          title,
          message: 'In progress',
          alertIcon: 'fa fa-info-circle red',
          cancelText: 'Cancel',
          confirmText: 'Continue',
          data: '',
          modalFor,
          existing: existingCodes,
          deleteAddressPayload: emailpayload,
          address: payload,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {});
  }

  deleteServiceAddress(event, address) {
    event.stopPropagation();
    let actionType = 'delete_web_account';
    let deleteWebAccountFlag = true;
    if (this.allAddresses.length > 1) {
      actionType = 'delete_property';
      deleteWebAccountFlag = false;
    }
    const deleteAddressPayload = {
      actionType,
      customerNo: address.customerNo,
      deleteWebAccountFlag,
      email: this.user.user.email,
      customerId: this.user.user._id,
      addressId: address._id,
    };
    this.delCustPropertyOrAccount(deleteAddressPayload, address);
  }

  delCustPropertyOrAccount(deleteAddressPayload, address) {
    const message =
      'Are you sure? Please confirm that you would like to have your online profile removed for this property. If you change your mind, you can always come back and register this property again.';
    let title = 'Delete Web Account';
    if (deleteAddressPayload.actionType === 'delete_property') {
      title = 'Delete Property';
    }
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title,
          message,
          alertIcon: 'fa fa-exclamation-circle red',
          confirmText: 'Yes',
          cancelText: 'Cancel',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed === 'confirmed') {
          this.showAuthenticationModal(address, deleteAddressPayload.email, 'Please enter your password:', 2, deleteAddressPayload);
        } else {
          this.showLoader = false;
        }
      });
  }

  reEnroll(serviceData: any) {
    const orderProductDetails = serviceData.productDetails;
    const query = `contact_type=${orderProductDetails.contactType}&program_ids=${orderProductDetails.programId}&allProducts=true`;
    this.productService.getMarketingCodes(query, serviceData.locationId).subscribe(dataRes => {
      if (dataRes.status === 200) {
        const productDetails = dataRes.body.result[0];
        if (orderProductDetails.programId === productDetails.programId) {
          const selectedZipcode = serviceData.address.serviceAddress.zipcode;
          const selectedAddress = this.allAddresses.filter(y => y.customerNo === serviceData.address.customerNo);
          this.showLoader = true;
          const productData: any = productDetails;
          productData.isBundle = productData.program.products.length > 1;
          productData.locationId = serviceData.locationId;
          productData.zipcode = selectedZipcode;
          productData.customerNo = serviceData.crmCustomerNumber;
          productData.isUpgrade = false;
          productData.description = serviceData.description;
          productData.status = 'PENDING_PAYMENT';
          this.globalVariables.billingFrequency = serviceData.billingFrequency;
          this.globalVariables.waterBillOnOff = selectedAddress[0].waterBillOnOff;
          productData.isReEnroll = true;
          this.cartService.addItem(productData, '', (err, data, status) => {
            if (err) {
              if (data.status === 409) {
                if (data.error.errPopupMessage) {
                  this.showErrorModal(data.error.errPopupTitle, data.error.errPopupMessage, 'Ok', '', data.error.gaEventLabel);
                } else {
                  this.showErrorModal(
                    'Upgrade to Bundle',
                    `If you are looking to bundle your existing services,
                              please visit your My Services page and view the list of opportunities from your current services.`,
                    'Ok',
                  );
                }
              } else if (data.status === 400) {
                this.showErrorModal(
                  'Upgrade to Bundle',
                  `If you are looking to bundle your existing services,
                        please visit your My Services page and view the list of opportunities from your current services.`,
                  'Ok',
                );
              } else {
                this.showErrorModal('Error', 'Error while adding item to cart');
              }
            } else {
              if (status === 201) {
                //   this.openBundle(data.json());
              } else if (!!data && data.length > 0) {
                this.sessionStorageService.setItemToloclaStorage('deletedCartData', JSON.stringify(data));
              }
              this.router.navigate(['/payment']);
            }
            this.showLoader = false;
          });
        }
      }
    });
  }

  disAllowRenroll(serviceData) {
    if (serviceData) {
      if (serviceData.parentEnrollmentId) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.parentEnrolments.indexOf(serviceData.parentEnrollmentId) === -1 ? this.parentEnrolments.push(serviceData.parentEnrollmentId) : null;
      }
      if (this.services.length === 1) {
        return false;
      }
      return this.services.some(service => {
        if (service._id !== serviceData._id) {
          if (service.productDetails && service.productDetails.program && service.productDetails.program.products) {
            if (serviceData.productDetails && serviceData.productDetails.program && serviceData.productDetails.program.products) {
              if (
                service.productDetails.program.products.filter(element => {
                  serviceData.productDetails.program.products.includes(element);
                })
              ) {
                if (this.parentEnrolments.indexOf(serviceData.crmEnrollmentGuid) > -1) {
                  return true;
                }
                if (['2', '3', '999'].indexOf(service.crmStatus) > -1 && service.expiredIn > 0) {
                  return true;
                }
                if (new Date(serviceData.crmChargeDate) < new Date(service.crmChargeDate)) {
                  return true;
                }
              }
            }
          }
        }
        return false;
      });
    }
  }
}
