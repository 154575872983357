/* eslint-disable prefer-destructuring */
import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { nanoid } from 'nanoid';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { isPlatformBrowser } from '@angular/common';
import { UtilsService } from '../../../core/services/utils.service';
import { environment } from '../../../../environments/environment';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { ProductService } from '../../../product/services/product.service';
import { LocationService } from '../../../home/services/location.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { CrmProxyService } from '../../../core/services/crm-proxy.service';
import { UserService } from '../../services/user.service';
import { CartService } from '../../../cart/services/cart.service';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { CartQuickCheckoutService } from '../../../cart/services/cart-quick-checkout.service';
import { ShoppingCart } from '../../../cart/model/cart.model';
import { PaymentMethod, PaymentPayload, Stripe } from '../../../models/payment/stripe';

declare let hp: any;

export interface RenewNowModalModel {
  title: string;
  message: string;
  description: string;
  billingData: any;
  taxData: any;
  serviceData: any;
  marketing_code_id: any;
  userDataToken?: any;
  isFromCheckout?: Boolean;
  productData?: any;
  services?: any;
}

@Component({
  selector: 'hos-renew-now-modal',
  templateUrl: './renew-now-modal.component.html',
  styleUrls: ['./renew-now-modal.component.scss'],
})
export class RenewNowModalComponent extends SimpleModalComponent<RenewNowModalModel, boolean> implements RenewNowModalModel, OnInit, OnDestroy {
  title: string;

  message: string;

  referer: String;

  description: string;

  tax: any;

  serviceData: any;

  enrollemenntguid: any;

  productServiceBaseUrl: String = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;

  successCall: boolean = false;

  termsLink: string;

  marketing_code_id: any;

  showLoader: Boolean = false;

  productsByBillingType: any;

  public cartProducts: Array<any>;

  cuid: String;

  customerServiceNumber: any;

  billingData: any;

  taxData: any;

  userDataToken: any;

  isFromCheckout: Boolean = false;

  billingfrequency: string;

  cartData: any;

  providerState: any;

  siteName: any;

  partnerObj: any = {};

  cartSubscription: any;

  public cart: Observable<ShoppingCart>;

  cartLocationID: String;

  cartCusNo: String;

  customerAddresses: any[];

  billingfrequencyArr = ['Monthly', 'Annual'];

  billingFrequencyCheckout: any;

  productData: any;

  customer_service_number: any;

  isbrowser = true;

  payment = (window as any).PaymentHubWidget;

  customerDetailsForStripe: any;

  isAPIProcessed: boolean = false;

  services: any;

  constructor(
    private simpleModalService: SimpleModalService,
    private router: Router,
    private utilsService: UtilsService,
    private orderService: OrdersService,
    private crmProxyService: CrmProxyService,
    private productService: ProductService,
    private userService: UserService,
    private cartService: CartService,
    private locationService: LocationService,
    private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    public globalVariables: GlobalVariables,
    private cartQuickCheckoutService: CartQuickCheckoutService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super();
    this.referer = this.activatedRoute.snapshot.queryParams.referer;
    this.isbrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.customer_service_number = environment.customer_service_number;
    this.billingFrequencyCheckout = this.serviceData.billingFrequency;
    this.enrollemenntguid = this.serviceData.crmEnrollmentGuid;
    this.cuid = nanoid();
    this.customerServiceNumber = environment.customer_service_number;
    this.siteName = this.sessionStorageService.getItem('providerState');
    this.providerState = this.sessionStorageService.getItem('websitePartnerName');
    this.partnerObj = {
      siteName: this.siteName,
      providerName: this.providerState,
    };
    if (this.isFromCheckout) {
      /* const serviceAddress = `${this.serviceData.address.serviceAddress.streetNumber} ${this.serviceData.address.serviceAddress.streetName}, ${this.serviceData.address.serviceAddress.city} ${this.serviceData.address.serviceAddress.state} ${this.serviceData.address.serviceAddress.zipcode}`;
      this.loadPaymentbox(
        this.serviceData.crmCustomerGuid,
        serviceAddress,
        this.serviceData.address.serviceAddress.zipcode,
        this.serviceData.address.customerNo,
      ); */
      this.agreeToRenew(this.serviceData);
    }
  }

  cancelHandler() {
    this.router.navigate(['']);
    this.close();
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  agreeToRenew(serviceData) {
    if (!this.isFromCheckout) {
      this.result = true;
      this.close();
    }
    this.showLoader = true;
    const productData: any = serviceData.productDetails;
    productData.locationId = serviceData.locationId;
    productData.zipcode = serviceData.address.serviceAddress.zipcode;
    productData.customerNo = serviceData.crmCustomerNumber;
    productData.enrollmentGuid = [serviceData.crmEnrollmentGuid];
    productData.description = serviceData.description;
    productData.isRenew = true;
    productData.isUpgrade = false;
    productData.status = 'PENDING_PAYMENT';
    productData.parentEnrollmentDate = serviceData.renewalDate;
    productData.parentEnrollmentId = serviceData.crmEnrollmentGuid;
    this.globalVariables.billingFrequency = serviceData.billingFrequency;
    this.billingFrequencyCheckout = serviceData.billingFrequency;
    if (this.isFromCheckout) {
      this.cartQuickCheckoutService.getCustomerCart(serviceData.customerId, this.userDataToken.authHeader).subscribe(
        data => {
          if (data.status === 200) {
            this.deleteCart(serviceData.customerId, productData);
          }
        },
        error => {
          if (error.status === 404) {
            // this.cartService.createCart(cartObj, this.userDataToken.authHeader).subscribe(data => {
            //     if (data.status === 200) {
            this.sessionStorageService.deleteItem('cart');
            this.addItemToCart(productData);
            // }
            //   });
          }
        },
      );
    } else {
      this.addItemToCart(productData);
    }
  }

  showErrorModal(title: string, message: string, confirmationMsg?: string, alertIcon?: string, trackAPIGATracking?: any, reload?) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
          confirmationMsg,
          alertIcon,
          confirmBtnId: trackAPIGATracking || 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {
        if (reload || this.isFromCheckout) {
          window.location.reload();
        }
      });
  }

  /**
   * Update cart status to complete.
   * @param done
   */
  updateSuccessCartStatus(done: any) {
    this.cartService.updateProductCartStatus('ASYNC_IN_PROGRESS', errCart => {
      if (!errCart) {
        done(null, true);
      } else {
        done(true, false);
      }
    });
  }

  /**
   * Multiple enrollment request.
   * @param products
   * @param done
   */
  enrollmentRequest(products: any, done: any) {
    const apiArray = products.map(product => this.orderService.createOrder(product, product.billingType, this.serviceData.customerId));

    forkJoin(apiArray).subscribe({
      next: (results: any[]) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const result of results) {
          if (result.status !== 200) {
            done(true, result);
            return;
          }
        }
        done(null, results);
      },
      error: err => done(true, err),
    });
  }

  goToTerms(service): void {
    if (service.productDetails) {
      const program = service.productDetails.program.programCode;
      const isSafari = !!navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(<any>window).MSStream;
      let newTab: any;
      let isCombo = 0;
      if (service.productDetails.program.products.length > 1) {
        isCombo = 1;
      }
      if (iOS && isSafari) {
        newTab = window.open();
      }
      this.productService
        .getTermsAndConditionsForProduct(
          'english',
          service.address.serviceAddress.state,
          service.address.serviceAddress.zipcode,
          program,
          service.productDetails.contactType,
          isCombo,
          service.productDetails.coverageType,
          service.address.serviceAddress.locationCodeId,
        )
        .subscribe(
          response => {
            this.termsLink = `${environment.Image_API_Endpoint + environment.TERMS_SERVICE_ENDPOINT}/${
              response.body.result && response.body.result.length ? response.body.result[0].pdfUrl : ''
            }`;
            if (iOS && isSafari) {
              newTab.location.href = this.termsLink;
            } else {
              window.open(this.termsLink, '_blank');
            }
          },
          error => {
            if (error.status === 404) {
              this.showErrorModal('Error', 'File is not available, Please try later.');
            }
          },
        );
    } else {
      this.showErrorModal('Error', 'File is not available, Please try later.');
    }
  }

  /**
   * Load ETS payment box.
   * @param customerGuid
   * @param serviceAddress
   * @param zipcode
   * @param cusno
   */
  loadPaymentbox(customerGuid: String, serviceAddress: any, zipcode: any, cusno: any) {
    $('#hp').empty();
    // this.sessionTokenGenerated = false;
    this.showLoader = true;
    this.userService.etsSignInToken(this.userDataToken.authHeader).subscribe(
      data => {
        //   this.sessionTokenGenerated = true;
        const response = data.body;
        if (response.data && response.data.signInResponse && response.data.signInResponse.token) {
          const pluginOptions = {
            baseUrl: environment.hosted_payments_base_url,
            apiKey: response.data.signInResponse.token,
            paymentService: hp.PaymentService.TOKEN,
            instrumentId: customerGuid,
            paymentTypeOrder: [0, 1],
            saveCustomer: true,
            correlationId: cusno,
            billingAddress: {
              addressLine1: serviceAddress,
              postalCode: zipcode,
            },
            transactionId: this.guid(),
            successCallback: resp => {
              this.submitEtsPaymentInfo(resp, 'new');
              // add payment box
              /* this.loadPaymentbox(customerGuid, serviceAddress, zipcode, cusno); */
            },
            errorCallback: () => {
              this.errorPaymentCallback();
            },
          };
          /* IE loader issue AP March 16 2020 */
          const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
          if (isIEOrEdge) {
            this.showLoader = false;
            console.log(`isIE${isIEOrEdge}`);
          }
          (<any>$('#hp')).hp(pluginOptions);
          hp.Utils.reset(pluginOptions);
          this.showLoader = false;
        }
      },
      () => {
        //   this.sessionTokenGenerated = true;
        this.showErrorModal(
          'Error',
          `We are currently facing technical difficulties, please try to add your payment method again. Sorry for any inconvenience.`,
        );
      },
    );
  }

  /**
   * Submit ETS payment info.
   * @param res
   * @param cardType
   * @param paymentType
   */
  submitEtsPaymentInfo(res: any, cardType?: string, stripeArrayRes?: any, paymentType?: string) {
    const payload: any = {};
    this.showLoader = true;
    payload.orderId = this.cuid;
    payload.customerId = this.userDataToken.user._id;
    if (cardType !== 'new') {
      payload.PaymentType = paymentType;
      if (paymentType === 'CC') {
        payload.FinancialInstrumentToken = res.CreditCardToken;
        payload.Last4Digits_CC = res.AccountNumberMasked.substr(res.AccountNumberMasked.length - 4);
        payload.ExpirationDate = res.ExpirationDate;
        payload.CardType = res.CardType;
      } else if (paymentType === 'ACH') {
        payload.FinancialInstrumentToken = res.AchAccountToken;
        payload.achAccountNo = res.AccountNumberMasked.substr(res.AccountNumberMasked.length - 4);
        payload.achRoutingNo = res.RoutingNumberMasked.substr(res.RoutingNumberMasked.length - 4);
      }
      payload.etsAccountName = res.AccountHolderName;
      payload.ETSCustomerId = res.CustomerToken;
    } else {
      if (res.instrument_type === 'ACH') {
        payload.PaymentType = res.instrument_type;
        payload.achAccountNo = res.instrument_last_four;
        payload.achRoutingNo = res.instrument_routing_last_four;
      } else {
        payload.PaymentType = 'CC';
        payload.Last4Digits_CC = res.instrument_last_four;
        payload.ExpirationDate = res.instrument_expiration_date;
        payload.CardType = res.instrument_type;
      }
      payload.FinancialInstrumentToken = res.customer_token;
      payload.ETSCustomerId = res.instrument_id;
      payload.etsAccountName = res.customer_name;
    }

    payload.fingerprint = res.fingerprint;
    payload.bankAccountType = res.bankAccountType;
    payload.CardType = res.CardType;
    payload.billingType = 'offbill';
    payload.orders = [];
    const orderItem: any = {
      cartId: this.cartData._id,
      cartItemId: this.cartData.items[0]._id,
      address: this.cartData.serviceAddress,
      productId: this.cartData.items[0].productId,
      billingFrequency: this.billingFrequencyCheckout,
      crmCustomerGuid: this.serviceData.crmCustomerGuid,
      crmCustomerNumber: this.serviceData.crmCustomerNumber,
      enrollmentPrice: this.taxData[0],
      locationId: this.cartData.items[0].locationId,
      partner: this.partnerObj,
      isAutoRenew: [undefined, 'undefined', null, 'null', ''].includes(this.cartData.items[0].isAutoRenew)
        ? true
        : this.cartData.items[0].isAutoRenew,
      enrollmentGuid: this.cartData.items[0].enrollmentGuid ? this.cartData.items[0].enrollmentGuid : '',
      isUpgrade: !!this.cartData.items[0].isUpgrade,
    };
    const checkRefNumber = stripeArrayRes.find(ele => ele.referenceNumber.includes(this.cartData.items[0].details.marketingCode));
    if (checkRefNumber) {
      orderItem.stripeReferenceNumber = checkRefNumber.referenceNumber;
    }
    const matchingStripeObject = stripeArrayRes.find(ele => ele.referenceNumber.includes(this.cartData.items[0].details.marketingCode));
    if (matchingStripeObject) {
      orderItem.upfrontPaymentToken = matchingStripeObject.upfrontPaymentToken;
      orderItem.FinancialInstrumentToken = matchingStripeObject.customer_token;
    }
    if (typeof this.cartData.items[0].isRenew !== 'undefined' && this.cartData.items[0].isRenew === true) {
      orderItem.parentEnrollmentDate = this.cartData.items[0].parentEnrollmentDate;
      orderItem.isRenew = true;
      orderItem.parentEnrollmentId = this.cartData.items[0].parentEnrollmentId;
      orderItem.isUpgrade = false;
    }
    payload.orders.push(orderItem);
    delete payload.FinancialInstrumentToken;
    // offbill payment async call
    this.orderService.dataFromEts(payload, this.userDataToken.user._id, this.userDataToken.authHeader).subscribe(
      () => {
        this.updateSuccessCartStatus(errCart => {
          this.showLoader = false;
          if (!errCart) {
            const queryParam = {
              queryParams: {
                orderId: this.cuid,
              },
            };
            this.close();
            this.globalVariables.customerAccountOrderCuid = this.cuid;
            let redirectPath: any;
            if (this.isbrowser) {
              redirectPath = window.location.pathname;
            }

            const renewals = this.services.filter(item => item.crmStatus === '2');

            if (renewals && renewals?.length > 1) {
              this.simpleModalService
                .addModal(
                  AlertConfirmModalComponent,
                  {
                    title: `We are processing your Enrollment and Payment`,
                    message: `Do you want to renew / re-activate other enrollments?`,
                    alertIcon: 'fa fa-exclamation-circle',
                    cancelText: 'Cancel',
                    confirmText: 'Continue',
                  },
                  {
                    wrapperClass: 'modal-main-class in',
                    closeOnClickOutside: false,
                  },
                )
                .subscribe(isConfirmed => {
                  if (isConfirmed) {
                    this.globalVariables.oneTimeSuccessEvent.emit(true);
                    this.globalVariables.nonLoggedInRenewedOrderIds.push(this.cuid);
                  } else {
                    // To add the last order ID in case of multiple renewals
                    if (this.globalVariables.nonLoggedInRenewedOrderIds && this.globalVariables.nonLoggedInRenewedOrderIds.length > 0) {
                      this.globalVariables.nonLoggedInRenewedOrderIds.push(this.cuid);
                    }
                    this.globalVariables.showCheckoutPaymentReponsePage = true;
                    this.router.navigate([redirectPath], queryParam);
                  }
                });
            } else {
              // To add the last order ID in case of multiple renewals
              if (this.globalVariables.nonLoggedInRenewedOrderIds && this.globalVariables.nonLoggedInRenewedOrderIds.length > 0) {
                this.globalVariables.nonLoggedInRenewedOrderIds.push(this.cuid);
              }
              this.globalVariables.showCheckoutPaymentReponsePage = true;
              this.router.navigate([redirectPath], queryParam);
            }
            if (this.isFromCheckout) {
              this.isAPIProcessed = true;
              if (this.cartSubscription) {
                this.cartSubscription.unsubscribe();
              }
            }
          } else {
            this.showErrorModal('Error', 'Error while updating cart status.');
          }
        });
      },
      error => {
        this.showLoader = false;
        this.isAPIProcessed = true;
        if (error.status === 500) {
          this.showErrorAlert();
        } else if (error.status === 400) {
          this.retryPaymentAlert(alertRes => {
            if (alertRes) {
              this.showLoader = false;
              this.stripeInit();
            }
          });
        }
      },
    );
  }

  /**
   * Error payment callback.
   * @param res
   */
  errorPaymentCallback() {
    this.showLoader = true;
    this.cartService.updateProductCartStatus('PAYMENT_DECLINED', () => {
      this.showLoader = false;
      this.showErrorAlert();
    });
    this.isAPIProcessed = true;
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  /**
   * Generate GUID.
   */
  guid() {
    return `${this.s4() + this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}-${this.s4()}${this.s4()}${this.s4()}`;
  }

  /**
   * Random 4 character string.
   * @param product
   */
  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  showErrorAlert() {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'We have encountered a problem with your enrollment',
          message: `<p style="margin-top:-20px;margin-bottom:0;">Please call customer service to ensure your enrollment has completed and you are protected. <b><a href="tel:${this.customerServiceNumber}">${this.customerServiceNumber}</a></b></p>`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Close Message',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {
        if (this.isFromCheckout) {
          this.close();
        }
      });
  }

  retryPaymentAlert(done?: any) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: 'We are experiencing difficulties completing your payment.',
          message: 'Please try again or select a different payment method.',
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Continue',
          confirmBtnId: 'ga-ets-payment-alert',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          if (done) {
            done(false);
          }
        }
      });
  }

  addItemToCart(productData) {
    this.cartService.addItem(
      productData,
      this.userDataToken && this.userDataToken.authHeader ? this.userDataToken.authHeader : '',
      (err, data, status) => {
        if (err) {
          if (data.status === 409) {
            if (data.error.errPopupMessage) {
              this.showErrorModal(data.error.errPopupTitle, data.error.errPopupMessage, 'Ok', '', data.error.gaEventLabel);
            } else {
              this.showErrorModal(
                'Upgrade to Bundle',
                `If you are looking to bundle your existing services,
                  please visit your My Services page and view the list of opportunities from your current services.`,
                'Ok',
              );
            }
          } else if (data.status === 400) {
            this.showErrorModal(
              'Upgrade to Bundle',
              `If you are looking to bundle your existing services,
          please visit your My Services page and view the list of opportunities from your current services.`,
              'Ok',
            );
          } else {
            this.showErrorModal('Error', 'Error while adding item to cart');
          }
        } else {
          if (status === 201) {
            //   this.openBundle(data.json());
          } else if (!!data && data.length > 0) {
            this.sessionStorageService.setItemToloclaStorage('deletedCartData', JSON.stringify(data));
          }
          if (!this.isFromCheckout) {
            this.showLoader = false;
            this.router.navigate(['/payment']);
          } else {
            this.cart = this.cartService.get();
            this.cartSubscription = this.cart.subscribe(cart => {
              this.cartData = cart;
              this.showLoader = false;
            });
            this.cartService.getCustomerCartToLocal(this.userDataToken.user._id, this.userDataToken.authHeader, () => {
              this.cart = this.cartService.get();
              this.cartSubscription = this.cart.subscribe(cart => {
                this.cartData = cart;
                this.cartProducts = cart.items;
                this.globalVariables.cartAmount.emit(cart);
                if (this.cartProducts.length) {
                  this.cartLocationID = this.cartProducts[0].locationId;
                  this.cartCusNo = this.cartProducts[0].customerNo;
                }
                const guestToken = this.sessionStorageService.getItem('guestTokenUpdateEnrollment');
                this.userService.getCustomerDetails(this.serviceData.customerId, guestToken).subscribe(
                  custData => {
                    this.customerDetailsForStripe = custData.body;
                    if (!this.isAPIProcessed) {
                      this.stripeInit();
                    }
                  },
                  () => {
                    this.errorPaymentCallback();
                  },
                );
              });
              if (this.cartData.serviceAddress === undefined) {
                this.getCustomerAddress(error => {
                  if (error) {
                    this.showLoader = false;
                    this.showErrorAlert();
                  }
                });
              }
            });
          }
        }
        this.showLoader = false;
      },
    );
  }

  deleteCart(customerId: string, productData) {
    this.cartQuickCheckoutService.deleteCustomerCart(customerId).subscribe(() => {
      // this.cartService.createCart(cartObj, this.userDataToken.authHeader).subscribe(data => {
      //     if (data.status === 200) {
      this.sessionStorageService.deleteItem('cart');
      this.addItemToCart(productData);
      //     }
      // });
    });
  }

  ngOnDestroy() {
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  /**
   * Get customer addresses.
   * @param done
   */
  getCustomerAddress(done?: any) {
    this.userService.getAllCustomerAddresses(this.userDataToken.user._id, this.userDataToken.authHeader).subscribe(
      data => {
        this.customerAddresses = [];
        const results = data.body;
        results.forEach(result => {
          if (result.customerNo === this.cartCusNo) {
            this.customerAddresses.push(result);
            this.cartData.serviceAddress = this.customerAddresses[0];
            this.showLoader = false;
            if (done) {
              done(false, true);
            }
          }
        });
      },
      () => {
        if (done) {
          done(true, null);
        }
      },
    );
  }

  setPaymentFrequency(frequency: string) {
    this.billingFrequencyCheckout = frequency;
    this.recheckTaxPrice();
  }

  recheckTaxPrice() {
    this.showLoader = true;
    this.locationService.getLocationsContent(this.productData.defaultLocationCodeId).subscribe(data => {
      const locationCodeData = data.body.locationCode;
      const paymentMethod = 'Check';
      const frequencyVal = this.billingFrequencyCheckout === 'Monthly' ? 'Monthly' : 'Annually';
      this.crmProxyService
        .getProductsTaxCheckout(
          this.userDataToken.authHeader,
          this.serviceData.address.customerGuid,
          this.productData.marketingCodeId,
          this.productData.program.programId,
          locationCodeData,
          paymentMethod,
          frequencyVal,
        )
        .subscribe(
          productsData => {
            this.showLoader = false;
            this.taxData = productsData.body.data;
            this.serviceData.productDetails = this.productData;
          },
          () => {
            const errorTitle = "We're sorry, we are experiencing technical difficulties.";
            const strError = `Please try again. If you continue to experience issues, please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span> for assistance. Thank you.`;
            const trackAPIGATracking = 'trackProductTaxBillingAPIFail';
            this.showErrorModal(errorTitle, strError, '', '', trackAPIGATracking, true);
          },
        );
    });
  }

  private stripeInit() {
    if (document.getElementById('stripe-payment-renewal').innerHTML) {
      document.getElementById('stripe-payment-renewal').innerHTML = '';
    }
    const stripe = new Stripe();
    const paymentMethods = [PaymentMethod.CARD, PaymentMethod.ACH];

    const customerNo = this.serviceData.address.customerNo;
    const matchedAddress = this.customerDetailsForStripe.address.find(address => address.customerNo === customerNo);
    const oracleCustomerId = matchedAddress?.oracleAccountNumber;
    const oracleCustomerGuid = matchedAddress ? matchedAddress.customerGuid : null;

    const element = document.getElementById('stripe-payment-renewal');
    const payload: PaymentPayload = {
      customer: {
        oracleCustomerId,
        oracleCustomerGuid,
        postalCode: this.serviceData.address.serviceAddress.zipcode,
        fullName: `${this.userDataToken.user.firstName ?? ''} ${this.userDataToken.user.lastName ?? ''}`,
        email: this.userDataToken.user.email, // US Bank extra field
      },
      amount: this.taxData[0].TaxDetails.Total,
      mountNode: element,
      paymentMethods,
      enrollments: this.cartProducts.map((result, index) => ({
        referenceNumber: `UPFRONT-${result.details.marketingCode}-${matchedAddress.oracleCustId}-${(index + 1).toString().padStart(2, '0')}`,
        amount: this.taxData[index].TaxDetails.Total,
      })),
    };
    stripe
      .updatePaymentMethod(payload)
      .then(p => {
        this.submitEtsPaymentInfo(p[0], 'new', p);
      })
      .catch(() => {
        this.errorPaymentCallback();
      });
  }
}
