import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { find } from 'lodash-es';
import { forkJoin } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { LocationService } from '../../../home/services/location.service';
import { appConfig } from '../../../core/appConfig/config';
import { UserService } from '../../services/user.service';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { ZipcodeComponent } from '../../../core/component/zipcode/zipcode.component';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { environment } from '../../../../environments/environment';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { AlertService } from '../../../core/services/alert.service';
import { AlertConfirmModalComponent } from '../../../core/component/alert-confirm-modal/alert-confirm-modal.component';
import { CustomerServiceValidationModalComponent } from '../customer-service-validation-modal/customer-service-validation-modal.component';
import { ProviderModalComponent } from '../../../product/component/provider-modal/provider-modal.component';
import { User } from './user';
import { SiteContentService } from '../../../core/services/siteContents';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { SeoService } from '../../../core/services/seo.service';
import { ProductService } from '../../../product/services/product.service';
import { ZipcodeService } from '../../../core/services/zipcode.service';
import { GooglePlacesService } from '../../../core/services/google-places.service';
import { CrmProxyService } from '../../../core/services/crm-proxy.service';
import { AddressValidationModalComponent } from '../address-validation-modal/address-validation-modal.component';
import { CCPAUpdatesService } from '../../../core/services/ccpa-updates.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';

@Component({
  selector: 'hos-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  selectedAddress: any = {
    rawData: '',
    zipcode: '',
  };

  temporaryUserId: string;

  resultRegiData: any;

  locationCode: any;

  zipcode: any;

  selectedCity: any;

  validAddress = true;

  strAddress: String = '';

  zipcodeValid: Boolean = false;

  registerError: String = '';

  registerSuccess: String = '';

  userEmail: String = '';

  isClicked: Boolean;

  invalidForm: Boolean = false;

  //   showPhoneError: Boolean = false;
  user: User = new User();

  existingUser: User = new User();

  password_regex: String;

  email_regex: String;

  customer_number_regex: String;

  phone_number_regex: String;

  customer_service_number: String;

  referer: String;

  isExistingUser: Boolean = false;

  states: any;

  showLoader: Boolean = false;

  business: any = {};

  isPropertyExist: Boolean = true;

  zipcodeSet: Boolean = false;

  streetNumberValidation: string = appConfig.streetNumber;

  addressLookup: any = {
    zipcode: '',
  };

  stateCode: any;

  isClickedForm = false;

  showProvider: Boolean = false;

  showNewForm: any;

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'Register',
      link: '',
      active: true,
    },
  ];

  locationCodeData: any;

  partnerName: any;

  step0Access = false;

  step1Access = true;

  step2Access = false;

  step3Access = false;

  confirmEmailId: String;

  currentPageNumber = 0;

  existingCustomer: any;

  existingCustomerData: any;

  backPress: Boolean = false;

  businessTypeMapping: any = {
    1: 'Food Service',
    2: 'Retail',
    3: 'Automotive',
    4: 'Health Services',
    5: 'Professional Services',
    6: 'Worship Center',
    7: 'Rental Home/ Apartment',
    8: 'VFW/ Union Hall/ Private Club',
    9: 'Manuftrg/ Distr/ Indust',
    10: 'Other',
  };

  userTypeMapping: any = {
    1: 'Tenant',
    2: 'Property Owner',
    3: 'Management Company',
  };

  seoConfig: SeoConfig = {
    title: 'Register Service Line Warranties | American Water Resources',
    description: `American Water Resources offers affordable home protection programs
      that help protect against unexpected repair costs for service line emergencies.`,
    keywords: `service line warranties, water line protection, sewer line protection,
      in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
    image: '',
    slug: '',
    sitename: '',
    type: '',
    summary: '',
    url: '',
  };

  isViewSecurity: Array<boolean> = [false, false];

  customerNumber: any;

  searchApiCall: any;

  showDropDown = false;

  suggestedAddress: any;

  secQueAnsErrorMsg: Array<String> = [];

  homePhoneErrorMsg: String;

  mobilePhoneErrorMsg: String;

  businessPhoneErrorMsg: String;

  phoneNumberExistingErrorMsg: String;

  isDisposableEmail: Boolean = false;

  customerExistInHOS: Boolean = false;

  homeOwner = {
    isHomeOwner: true,
    allowRental: true,
    offbillOnly: false,
  };

  providerAvailable: Boolean = false;

  providerSelected: Boolean;

  existingUserLocationCodeId: any;

  oldUserData: any = {
    email: '',
    zipcode: '',
  };

  abandonedLocationCode: any;

  existingUserDetails: any = {};

  @ViewChild('customerForm')
  customerNumberForm: any;

  setSuccessfulRegister: boolean = false;

  isExistingCustReg: boolean = false;

  existingCustFormVal: any;

  addressPresentIn: string;

  blockUnicodeRegex: string = environment.blockUnicodeRegex;

  lastNameMismatchFound: string = '';

  registerUsingCustomerNumber: boolean = false;

  productsUnavailable: boolean = false;

  isConfirmation: boolean = false;

  chance: any;

  @ViewChild('registerForm') registerForm: NgForm;

  @ViewChild(ZipcodeComponent) zipcodeComponent: ZipcodeComponent;

  @ViewChild('confirmPassword') confirmPassword: NgForm;

  @ViewChild('password') password: NgForm;
  /**
   *
   * Creates an instance of RegisterComponent.
   *
   * @param {Router} router
   * @param {UserService} userService
   *
   * @memberof RegisterComponent
   */

  @HostListener('document:click')
  clickout() {
    this.showDropDown = false;
  }

  constructor(
    private router: Router,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private simpleModalService: SimpleModalService,
    private seoService: SeoService,
    private siteContentService: SiteContentService,
    private productService: ProductService,
    private zipcodeService: ZipcodeService,
    private alertService: AlertService,
    private googlePlacesService: GooglePlacesService,
    private titlecasePipe: TitleCasePipe,
    private crmProxyService: CrmProxyService,
    private ccpaUpdatesService: CCPAUpdatesService,
    private locationService: LocationService,
    private globalVariables: GlobalVariables,
  ) {
    seoService.generateTitleTags(this.seoConfig);
    seoService.setCanonicalTags();
    this.referer = this.activatedRoute.snapshot.queryParams.referer;

    this.currentPageNumber = 0;
    this.isClicked = false;
    this.email_regex = environment.email_regex;
    this.password_regex = environment.password_regex;
    this.customer_number_regex = environment.customer_number_regex;
    this.phone_number_regex = environment.phone_number_regex;
    this.customer_service_number = environment.customer_service_number;
    this.chance = new Chance();
  }

  ngOnInit() {
    this.sessionStorageService.deleteItem('providerSelected');
    this.sessionStorageService.deleteItem('isProvider');
    this.sessionStorageService.deleteItem('websitePartnerName');
    this.siteContentService.statesdata$().subscribe(states => {
      this.states = states;
      if (!this.states) {
        this.states = this.sessionStorageService.getItem('statesList') ? JSON.parse(this.sessionStorageService.getItem('statesList')) : states;
      }
    });
    this.showNewForm = true;
    this.showCCPAUpdates();
    const registerFormData = this.sessionStorageService.getItem('registerFormData');
    if (registerFormData) {
      this.user = JSON.parse(registerFormData);
    }
  }

  onZipcodeUpdated(data: any) {
    if (data) {
      this.user.address[0].serviceAddress.zipcodeId = data.zipcodeId;
      this.user.address[0].serviceAddress.locationCodeId = data.locationCodeId;
      this.zipcodeValid = true;
      this.stateCode = data.stateCode;
      let oofLocationCode = '';
      if (this.user && this.user.address && this.user.address.length > 0 && this.user.address[0].serviceAddress) {
        this.user.address[0].serviceAddress.state = find(this.states, {
          key: data.stateCode,
        }).value;
        oofLocationCode = `${this.user.address[0].serviceAddress.state}150`;
      }
      if (this.sessionStorageService.getItem('providerSelected') !== '1') {
        this.showLoader = true;
        this.googlePlacesService.getCityAndStateData(this.user.address[0].serviceAddress.zipcode).subscribe(
          response => {
            const result = response.body;
            this.autoCompleteSearchCallback(result.results[0]);
            this.productService.getProviderContent(data.locationCodeId).subscribe(locationResponse => {
              let dataForLocation = locationResponse.body;
              this.checkLocationChangedForReferer(dataForLocation.locationCode);
              if (oofLocationCode !== dataForLocation.locationCode && this.homeOwner.offbillOnly) {
                this.productService.getLocationCode(oofLocationCode).subscribe(oofLocationResponse => {
                  const dataForOofLocationCode = oofLocationResponse.body;
                  [dataForLocation] = dataForOofLocationCode;
                  this.user.address[0].serviceAddress.locationCodeId = dataForOofLocationCode[0].locationCodeId;
                  this.checkLocationChangedForReferer(dataForLocation.locationCode);
                  this.updateDataForLocation(dataForLocation);
                });
              } else {
                this.updateDataForLocation(dataForLocation);
              }
            });
          },
          () => {
            // error in google service
            this.showLoader = false;
          },
        );
      }
    } else {
      this.zipcodeValid = false;
      this.sessionStorageService.deleteItem('providerSelected');
      this.sessionStorageService.deleteItem('isProvider');
      this.sessionStorageService.deleteItem('websitePartnerName');
    }
  }

  autoCompleteSearchCallback(data: any) {
    if (data) {
      if (data) {
        for (let i = 0; i < data.address_components.length; i++) {
          for (let j = 0; j < data.address_components[i].types.length; j++) {
            if (data.address_components[i].types[j] === 'postal_code') {
              this.selectedAddress.rawData = data;
              this.selectedAddress.zipcode = this.strAddress;
              this.validAddress = false;
              this.sessionStorageService.setItem('selectedAddress', JSON.stringify(this.selectedAddress));
            }
          }
        }
      }
    } else {
      this.sessionStorageService.deleteItem('location_code');
      this.sessionStorageService.deleteItem('selectedAddress');
    }
  }

  openModalPopup(selectedZipcode: String) {
    this.providerAvailable = true;
    this.simpleModalService
      .addModal(
        ProviderModalComponent,
        {
          zipcode: selectedZipcode,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        this.sessionStorageService.setItem('providerSelected', '1');
        this.sessionStorageService.setItem('isProvider', '1');
        if (isConfirmed) {
          this.showProvider = true;
          this.providerSelected = true;
          this.productService.setCategories();
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'false';
          this.checkIfProductsAreAvailable();
          //  this.goToPage(1);
        } else if (this.backPress) {
          this.backPress = false;
        } else {
          this.partnerName = '';
          this.sessionStorageService.setItem('websitePartnerName', '');
          this.providerSelected = false;
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'true';
          this.notMyProvider();
        }
        if (this.user.address[0].serviceAddress.state.toLowerCase() === 'ca') {
          this.ccpaUpdatesService.getCCPAUpdates();
        }
      });
  }

  notMyProvider() {
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    this.selectedCity = '';
    if (savedAddress) {
      const address = JSON.parse(savedAddress);
      if (address.rawData && address.rawData.address_components.length) {
        this.selectedCity = address.rawData.address_components[1].short_name;
      }
    }
    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      res => {
        const data = res.body;
        if (data.length > 0) {
          this.productService.getProviderContent(data[0].locationCodeId).subscribe(() => {
            this.user.address[0].serviceAddress.locationCodeId = data[0].locationCodeId;
            this.checkIfProductsAreAvailable();
            this.checkLocationChangedForReferer(data[0].locationCode);
            this.sessionStorageService.setItem('location_code', data[0].locationCodeId);
            this.productService.setCategories();
            this.locationCode = data[0].locationCodeId;
            this.abandonedLocationCode = data[0].locationCode;
            this.abandonedRegistrationInsert();
          });
        } else {
          this.getLocationDataState();
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getLocationDataState() {
    this.selectedCity = '';
    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      res => {
        const dataReceived = res.body;
        if (dataReceived.length > 0) {
          this.productService.getProviderContent(dataReceived[0].locationCodeId).subscribe(() => {
            this.user.address[0].serviceAddress.locationCodeId = dataReceived[0].locationCodeId;
            this.checkIfProductsAreAvailable();
            this.checkLocationChangedForReferer(dataReceived[0].locationCode);
            this.sessionStorageService.setItem('location_code', dataReceived[0].locationCodeId);
            this.productService.setCategories();
            this.locationCode = dataReceived[0].locationCodeId;
            this.abandonedLocationCode = dataReceived[0].locationCode;
            this.abandonedRegistrationInsert();
          });
        } else {
          this.getLocationCodeCrm(this.locationCodeData.locationCode);
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  getLocationCodeCrm(locationCode: any) {
    this.productService.getLocationDataCRM(locationCode).subscribe(
      res => {
        const location = res.body;
        this.productService.getProviderContent(location.LocationDetails[0].Id).subscribe(() => {
          this.user.address[0].serviceAddress.locationCodeId = location.LocationDetails[0].Id;
          this.checkIfProductsAreAvailable();
          this.checkLocationChangedForReferer(location.LocationDetails[0].Code);
          this.sessionStorageService.setItem('location_code', location.LocationDetails[0].Id);
          this.globalVariables.OOFSelected.isOOFLocationRegister = 'true';
          this.locationCode = location.LocationDetails[0].Id;
          this.productService.setCategories();
          this.abandonedLocationCode = location.LocationDetails[0].Code;
          this.abandonedRegistrationInsert();
        });
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  stateChangeHandler(event: any) {
    if (this.stateCode && this.stateCode !== event.key) {
      this.showAlertModal({
        title: 'Error',
        // tslint:disable-next-line:max-line-length
        message: `Zipcode does not exist in selected state, Please select ${
          find(this.states, {
            key: this.stateCode,
          }).value
        } to proceed.`,
        alertIcon: 'fa fa-exclamation-circle red',
        cancelText: 'Cancel',
        confirmText: 'Ok',
      });
    }
    this.saveToLocalStorage();
  }

  onZipcodeUpdatedLookup(data: any) {
    if (data) {
      this.addressLookup.zipcodeId = data.zipcodeId;
      this.addressLookup.locationCodeId = data.locationCodeId;
      this.addressLookup.state = data.stateCode;
      const stateName = find(this.states, {
        key: this.addressLookup.state,
      });
      this.addressLookup.stateName = stateName.value;
      this.zipcodeSet = true;
    } else {
      this.zipcodeSet = false;
      this.addressLookup.stateName = '';
    }
  }

  goToStep(action: string, form: any, step, $event, existingCustRegFlag) {
    this.currentPageNumber = step;
    this.isExistingCustReg = existingCustRegFlag;
    if (action === 'previous' && step === 2) {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        1000,
      );
      this.isClicked = false;
      this.step1Access = true;
      this.step2Access = false;
      this.step3Access = false;
      this.currentPageNumber = 0;
      this.existingUser = new User();
      this.isExistingCustReg = false;
    } else if (action === 'previous' && step === 1) {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        1000,
      );
      this.isClicked = false;
      this.step0Access = false;
      this.step1Access = true;
      this.step2Access = false;
      this.step3Access = false;
      this.currentPageNumber -= 1;
      this.existingUser = new User();
    }
    if (action === 'next' && step === 0) {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        1000,
      );
      this.isClicked = false;
      this.step0Access = false;
      this.step1Access = true;
      this.step2Access = false;
      this.step3Access = false;
      this.existingUser = new User();
    }
    if (action === 'next' && step === 1) {
      if (form.invalid) {
        if (form.controls.firstName && (form.controls.firstName.valid === false || form.controls.lastName.valid === false)) {
          $('html, body').animate(
            {
              scrollTop: $('#name').offset().top - 60,
            },
            1000,
          );
        } else if (
          form.controls.email &&
          (form.controls.email.valid === false ||
            form.controls.phoneNumber.valid === false ||
            form.controls.mobilePhoneNumber.valid === false ||
            (form.controls.businessPhone && form.controls.businessPhone.valid === false) ||
            (form.controls.phoneNumberExisting && form.controls.phoneNumberExisting.valid === false) ||
            this.isExistingUser ||
            this.isDisposableEmail ||
            (form.controls.password && (form.controls.password.valid === false || form.controls.confirmPassword.valid === false)))
        ) {
          $('html, body').animate(
            {
              scrollTop: $('#email').offset().top - 50,
            },
            1000,
          );
        } else if (
          form.controls.city &&
          (form.controls.city.valid === false ||
            form.controls.state.valid === false ||
            form.controls.streetNumber.valid === false ||
            form.controls.streetName.valid === false)
        ) {
          $('html, body').animate(
            {
              scrollTop: $('#service-address').offset().top,
            },
            1000,
          );
        }
        return;
      }
      if (
        form.controls.state.value ===
        find(this.states, {
          key: this.stateCode,
        }).value
      ) {
        if (form.valid && !this.isExistingUser && this.zipcodeValid && !this.isDisposableEmail) {
          const searchAddress = this.user.address[0].serviceAddress;
          this.getSmartAddress(
            searchAddress.streetNumber,
            searchAddress.streetName || '',
            searchAddress.state,
            searchAddress.city,
            searchAddress.zipcode,
            upDateAddress => {
              if (upDateAddress) {
                if (this.globalVariables.OOFSelected && this.globalVariables.OOFSelected.isOOFLocationRegister === 'true') {
                  /* Add code if need to set other field for ooflocation */
                } else {
                  this.zipcodeService.getZipcodeId(upDateAddress.zipcode).subscribe(response => {
                    // this.onZipcodeUpdated(res);
                    const res = response.body;
                    this.user.address[0].serviceAddress.zipcodeId = res.zipCodeId;
                    this.user.address[0].serviceAddress.locationCodeId = res.locationCodeId;
                  });
                }
                this.user.address[0].serviceAddress.streetNumber = upDateAddress.streetNumber;
                this.user.address[0].serviceAddress.streetName = upDateAddress.streetName || '';
                this.user.address[0].serviceAddress.city = upDateAddress.city;
                this.user.address[0].serviceAddress.state = upDateAddress.state;
                this.user.address[0].serviceAddress.zipcode = upDateAddress.zipcode;
              }
              this.checkExistingUser(form, response => {
                if (response.status === 200) {
                  response = response.body;
                }
                if (!!response && response.length === 0) {
                  this.isClicked = false;
                  this.onSubmitRegister(form, this.currentPageNumber, $event);
                } else if (response.length > 0 && this.addressPresentIn === 'customersDB') {
                  const currentCustomer = this.checkCustomerExists(response, form.controls.lastName.value, form.controls.email.value);
                  this.isClicked = false;
                  if (currentCustomer) {
                    this.existingCustomerData = currentCustomer;
                    this.setExistingCustomerData(this.existingCustomerData, false).then(() => {
                      if (form.controls.email.value.toLowerCase() === currentCustomer.email.toLowerCase()) {
                        this.onSubmitRegister(form, this.currentPageNumber, $event);
                      } else {
                        this.showLoader = false;
                        const email = currentCustomer.email.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
                        this.showErrorRegistering(
                          `Our records indicate that the email entered doesn't match the email present in our system ${email}. Please try entering valid details or click on forget password to reset.`,
                        );
                      }
                    });
                  } else {
                    this.lastNameMismatchFound = response[0].address.customerNo;
                    this.onSubmitRegister(form, this.currentPageNumber, $event);
                  }
                } else {
                  const currentCustomer = this.checkCustomerExists(response, form.controls.lastName.value, form.controls.email.value);
                  if (currentCustomer) {
                    const alertData = {
                      title: 'Please confirm',
                      message: `Our records indicate that you are a current customer.
                    <br><br>
                    Would you like to create an account using the information we have in our records?`,
                      alertIcon: 'fa fa-info-circle red',
                      cancelText: 'No',
                      confirmText: 'Yes',
                    };
                    this.showAlertModal(alertData, success => {
                      if (success && response.length === 1) {
                        this.isClicked = false;
                        this.existingCustomerData = currentCustomer;
                        if (currentCustomer.enrollments.length > 0) {
                          const apiCall = 0;
                          const query = 'skip_schema_validation=yes';
                          this.searchApiCall = this.userService.searchCustomerById(this.existingCustomerData.Customer_Number, query);
                          this.selectNameModal(apiCall, this.searchApiCall, false);
                          this.existingCustFormVal = form;
                        } else {
                          this.addressPresentIn = 'customercrmsDB';
                          this.setExistingCustomerData(this.existingCustomerData, false).then(() => {
                            this.onSubmitRegister(form, this.currentPageNumber, $event);
                          });
                        }
                      } else {
                        if (this.addressPresentIn === 'customercrmsDB') {
                          this.lastNameMismatchFound = response[0].Customer_Number;
                        } else if (this.addressPresentIn === 'customersDB') {
                          this.lastNameMismatchFound = response[0].address.customerNo;
                        }
                        this.onSubmitRegister(form, this.currentPageNumber, $event);
                      }
                    });
                  } else {
                    if (this.addressPresentIn === 'customercrmsDB') {
                      this.lastNameMismatchFound = response[0].Customer_Number;
                    } else if (this.addressPresentIn === 'customersDB') {
                      this.lastNameMismatchFound = response[0].address.customerNo;
                    }
                    this.onSubmitRegister(form, this.currentPageNumber, $event);
                  }
                }
              });
            },
          );
        } else if (form.controls.firstName && (form.controls.firstName.valid === false || form.controls.lastName.valid === false)) {
          $('html, body').animate(
            {
              scrollTop: $('#name').offset().top - 60,
            },
            1000,
          );
        } else if (
          form.controls.email &&
          (form.controls.email.valid === false ||
            form.controls.phoneNumber.valid === false ||
            form.controls.mobilePhoneNumber.valid === false ||
            (form.controls.businessPhone && form.controls.businessPhone.valid === false) ||
            (form.controls.phoneNumberExisting && form.controls.phoneNumberExisting.valid === false) ||
            this.isExistingUser ||
            this.isDisposableEmail ||
            (form.controls.password && (form.controls.password.valid === false || form.controls.confirmPassword.valid === false)))
        ) {
          $('html, body').animate(
            {
              scrollTop: $('#email').offset().top - 50,
            },
            1000,
          );
        } else if (
          form.controls.city &&
          (form.controls.city.valid === false ||
            form.controls.state.valid === false ||
            form.controls.streetNumber.valid === false ||
            form.controls.streetName.valid === false)
        ) {
          $('html, body').animate(
            {
              scrollTop: $('#service-address').offset().top,
            },
            1000,
          );
        }
      } else {
        this.showAlertModal({
          title: 'Error',
          // tslint:disable-next-line:max-line-length
          message: `Zipcode does not exist in selected state, Please select ${
            find(this.states, {
              key: this.stateCode,
            }).value
          } to proceed.`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: 'Cancel',
          confirmText: 'Ok',
        });
      }
    } else if (action === 'next' && step === 2) {
      if (form.valid && !this.isDisposableEmail) {
        this.onSubmitRegister(form, this.currentPageNumber, $event);
      } else if (form.controls.password && (form.controls.password.valid === false || form.controls.confirmPassword.valid === false)) {
        $('html, body').animate(
          {
            scrollTop: $('#credential-section').offset().top,
          },
          1000,
        );
      }
    } else if (action === 'next' && step === 0) {
      $event.preventDefault();
      this.onSubmitRegister(form, this.currentPageNumber, $event);
    }
    this.abandonedRegistrationInsert();
  }

  onSubmitRegister(form: NgForm, actIndex, $event?) {
    switch (actIndex) {
      case 0: {
        // this.user.address[0].serviceAddress.offbillOnly = this.homeOwner.offbillOnly;
        if ($event) {
          $event.preventDefault();
        }
        this.user.address[0].ownOrRentedValidated = 'true';
        if (this.homeOwner.offbillOnly) {
          this.user.address[0].noWaterBillConversion = 'true';
        } else {
          this.user.address[0].noWaterBillConversion = 'false';
        }
        break;
      }
      case 1: {
        this.userEmail = form.value.email;
        this.user.email = form.value.email;
        this.user.firstName = form.value.firstName || '';
        this.user.lastName = form.value.lastName;
        this.user.middleName = form.value.middleName ? form.value.middleName : '';
        this.user.phoneNumber = form.value.phoneNumber;
        this.user.phoneNumber2 = '';
        // [VJ HWR-2661-2654 MOBILE FIELD RELATED CODE]
        this.user.mobilePhoneNumber = form.value.mobilePhoneNumber ? form.value.mobilePhoneNumber : '';
        if (form.value.accountNumber) {
          this.user.accountnumber = form.value.accountNumber;
        }
        // HWR-5025  Removal of Checkbox from Web Account Registration sending defalt true.
        this.user.isAuthorizedToSendMessage = 1;
        this.user.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
        this.confirmEmailId = form.value.confirmEmail;
        this.user.address[0].serviceAddress.city = form.value.city.trim();
        this.user.address[0].serviceAddress.streetNumber = form.value.streetNumber;
        this.user.address[0].serviceAddress.streetName = form.value.streetName || '';
        this.user.address[0].firstName = form.value.firstName || '';
        this.user.address[0].lastName = form.value.lastName;
        this.step1Access = true;
        this.step2Access = false;
        this.step3Access = false;
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          1000,
        );
        this.abandonedRegistrationInsert();
        this.onSubmitRegister(form, 2, $event);
        break;
      }
      case 2: {
        // statements;

        this.showLoader = true;
        if (this.existingUser.address[0].customerNo) {
          // this.existingUser.email = this.existingUser.email ? this.existingUser.email : form.value.loginId;
          this.existingUser.email = this.isExistingCustReg ? form.value.loginId : form.value.email;
          this.userEmail = this.existingUser.email;
          this.existingUser.password = form.value.password;
          this.sanitize(this.existingUser);
          this.sanitize(this.user);
          if (this.referer) {
            this.existingUser.referer = this.referer;
          }
          this.existingUser.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
        } else {
          this.user.password = form.value.password;

          if (this.user.address[0].serviceAddress.contactType === 'COMMERCIAL') {
            Object.keys(this.business).forEach(key => {
              this.user.address[0].serviceAddress[key] = this.business[key];
            });
          }

          // To do : Change address model later
          this.user.address[0].billingAddress = this.user.address[0].serviceAddress;

          this.user.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
          this.sanitize(this.user);
          // call customer register service here
          if (this.referer) {
            this.user.referer = this.referer;
          }
        }

        const payload: any = this.existingUser.address[0] && this.existingUser.address[0].customerNo ? this.existingUser : this.user;
        if (this.existingUser.address[0] && this.existingUser.address[0].customerNo) {
          payload.isAuthorizedToSendMessage = this.user.isAuthorizedToSendMessage;
        }
        delete payload.accountnumber;
        if (payload.password === form.value.confirmPassword) {
          if (!payload.address[0].serviceAddress.streetName) {
            payload.address[0].serviceAddress.streetName = '';
          }
          if (this.referer) {
            payload.retainUrl = this.referer;
          }
          payload.utmParams = this.sessionStorageService.getItem('utmParams') || '';
          payload.address[0].providerAvailable = this.providerAvailable;
          if (this.providerAvailable) {
            payload.address[0].providerSelected = this.providerSelected;
          }
          /* HWR -1445 */
          if (this.existingUserLocationCodeId) {
            this.locationService.getLocationsContent(this.existingUserLocationCodeId).subscribe(
              dataReceivedRes => {
                const dataReceived = dataReceivedRes.body;
                if (dataReceived && dataReceived.locationCode.includes('150')) {
                  payload.address[0].providerAvailable = true;
                  payload.address[0].providerSelected = true;
                }
                if (this.lastNameMismatchFound.length) {
                  payload.address[0].lastNameMismatchFound = this.lastNameMismatchFound;
                }
                this.registerCustomer(payload);
              },
              () => {
                this.showLoader = false;
                this.alertService.error('Something went wrong. Please try again letter');
              },
            );
          } else {
            if (this.lastNameMismatchFound.length) {
              payload.address[0].lastNameMismatchFound = this.lastNameMismatchFound;
            }
            this.registerCustomer(payload);
          }
          /* HWR -1445 */
        } else {
          this.isClicked = true;
          this.showLoader = false;
          $('html, body').animate(
            {
              scrollTop: 0,
            },
            1000,
          );
        }
        break;
      }
      default:
        break;
    }
  }

  registerCustomer(payloadData) {
    let payload = payloadData;
    if (this.registerUsingCustomerNumber && this.existingCustomerData && this.existingCustomerData.email !== payload.email) {
      this.showErrorRegistering(
        "Our records indicate that the data entered doesn't matches with the data present in our system. Please try entering valid details.",
      );
      this.isClicked = false;
      this.showLoader = false;
    } else if (this.existingUserDetails && this.existingUserDetails._id && !this.registerUsingCustomerNumber) {
      payload = {
        ...this.formatAddPropertyData(payload),
      };
      this.userService.addCustomerAddress(this.existingUserDetails._id, payload).subscribe(
        data => {
          this.globalVariables.OOFSelected = {};
          this.showLoader = false;
          if (data.status === 200) {
            this.setSuccessfulRegister = true;
            this.step1Access = false;
            this.step2Access = false;
            this.step3Access = true;
            const element: HTMLElement = document.getElementById('successful_register') as HTMLElement;
            element.click();
            $('html, body').animate(
              {
                scrollTop: 0,
              },
              1000,
            );
            this.deleteAbandonedRegCustomer();
            this.sessionStorageService.deleteItem('registerFormData');
          }
        },
        error => {
          this.currentPageNumber -= 1;
          this.isClicked = false;
          this.registerError = error.error.message;
          let errorTitle = "We're sorry, there was a problem attempting to create your account.";
          let strError: String = 'Please call the number at the top of page to speak to a Customer Service Representative.';
          if (error.status === 409) {
            strError = 'User already exists';
            errorTitle = 'Error';
          }
          this.showLoader = false;
          let trackAbandonedRegistration: boolean = false;
          if (error.status !== 409) {
            trackAbandonedRegistration = true;
          }
          this.resetRegisterFormData();
          this.showErrorModal(errorTitle, strError, ErrorModalComponent, trackAbandonedRegistration);
        },
      );
    } else {
      this.userService.registerCustomer(payload).subscribe(
        data => {
          this.globalVariables.OOFSelected = {};
          this.showLoader = false;
          if (data.status === 200) {
            this.setSuccessfulRegister = true;
            this.step1Access = false;
            this.step2Access = false;
            this.step3Access = true;
            const element: HTMLElement = document.getElementById('successful_register') as HTMLElement;
            element.click();
            $('html, body').animate(
              {
                scrollTop: 0,
              },
              1000,
            );
            this.deleteAbandonedRegCustomer();
            this.sessionStorageService.deleteItem('registerFormData');
          }
        },
        error => {
          this.currentPageNumber -= 1;
          this.isClicked = false;
          this.registerError = error.error.message;
          let errorTitle = "We're sorry, there was a problem attempting to create your account.";
          let strError: String = 'Please call the number at the top of page to speak to a Customer Service Representative.';
          if (error.status === 409) {
            strError = 'User already exists';
            errorTitle = 'Error';
          }
          this.showLoader = false;
          let trackAbandonedRegistration: boolean = false;
          if (error.status !== 409) {
            trackAbandonedRegistration = true;
          }
          this.resetRegisterFormData();
          this.showErrorModal(errorTitle, strError, ErrorModalComponent, trackAbandonedRegistration);
        },
      );
    }
  }

  formKeyPressHandler(event: any, newRegistration = false) {
    if (newRegistration && this.customerNumberForm && this.customerNumberForm.form.controls.accountNumber.value === '') {
      this.customerNumberForm.form.markAsPristine();
    }

    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      event.preventDefault();
    }
  }

  cancelRegister() {
    this.showAlertModal({
      title: 'Are you sure you want to cancel your registration?',
      message: 'If you cancel you will need to start the process again.',
      alertIcon: 'fa fa-exclamation-circle red',
      cancelText: 'NO, CONTINUE',
      confirmText: 'YES, CANCEL REGISTRATION',
    });
  }

  sanitize(obj: Object) {
    if (obj) {
      Object.keys(obj).forEach(prop => {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          if (typeof obj[prop] === 'object') {
            this.sanitize(obj[prop]);
          } else if (!obj[prop] && obj[prop] !== 0) {
            delete obj[prop];
          }
        }
      });
    }
  }

  showErrorModal(title: String, message: String, modalComponent: any, trackGA?: boolean) {
    this.simpleModalService
      .addModal(
        modalComponent,
        {
          title,
          message,
          confirmBtnId: trackGA ? 'registrationerror' : 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {
        this.sessionStorageService.deleteItem('registerFormData');
      });
  }

  emailBlurHandler(field: any) {
    const input = field;
    this.isDisposableEmail = false;
    input.isBlur = true;
    this.isExistingUser = false;
    if (input.valid) {
      if (environment.disposable_email.includes(input.value.split('@')[1].toLowerCase())) {
        input.isBlur = false;
        this.isDisposableEmail = true;
      } else {
        this.userService.verifyUser(input.value).subscribe(
          data => {
            if (data && data.body && data.body.user && data.body.user.isFromCheckout) {
              this.existingUserDetails = data.body.user;
              this.isExistingUser = false;
            } else if (data.status === 200) {
              this.existingUserDetails = {};
              this.isExistingUser = true;
              input.isBlur = false;
              this.deleteAbandonedRegCustomer();
            }
          },
          () => {
            this.existingUserDetails = {};
            this.isExistingUser = false;
          },
        );
      }
    }
    this.saveToLocalStorage();
  }

  phoneBlurHandler(field: any) {
    const input = field;
    input.isBlur = true;
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      if (input.name === 'phoneNumber') {
        this.homePhoneErrorMsg = 'Please enter 10 digit number';
      } else if (input.name === 'mobilePhoneNumber') {
        this.mobilePhoneErrorMsg = 'Please enter 10 digit number';
      } else if (input.name === 'businessPhone') {
        this.businessPhoneErrorMsg = 'Please enter 10 digit number';
      }
      return false;
    }
    if (input.name === 'phoneNumber') {
      this.homePhoneErrorMsg = '';
    } else if (input.name === 'mobilePhoneNumber') {
      this.mobilePhoneErrorMsg = '';
    } else if (input.name === 'businessPhone') {
      this.businessPhoneErrorMsg = '';
    }

    // this.showPhoneError = false;
    if (input.valid) {
      /* HWR-2485 Start */
      /* this.userService.verifyPhoneNumber(input.value)
        .subscribe((data) => {
          if (data.status === 200) {
            input.isBlur = false;
          }
        }, (error) => {
        }); */
      /* HWR-2485 End */
    }
    this.saveToLocalStorage();
    return true;
  }

  blurHandler(field: any) {
    const input = field;
    input.isBlur = true;
    this.phoneNumberExistingErrorMsg = '';
    const pattern = /^\d{10}$/;
    if (input.value && !pattern.test(input.value)) {
      this.phoneNumberExistingErrorMsg = 'Please enter 10 digit number';
      return false;
    }
    return true;
  }

  keyPressHandler(field: any) {
    const input = field;
    input.isBlur = false;
  }

  emailKeyPressHandler(field: any) {
    const input = field;
    input.isBlur = false;
    this.isExistingUser = false;
  }

  scrollToSection(sectionId: string) {
    document.querySelector(`#${sectionId}`).scrollIntoView({
      behavior: 'smooth',
    });
  }

  accountNumberKeyPressHandler(formInput) {
    const form = formInput;
    if (form.valid) {
      let apiCall: number;
      this.showLoader = true;
      this.invalidForm = false;
      this.isClickedForm = false;
      // sending state name in query
      let query = form.value.accountNumber ? 'skip_schema_validation=yes' : '';
      if (!form.value.streetNameExisting) {
        form.value.streetNameExisting = '';
      }
      // let searchApiCall: any = '';
      if (form.value.accountNumber) {
        this.existingCustomerData = {};
        this.addressPresentIn = '';
        this.userService.searchExistingCustomerByCustomerNumber(form.value.accountNumber.trim()).subscribe(res => {
          const data = res.body;
          if (!data.customerExist) {
            apiCall = 1;
            this.searchApiCall = this.userService.searchCustomerById(form.value.accountNumber.trim(), query);
            this.selectNameModal(apiCall, this.searchApiCall, true);
            this.showLoader = false;
            // this.callSearchApi(apiCall, searchApiCall);
          } else if (data.data) {
            const address = data.data.address.find(p => p.customerNo === form.value.accountNumber.trim());
            if (address && !address.isFromCheckout) {
              this.customerExistInHOS = data.customerExist;
              this.showLoader = false;
              return;
            }
            if (address && address.isFromCheckout) {
              apiCall = 1;
              this.searchApiCall = this.userService.searchCustomerById(form.value.accountNumber.trim(), query);
              this.selectNameModal(apiCall, this.searchApiCall, true);
              this.showLoader = false;
            }

            this.customerExistInHOS = false;
            this.isExistingCustReg = true;
            this.addressPresentIn = 'customersDB';
            this.registerUsingCustomerNumber = true;
            this.existingCustomerData = data.data;
            [this.existingCustomerData.address] = this.existingCustomerData.address;
            this.setExistingCustomerData(this.existingCustomerData, true).then(() => {});
            this.showLoader = false;
          } else {
            this.customerExistInHOS = data.customerExist;
            this.showLoader = false;
          }
        });
      } else {
        apiCall = 2;
        this.getSmartAddress(
          form.value.streetNumberExisting,
          form.value.streetNameExisting || '',
          this.addressLookup.stateName,
          form.value.cityExisting,
          this.addressLookup.zipcode,
          upDateAddress => {
            if (!upDateAddress) {
              query = `street=${encodeURIComponent(form.value.streetNameExisting.trim())}
&street_number=${encodeURIComponent(form.value.streetNumberExisting.trim())}
&city=${encodeURIComponent(form.value.cityExisting.trim())}&zip_code=${encodeURIComponent(this.addressLookup.zipcode.trim())}
&state=${encodeURIComponent(this.addressLookup.stateName.trim())}`;
            } else {
              query = `street=${encodeURIComponent(upDateAddress.streetName.trim())}
&street_number=${encodeURIComponent(upDateAddress.streetNumber.trim())}
&city=${encodeURIComponent(upDateAddress.city.trim())}&zip_code=${encodeURIComponent(upDateAddress.zipcode.trim())}
&state=${encodeURIComponent(upDateAddress.state.trim())}`;
            }
            this.searchApiCall = this.userService.searchCustomerByAddress(query);
            this.selectNameModal(apiCall, this.searchApiCall, true);
            // this.callSearchApi(apiCall, searchApiCall);
          },
        );
      }
    } else {
      this.showLoader = false;
    }
  }

  callSearchApi(apiCall, searchApiCall, existingCustReg) {
    searchApiCall.subscribe(data => {
      if (!this.addressPresentIn.length || this.addressPresentIn === 'customercrmsDB') {
        const customerData = data.body;
        [this.existingCustomerData] = customerData;
        this.isExistingCustReg = existingCustReg;
        this.addressPresentIn = 'customercrmsDB';
        this.setExistingCustomerData(this.existingCustomerData, existingCustReg).then(() => {});
        this.showLoader = false;
        if (!existingCustReg) {
          this.onSubmitRegister(this.existingCustFormVal, this.currentPageNumber, null);
        }
      }
    });
  }

  setExistingCustomerData(existingCustomer: any, existingCustReg) {
    return new Promise(resolve => {
      if (this.addressPresentIn === 'customercrmsDB') {
        this.existingUser = this.formatSyncData(existingCustomer);
      } else {
        this.existingUser = this.formatCustomersData(existingCustomer);
      }

      const zipcodes = [];
      if (this.existingUser.address[0].serviceAddress.zipcode === this.existingUser.address[0].billingAddress.zipcode) {
        zipcodes.push(this.zipcodeService.getZipcodeId(this.existingUser.address[0].serviceAddress.zipcode));
      } else {
        zipcodes.push(this.zipcodeService.getZipcodeId(this.existingUser.address[0].serviceAddress.zipcode));
        zipcodes.push(this.zipcodeService.getZipcodeId(this.existingUser.address[0].billingAddress.zipcode));
      }
      this.getZipcodeData(zipcodes, zipcodeRes => {
        const zipcodedata = zipcodeRes;
        // zipcodedata = zipcodedata['body'];
        if (!!this.existingUser.address[0].waterBillOnOff && !!this.existingUser.address[0].noWaterBillConversion) {
          const oofLocationCode = `${this.existingUser.address[0].serviceAddress.state}150`;
          this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLocationResponse => {
            const dataForLocation = dataForLocationResponse.body;
            if (oofLocationCode !== dataForLocation.locationCode) {
              this.productService.getLocationCode(oofLocationCode).subscribe(dataForOofLocationCodeResponse => {
                const dataForOofLocationCode = dataForOofLocationCodeResponse.body;
                this.locationCodeData = dataForOofLocationCode[0];
                zipcodedata[0].locationCodeId = dataForOofLocationCode[0].locationCodeId;
                resolve(zipcodedata);
              });
            } else {
              this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLocation2 => {
                this.locationCodeData = dataForLocation2.body;
                resolve(dataForLocation);
              });
            }
          });
        } else {
          this.productService.getProviderContent(zipcodedata[0].locationCodeId).subscribe(dataForLocation => {
            this.locationCodeData = dataForLocation.body;
            resolve(dataForLocation);
          });
        }

        this.existingUser.address[0].serviceAddress.zipcodeId = zipcodedata[0].zipCodeId;
        this.existingUser.address[0].serviceAddress.locationCodeId = zipcodedata[0].locationCodeId;
        if (zipcodedata.length === 1) {
          this.existingUser.address[0].billingAddress.zipcodeId = zipcodedata[0].zipCodeId;
          this.existingUser.address[0].billingAddress.locationCodeId = zipcodedata[0].locationCodeId;
        } else if (zipcodedata.length === 2) {
          this.existingUser.address[0].billingAddress.zipcodeId = zipcodedata[1].zipCodeId;
          this.existingUser.address[0].billingAddress.locationCodeId = zipcodedata[1].locationCodeId;
        }
        if (existingCustReg) {
          this.currentPageNumber += 1;
          this.step1Access = false;
          this.step2Access = true;
          this.step3Access = false;
        }
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          1000,
        );
      });
      /* HWR-2485 End */
    });
  }

  getZipcodeData(zipcodes, done: any) {
    forkJoin(zipcodes).subscribe(
      zipcodesData => {
        const zipcodeData: any = [];
        (zipcodesData as any).forEach(ele => {
          zipcodeData.push(ele.body);
        });
        done(zipcodeData);
      },
      () => {
        const errorTitle = "We're sorry, there was a problem attempting to create your account.";
        const strError = `Please contact Customer Service at <span class="app-txt">
        ${this.customer_service_number}</span>`;
        this.showLoader = false;
        this.showErrorModal(errorTitle, strError, ErrorModalComponent);
      },
    );
  }

  setContactType(type) {
    this.user.address[0].serviceAddress.contactType = type;
    this.isClicked = false;
  }

  checkExistingUser(form, done) {
    this.lastNameMismatchFound = '';
    this.existingUser = new User();
    this.showLoader = true;
    const stateValue = this.user.address[0].serviceAddress.state;
    this.existingCustomerData = {};
    this.addressPresentIn = '';
    const state = find(this.states, o => {
      if (o.value === stateValue) {
        return o;
      }
      return false;
    });
    const params = `street=${encodeURIComponent(this.user.address[0].serviceAddress.streetName?.trim() || '')}
&street_number=${encodeURIComponent(this.user.address[0].serviceAddress.streetNumber?.trim())}
&city=${encodeURIComponent(this.user.address[0].serviceAddress.city?.trim())}
&state=${encodeURIComponent(state.value)}&zip_code=${encodeURIComponent(this.user.address[0].serviceAddress.zipcode.trim())}`;

    this.userService.searchCustomerByAddressFromCustomers(params).subscribe(
      res => {
        this.addressPresentIn = 'customersDB';
        done(res.body.responseDesc);
        this.showLoader = false;
      },
      () => {
        let params2 = `street=${encodeURIComponent(this.user.address[0].serviceAddress.streetName?.trim() || '')}
    &street_number=${encodeURIComponent(this.user.address[0].serviceAddress.streetNumber?.trim())}
    &city=${encodeURIComponent(this.user.address[0].serviceAddress.city?.trim())}
    &state=${encodeURIComponent(state.value)}&zip_code=${encodeURIComponent(this.user.address[0].serviceAddress.zipcode?.trim())}
    &first_name=${encodeURIComponent(this.user.firstName.trim())}&last_name=${encodeURIComponent(this.user.lastName.trim())}
    &phone=${encodeURIComponent(this.user.phoneNumber.trim())}`;
        if (this.user.middleName) {
          params2 += `&middle_name=${encodeURIComponent(this.user.middleName.trim())}`;
        }
        this.userService.searchCustomerByAddress(params2).subscribe(
          res => {
            this.addressPresentIn = 'customercrmsDB';
            done(res.body);
            this.showLoader = false;
          },
          err => {
            let errorMassgae = '';
            let errorName = '';
            const apiError = err;
            errorName = apiError.error.name;
            if (errorName === 'MultipleCustomersFoundError') {
              errorMassgae = `We're sorry, but we are having difficulty creating your account as our records indicate there are multiple
                 accounts in your name. Please contact Customer Service at ${this.customer_service_number} to complete your registration.`;
              this.showAlertModal({
                title: "We're sorry, we are having difficulty verifying your account information.",
                message: errorMassgae,
                alertIcon: 'fa fa-exclamation-circle red',
                cancelText: ' ',
                confirmText: 'Ok',
              });
            } else {
              done([]);
            }
          },
        );
      },
    );
  }

  showAlertModal(data?: any, done?) {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: data.title,
          message: data.message,
          alertIcon: data.alertIcon,
          navigate: data.navigate,
          cancelText: data.cancelText,
          confirmText: data.confirmText,
          confirmBtnId: data.confirmBtnId ? data.confirmBtnId : 'close',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          this.resetRegisterFormData();
          if (done) {
            done(true);
          }
        } else if (isConfirmed === false) {
          if (done) {
            done(false);
          }
        } else if (isConfirmed === undefined) {
          if (done) {
            done(false);
          }
        }
        if (data.navigate) {
          this.router.navigate(['']);
        }
        if (isConfirmed === 'confirmed' && data.confirmText === 'YES, CANCEL REGISTRATION') {
          this.router.navigate(['']);
        }
      });
  }

  showCustomerServiceValidationModal(data: any, existingCodes: any, apiCall = 0, modalFor = 0, title = '', existingCustReg = false) {
    if (!title) {
      title = 'In order to better protect your account please select the program you are currently enrolled in:';
    }
    this.simpleModalService
      .addModal(
        CustomerServiceValidationModalComponent,
        {
          title,
          message: 'In progress',
          alertIcon: 'fa fa-info-circle red',
          cancelText: 'Cancel',
          confirmText: 'Continue',
          data,
          modalFor,
          existing: existingCodes,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(isConfirmed => {
        if (isConfirmed && !modalFor) {
          this.isExistingCustReg = existingCustReg;
          this.addressPresentIn = 'customercrmsDB';
          this.setExistingCustomerData(this.existingCustomerData, existingCustReg).then(() => {
            this.onSubmitRegister(this.existingCustFormVal, this.currentPageNumber, null);
          });
        } else if (!isConfirmed && isConfirmed !== undefined && !modalFor) {
          this.showAlertModal({
            title: "We're sorry, the enrollment you selected does not match with the information on your account.",
            message: `Please contact Customer Service at <span class="app-txt">
            ${this.customer_service_number}</span> to confirm your enrollment to complete registration.`,
            alertIcon: 'fa fa-exclamation-circle red',
            cancelText: ' ',
            confirmText: 'Ok',
          });
        } else if (isConfirmed && modalFor) {
          this.callSearchApi(apiCall, this.searchApiCall, existingCustReg);
        } else if (!isConfirmed && isConfirmed !== undefined && modalFor) {
          this.showAlertModal({
            title: "We're sorry, the name you selected does not match with the information on your account.",
            message: `Please contact Customer Service at <span class="app-txt">
            ${this.customer_service_number}</span> to confirm account holder information to complete registration.`,
            alertIcon: 'fa fa-exclamation-circle red',
            navigate: 'yes',
            cancelText: ' ',
            confirmText: 'Ok',
          });
        }
      });
  }

  showErrorRegistering(message = 'Please call the number at the top of page to speak to a Customer Service Representative.') {
    this.showAlertModal({
      title: "We're sorry, there was a problem attempting to create your account.",
      message,
      alertIcon: 'fa fa-exclamation-circle red',
      cancelText: ' ',
      confirmText: 'Ok',
    });
  }

  getSmartAddress(strNo, strtName, state, city, zipcode, done: any) {
    this.crmProxyService.getSmartAddress(strNo, strtName, zipcode, city, state).subscribe(
      res => {
        const data = res.body;
        if (data.length && data[0].components) {
          const addressLine1 = data[0]?.delivery_line_1;
          const addressLine2 = data[0]?.delivery_line_2 ? `${data[0].delivery_line_2}, ` : '';
          const smartAddress: string = `${addressLine1},${addressLine2}${data[0]?.components?.city_name},`;
          const userAddress: string = `${strNo.trim()},${strtName ? `${strtName.trim()}, ` : ''}${city.trim()},`;
          if (
            !(
              smartAddress === userAddress &&
              zipcode === data[0].components.zipcode &&
              city.trim() === data[0].components.city_name &&
              state === data[0].components.state_abbreviation
            )
          ) {
            this.simpleModalService
              .addModal(
                AddressValidationModalComponent,
                {
                  inputAddress: {
                    streetNumber: strNo.trim(),
                    streetName: strtName.trim(),
                    city: city.trim(),
                    state: state.trim(),
                    zipcode: zipcode.trim(),
                  },
                  suggestedAddress: data[0],
                },
                {
                  wrapperClass: 'modal-main-class in',
                  closeOnClickOutside: false,
                },
              )
              .subscribe(isConfirmed => {
                done(isConfirmed);
              });
          } else {
            done();
          }
        } else {
          const GAErrorId = 'ga_track_customer_address_validation_fail';
          const alertData = {
            title: '',
            message: `Please check your address, our system indicates that this is not a valid service address.<br></br>
            If you are continuing to experience issues, please call  1-855-800-5195 to speak to a customer service representative.`,
            alertIcon: 'fa fa-info-circle red',
            cancelText: ' ',
            confirmText: 'Continue',
            confirmBtnId: GAErrorId || 'close',
          };
          this.showAlertModal(alertData);
        }
      },
      () => {
        done(undefined);
      },
    );
  }

  securityQABlurHandler(ques, val, index) {
    this.secQueAnsErrorMsg[index] = '';
    ques = ques.toLowerCase().replace(/[^A-Z0-9 ]/gi, '');
    if (val.length < environment.valid_security_question_answer_length) {
      this.secQueAnsErrorMsg[index] = 'Please enter valid length should be 6 characters';
      return false;
    }
    if (ques.indexOf(val.toLowerCase()) !== -1 || environment.valid_security_question_answer.includes(val.toLowerCase())) {
      this.secQueAnsErrorMsg[index] = 'Please enter another value';
      return false;
    }
    return true;
  }

  securityQAKeyPressHandler(ques, val, index) {
    if (this.securityQABlurHandler(ques, val, index)) {
      this.secQueAnsErrorMsg[index] = '';
    }
  }

  selectNameModal(apiCall, apiResponse, existingCustReg) {
    apiResponse.subscribe(
      data => {
        const customerData = data.body;
        this.generateName(apiCall, customerData[0], existingCustReg);
        this.showLoader = false;
      },
      error => {
        let errorMassgae = '';
        let errorName = '';
        if (apiCall === 2) {
          const apiError = error;
          errorName = apiError.error.name;
        } else {
          errorName = '';
        }
        switch (errorName) {
          case 'NameNotFoundError':
            errorMassgae = `Account holder name does not match.
      Please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span>`;
            break;
          case 'MultipleCustomersFoundError':
            errorMassgae = `We're sorry, but we are having difficulty creating your account as our records indicate there are multiple
           accounts in your name. Please contact Customer Service at ${this.customer_service_number} to complete your registration.`;
            break;
          case 'AddressNotFoundError':
          case 'InvalidAddressError':
            errorMassgae = `Property address could not be verified.
      Please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span>`;
            break;
          default:
            errorMassgae = `Account holder name or Property address could not be verified.
      Please contact Customer Service at <span class="app-txt">${this.customer_service_number}</span>`;
        }
        this.showAlertModal({
          title: "We're sorry, we are having difficulty verifying your account information.",
          message: errorMassgae,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: ' ',
          confirmText: 'Ok',
        });
        this.showLoader = false;
      },
    );
  }

  generateName(apiCall, customerData, existingCustReg) {
    const fakeName = `${this.chance.first()} ${this.chance.last()}`;
    const originalName = this.titlecasePipe.transform(`${customerData.First_Name} ${customerData.Last_Name}`);
    const title = 'Security is important to us. For your protection please verify the name on the account';
    const data = [
      {
        name: fakeName,
      },
      {
        name: originalName,
      },
      {
        name: this.titlecasePipe.transform(`${fakeName.substring(0, fakeName.indexOf(' '))} ${customerData.Last_Name}`),
      },
      {
        name: this.titlecasePipe.transform(`${customerData.First_Name} ${fakeName.substr(fakeName.indexOf(' ') + 1)}`),
      },
    ];
    this.showCustomerServiceValidationModal(this.shuffle(data), originalName, apiCall, 1, title, existingCustReg);
  }

  shuffle(array) {
    let currentIndex = array.length;
    let temporaryValue = {};
    let randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  getSuggestion(event: any) {
    this.crmProxyService.getAddressSuggestion(event.target.value).subscribe(res => {
      this.suggestedAddress = res.body;
      this.showDropDown = true;
    });
  }

  closeDropDown() {
    this.showDropDown = !this.showDropDown;
  }

  openDropDown() {
    this.showDropDown = false;
  }

  selectValue(address1, city, form) {
    if (form === 1) {
      this.user.address[0].serviceAddress.streetNumber = address1;
      this.user.address[0].serviceAddress.city = city;
    } else {
      this.addressLookup.streetNumber = address1;
      this.addressLookup.city = city;
    }
    // this.stateForm.patchValue({"search": value});
    this.saveToLocalStorage();
    this.showDropDown = false;
  }

  allowRegistration(state, value) {
    switch (state) {
      case 'owner':
        this.homeOwner.isHomeOwner = false;
        if (value) {
          this.homeOwner.isHomeOwner = true;
        } else {
          this.homeOwner.allowRental = null;
          this.homeOwner.offbillOnly = null;
        }
        break;
      case 'rental':
        // On no Normal registration
        this.homeOwner.allowRental = false;
        if (value) {
          this.homeOwner.allowRental = true;
        } else {
          this.homeOwner.offbillOnly = null;
        }
        break;
      default:
        // tenantBill
        // On no Normal registration
        // On YES only offbill Product available for purchase
        this.homeOwner.offbillOnly = false;
        if (value) {
          this.homeOwner.offbillOnly = true;
        }
    }
  }

  updateDataForLocation(dataForLocation) {
    if (dataForLocation.locationCode === 'NV200') {
      this.showLasVegasPopup();
    }
    this.showLoader = false;
    this.locationCodeData = dataForLocation;
    this.abandonedLocationCode = this.locationCodeData.locationCode;
    this.sessionStorageService.setItem('websitePartnerName', dataForLocation.websitePartnerName);
    this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
    this.providerAvailable = false;
    /* HWR-1445 Don't show provider prompt if entered zipcode matches with zipcode stored from provider check and providerSelected is true */
    const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
      ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
      : '';
    if (
      dataForLocation.websitePartnerName !== '' &&
      this.sessionStorageService.getItem('providerSelected') !== '1' &&
      providerSelectionDetails.zipCode !== this.user.address[0].serviceAddress.zipcode
    ) {
      this.openModalPopup(this.user.address[0].serviceAddress.zipcode);
    } else {
      if (providerSelectionDetails && providerSelectionDetails.zipCode !== this.user.address[0].serviceAddress.zipcode) {
        this.sessionStorageService.setItem('providerSelectionDetails', '{}');
      }
      if (this.user.address[0].serviceAddress.state.toLowerCase() === 'ca') {
        this.ccpaUpdatesService.getCCPAUpdates();
      }
      this.checkIfProductsAreAvailable();
    }
  }

  showCCPAUpdates() {
    const providerState = this.sessionStorageService.getItem('providerState');
    if (environment.ccpa_updates_provider.includes(providerState)) {
      this.ccpaUpdatesService.getCCPAUpdates();
    }
  }

  /* Restrict Space Character AP March 9 2020 */
  AvoidSpace(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode === 32) {
      return false;
    }
    return true;
  }

  abandonedRegistrationInsert($event = null) {
    /**
     * HWR-2142[VJ]:Insert and Update AbandonedRegistration Collection
     * HWR-3016[KP][02-Feb-2021]:Added steps number and fix related locationCode error, existing user fix and code optimzation.
     * HWR-3024[KP][23-Feb-2021]:Added streetName,streetNumber.
     */
    let userDetail;
    if (this.existingUser.accountnumber) {
      userDetail = this.existingUser;
    } else {
      userDetail = this.user;
    }
    if ($event) {
      if ($event.target.name.toLowerCase() === 'email' && $event.target.value === this.oldUserData.email) {
        return;
      }
      if ($event.target.name.toLowerCase() === 'zipcode' && $event.target.value === this.oldUserData.zipcode) {
        return;
      }
    }

    if (userDetail.email && !this.isExistingUser && this.locationCodeData) {
      if (!this.abandonedLocationCode) {
        this.abandonedLocationCode = this.locationCodeData.locationCode;
      }
      const abandonedRegistration = {
        firstName: userDetail.firstName,
        lastName: userDetail.lastName,
        middleName: userDetail.middleName || '',
        email: userDetail.email.toLowerCase() || '',
        phoneNumber: userDetail.phoneNumber,
        city: userDetail.address[0].serviceAddress.city,
        zipcode: userDetail.address[0].serviceAddress.zipcode,
        state: userDetail.address[0].serviceAddress.state,
        locationCodeId: userDetail.address[0].serviceAddress.locationCodeId,
        locationCode: this.abandonedLocationCode,
        contactType: userDetail.address[0].serviceAddress.contactType || 'RESIDENTIAL',
        step: this.getCurrentStep(),
        streetName: userDetail.address[0].serviceAddress.streetName,
        streetNumber: userDetail.address[0].serviceAddress.streetNumber,
        mobilePhoneNumber: userDetail.mobilePhoneNumber || '',
      };
      if (abandonedRegistration.contactType === 'COMMERCIAL') {
        Object.keys(this.business).forEach(key => {
          abandonedRegistration[key] = this.business[key];
        });
      }
      this.oldUserData.email = abandonedRegistration.email;
      this.oldUserData.zipcode = abandonedRegistration.zipcode;
      if (!this.temporaryUserId) {
        this.userService.abandonedRegistrationInsert(abandonedRegistration).subscribe(data => {
          this.resultRegiData = data.body;
          this.temporaryUserId = this.resultRegiData._id;
        });
      } else {
        this.userService.abandonedRegistrationFindAndUpdate(this.temporaryUserId, abandonedRegistration).subscribe(data => {
          this.resultRegiData = data.body;
        });
      }
    }
    if (this.isExistingUser) {
      this.deleteAbandonedRegCustomer();
    }
    this.saveToLocalStorage();
  }

  deleteAbandonedRegCustomer() {
    if (this.temporaryUserId) {
      this.userService.abandonedregistrationcustomerdelete(this.temporaryUserId).subscribe(() => {
        this.temporaryUserId = '';
      });
    }
  }

  getCurrentStep() {
    /**
     * This method will return current steps;
     *
     */
    let CurrentStep = '1';
    if (this.existingUser.accountnumber) {
      if (this.step1Access) {
        CurrentStep = '1';
      } else if (this.step2Access) {
        CurrentStep = '2';
      } else if (this.step3Access) {
        CurrentStep = '3';
      }
    } else if (this.step0Access) {
      CurrentStep = '1';
    } else if (this.step1Access) {
      CurrentStep = '2';
    } else if (this.step2Access) {
      CurrentStep = '3';
    } else if (this.step3Access) {
      CurrentStep = '4';
    }
    return CurrentStep;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkLocationChangedForReferer(data) {
    if (this.referer) {
      /* Logic to change referer if location code is changed */
      let urlOperation: any = this.referer.split('/');
      const micrositeNames = this.sessionStorageService.getItem('micrositeName')
        ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
        : environment.micrositeNames;
      // urlOperation = urlOperation.filter(item => !environment.micrositeNames.includes(item) && item !== "");
      urlOperation = urlOperation.filter(item => !micrositeNames.includes(item) && item !== '');
      if (urlOperation.length > 0 && ['products', 'product'].includes(urlOperation[0])) {
        let changed: boolean = false;
        if (data && data.toLowerCase() !== urlOperation[2]) {
          changed = true;
          urlOperation[2] = data.toLowerCase();
          this.referer = urlOperation.join('/');
        }
        let userDetail;
        if (this.existingUser.accountnumber) {
          userDetail = this.existingUser;
        } else {
          userDetail = this.user;
        }
        if (userDetail.address[0].serviceAddress && userDetail.address[0].serviceAddress.zipcode !== urlOperation[1]) {
          changed = true;
          urlOperation[1] = userDetail.address[0].serviceAddress.zipcode;
          this.referer = urlOperation.join('/');
        }
        if (changed) {
          const redirectUrlOp: any = this.referer;
          const urlTree = this.router.createUrlTree([], {
            queryParams: {
              referer: decodeURIComponent(redirectUrlOp),
            },
            queryParamsHandling: 'merge',
            preserveFragment: true,
          });
          this.router.navigateByUrl(urlTree);
        }
      }
    }
  }

  onCustomerNumberChange() {
    this.customerExistInHOS = false;
  }

  formatSyncData(existingCustomer) {
    const existingUser: any = {};
    // hot fix optincellphone date
    existingUser.cellPhone1SmsOptInDate = new Date().toJSON().slice(0, 10);
    existingUser.accountnumber = existingCustomer.Customer_Number;

    if (existingCustomer.telephone) {
      existingUser.phoneNumber = existingCustomer.telephone.phoneNumber1;
      existingUser.phoneNumber2 = existingCustomer.telephone.phoneNumber2 || '';
    }

    if (existingCustomer.Email) {
      existingUser.email = existingCustomer.Email;
    } else if (existingCustomer.Email2) {
      existingUser.email = existingCustomer.Email2;
    } else if (existingCustomer.Email3) {
      existingUser.email = existingCustomer.Email3;
    }

    existingUser.firstName = existingCustomer.First_Name || '';
    existingUser.lastName = existingCustomer.Last_Name || '';
    existingUser.middleName = existingCustomer.Middle_Name || '';

    const contactType = existingCustomer.Contact_Type ? existingCustomer.Contact_Type : 1;
    let setContactType: any = '';
    if (contactType === 3 || contactType === 7) {
      setContactType = 'COMMERCIAL';
    } else {
      setContactType = 'RESIDENTIAL';
    }
    const serviceStateVal = find(this.states, {
      key: existingCustomer.Service_address.Service_State.toString(),
    });
    const billingStateVal = find(this.states, {
      key: existingCustomer.Billing_Address.Billing_State.toString(),
    });
    this.existingUserLocationCodeId = existingCustomer.LocationCodeId;
    existingUser.address = [
      {
        customerNo: existingCustomer.Customer_Number,
        customerGuid: existingCustomer.Customer,
        waterBillOnOff: existingCustomer.WaterBillOnOff || true,

        ownOrRentedValidated: existingCustomer.OwnOrRentValidated ? existingCustomer.OwnOrRentValidated.toString() : 'false',
        noWaterBillConversion: existingCustomer.NoWaterBillConversion ? existingCustomer.NoWaterBillConversion.toString() : 'false',
        // [HWR-2723 Feilds add for insert FirstName/LastName for existing customer registration]
        firstName: existingCustomer.First_Name || '',
        lastName: existingCustomer.Last_Name,

        serviceAddress: {
          streetName: existingCustomer.Service_address.Service_street2 || '',
          streetNumber: existingCustomer.Service_address.Service_Street_Number.toString(),
          city: existingCustomer.Service_address.Service_City.trim(),
          state: serviceStateVal.value.trim(),
          zipcode: existingCustomer.Service_address.Service_zipcode.trim(),
          contactType: setContactType,
        },

        billingAddress: {
          streetName: existingCustomer.Billing_Address.Billing_street2 || '',
          streetNumber: existingCustomer.Billing_Address.Billing_Street_Number.toString(),
          city: existingCustomer.Billing_Address.Billing_City.trim() || '',
          state: (billingStateVal ? billingStateVal.value.trim() : '') || '',
          zipcode: existingCustomer.Billing_Address.Billing_zipcode || '',
          contactType: setContactType || '',
        },
      },
    ];
    return existingUser;
  }

  formatCustomersData(existingCustomer) {
    return {
      firstName: existingCustomer.firstName,
      lastName: existingCustomer.lastName,
      middleName: existingCustomer.middleName,
      email: existingCustomer.email,
      phoneNumber: existingCustomer.phoneNumber,
      phoneNumber2: existingCustomer.phoneNumber2,
      mobilePhoneNumber: existingCustomer.mobilePhoneNumber,
      isAuthorizedToSendMessage: existingCustomer.isAuthorizedToSendMessage,
      password: existingCustomer.password,
      securityQuestion: existingCustomer.securityQuestion,
      referer: existingCustomer.referer,
      utmParams: existingCustomer.utmParams,
      source: existingCustomer.source,
      contactType: existingCustomer.contactType,
      cellPhone1SmsOptInDate: existingCustomer.cellPhone1SmsOptInDate,
      retainUrl: existingCustomer.retainUrl,
      isFromCheckout: existingCustomer.isFromCheckout,
      isPasswordUpdate: true,
      address: [
        {
          customerNo: existingCustomer.address.customerNo,
          customerGuid: existingCustomer.address.customerGuid,
          waterBillOnOff: existingCustomer.address.waterBillOnOff || true,

          ownOrRentedValidated: existingCustomer.address.ownOrRentedValidated,
          noWaterBillConversion: existingCustomer.address.noWaterBillConversion,
          firstName: existingCustomer.address.firstName || '',
          lastName: existingCustomer.address.lastName,

          serviceAddress: {
            streetName: existingCustomer.address.serviceAddress.streetName || '',
            streetNumber: existingCustomer.address.serviceAddress.streetNumber,
            city: existingCustomer.address.serviceAddress.city.trim(),
            state: existingCustomer.address.serviceAddress.state.trim(),
            zipcode: existingCustomer.address.serviceAddress.zipcode.trim(),
            contactType: existingCustomer.address.serviceAddress.contactType,
            zipcodeId: existingCustomer.address.serviceAddress.zipcodeId,
            locationCodeId: existingCustomer.address.serviceAddress.locationCodeId,
          },

          billingAddress: {
            streetName: existingCustomer.address.billingAddress.streetName || '',
            streetNumber: existingCustomer.address.billingAddress.streetNumber || '',
            city: existingCustomer.address.billingAddress.city.trim() || '',
            state: existingCustomer.address.billingAddress.state.trim() || '',
            zipcode: existingCustomer.address.billingAddress.zipcode.trim() || '',
            contactType: existingCustomer.address.billingAddress.contactType || '',
          },
        },
      ],
    };
  }

  formatAddPropertyData(payload) {
    return {
      email: payload.email,
      password: payload.password,
      isPasswordUpdate: true,
      ...payload.address[0],
    };
  }

  checkCustomerExists(customerList, lastName, email) {
    let customerFound = null;
    let emailMatched = false;
    lastName = lastName.toLowerCase().trim();
    email = email.toLowerCase().trim();
    for (let i = 0; i < customerList?.length; i++) {
      const currentCustomer = customerList[i];
      if (
        currentCustomer.Last_Name &&
        currentCustomer.Last_Name.toLowerCase().trim() === lastName &&
        currentCustomer.email &&
        currentCustomer.email.toLowerCase().trim() === email
      ) {
        customerFound = currentCustomer;
        break;
      } else if (currentCustomer.email && currentCustomer.email.toLowerCase().trim() === email) {
        customerFound = currentCustomer;
        emailMatched = true;
      } else if (
        this.addressPresentIn === 'customersDB' &&
        currentCustomer.lastName &&
        currentCustomer.lastName.toLowerCase().trim() === lastName &&
        !emailMatched
      ) {
        customerFound = currentCustomer;
      } else if (
        this.addressPresentIn === 'customercrmsDB' &&
        currentCustomer.Last_Name &&
        currentCustomer.Last_Name.toLowerCase().trim() === lastName &&
        !emailMatched
      ) {
        customerFound = currentCustomer;
      }
    }
    return customerFound;
  }

  checkIfProductsAreAvailable() {
    try {
      this.showLoader = true;
      this.productService.getMarketingCodes('', this.user.address[0].serviceAddress.locationCodeId, true).subscribe(
        () => {
          this.showLoader = false;
          this.productsUnavailable = false;
        },
        () => {
          this.showServiceUnavailablePopUp();
        },
      );
    } catch (err) {
      this.showServiceUnavailablePopUp();
    }
  }

  showServiceUnavailablePopUp() {
    this.showLoader = false;
    this.productsUnavailable = true;
    this.isConfirmation = false;
    this.simpleModalService.addModal(
      ErrorModalComponent,
      {
        title: 'We are sorry. We do not currently offer services in your location.',
        message: 'Please check back soon for product and service availability in your area.',
        showButton: 'close',
        alertType: 'info-type',
      },
      {
        wrapperClass: 'modal-main-class in',
        closeOnClickOutside: true,
      },
    );
  }

  saveToLocalStorage() {
    if (!this.existingCustomerData) {
      this.sessionStorageService.setItem('registerFormData', JSON.stringify(this.user));
    }
  }

  resetRegisterFormData() {
    if (!this.existingCustomerData) {
      this.registerForm.resetForm();
      this.zipcodeComponent.zipcode = '';
      this.zipcodeSet = false;
      this.isClicked = false;
      this.confirmPassword.reset();
      this.password.reset();
      this.sessionStorageService.deleteItem('registerFormData');
    }
  }

  showLasVegasPopup() {
    this.simpleModalService
      .addModal(
        AlertConfirmModalComponent,
        {
          title: ``,
          message: `Please be sure to enroll with the same name & address that's on your LVVWD water bill to qualify for first year reimbursement from LVVWD.`,
          alertIcon: 'fa fa-exclamation-circle red',
          cancelText: '',
          confirmText: 'OK',
          confirmBtnId: 'las-vegas-ok',
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: false,
        },
      )
      .subscribe(() => {});
  }
}
