import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observer, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { HttpService } from '../../core/services/http';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { User } from '../component/register/user';
import { GlobalVariables } from '../../core/services/global-variables.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserService {
  isBrowser: Boolean = true;

  private subscriptionObservable: Observable<any>;

  private subscribers: Array<Observer<any>> = new Array<Observer<any>>();

  /**
   * Returns the stored vaule stored with given key.
   *
   * @param {string} key
   * @returns {string}
   *
   * @memberof UserService
   */
  constructor(
    private router: Router,
    private http: HttpService,
    private sessionStorageService: SessionStorageService,
    private httpClient: HttpClient,
    private globalVariables: GlobalVariables,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.subscriptionObservable = new Observable<any>((observer: Observer<any>) => {
      this.subscribers.push(observer);
      return () => {
        this.subscribers = this.subscribers.filter(obs => obs !== observer);
      };
    });
    this.isBrowser = isPlatformBrowser(platformId);
  }

  setLoggin(isLoggedIn: String): void {
    this.subscribers.forEach(sub => {
      try {
        sub.next(isLoggedIn);
      } catch (e) {
        sub.next(false);
      }
    });
  }

  public get(): Observable<any> {
    return this.subscriptionObservable;
  }

  /**
   * Registers a user.
   *
   * @param {string} key
   * @param {string} username
   * @param {string} password
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  register(email: string, password: string): Observable<any> {
    const userData = {
      user: {
        firstName: 'string',
        lastName: 'string',
        email: email.trim(),
        role: ['User'],
        password,
        username: 'string',
        phoneNumber: 'string',
      },
      registerType: 'Register',
    };

    return this.http.post(`${environment.User_service_API_Endpoint}register`, userData).pipe(map(res => res));
  }

  /**
   * Registers a user.
   *
   * @param {string} key
   * @param {string} username
   * @param {string} password
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  registerCustomer(user: User): Observable<any> {
    return this.http.post(`${environment.Customer_service_API_Endpoint}customer`, user).pipe(map(res => res));
  }

  /**
   *HWR-2142[VJ]:Insert Data In AbandonedRegistration Collection.
   *
   * @param {any} registerData
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  abandonedRegistrationInsert(resultRegiData: any): Observable<any> {
    return this.http.post(`${environment.Customer_service_API_Endpoint}customer/abandonedregistrationcustomer`, resultRegiData).pipe(map(res => res));
  }

  /**
   *HWR-2142[VJ]:FindAndUpdate Data In AbandonedRegistration Collection.
   *
   * @param {string} temporaryUserId
   * @param {any} registerData
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  abandonedRegistrationFindAndUpdate(temporaryUserId: string, resultRegiData: any): Observable<any> {
    return this.http
      .put(`${environment.Customer_service_API_Endpoint}customer/abandonedregistrationcustomer/${temporaryUserId}`, resultRegiData)
      .pipe(map(res => res));
  }

  /**
   *HWR-2142[VJ]:Delete Data From AbandonedRegistration Collection.
   *
   * @param {string} temporaryUserId
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  abandonedregistrationcustomerdelete(temporaryUserId: string): Observable<any> {
    return this.http
      .delete(`${environment.Customer_service_API_Endpoint}customer/abandonedregistrationcustomerdelete/${temporaryUserId}`)
      .pipe(res => res);
  }

  /**
   * Resets password of user with valid OTP(token).
   *
   * @param {string} password
   * @param {string} token
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  resetPassword(password: String, token: String): Observable<any> {
    const userData = {
      token,
      password,
    };

    return this.http.post(`${environment.User_service_API_Endpoint}login/reset`, userData).pipe(map(res => res));
  }

  /**
   * Changes password for logged in user it uses authorization header.
   *
   * @param {string} password
   * @param {string} password_confirmation
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  changePassword(password: string, password_confirmation: string): Observable<any> {
    const userData = {
      password,
      password_confirmation,
    };

    return this.http.put(`${environment.User_service_API_Endpoint}user/changepassword`, userData).pipe(map(res => res));
  }

  /**
   * Send an email to users email id to reset password.
   *
   * @param {string} email
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  forgotPassword(email: string): Observable<any> {
    const userData = {
      email: email.trim(),
    };
    return this.http.post(`${environment.User_service_API_Endpoint}login/forgot`, userData).pipe(map(res => res));
  }

  /**
   * Logs in user.
   *
   * @param {string} key
   * @param {string} password
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  signIn(username: string, password: string): Observable<any> {
    const userData = {
      email: username.trim(),
      password,
    };
    return this.http.post(`${environment.User_service_API_Endpoint}login`, userData).pipe(map(res => res));
  }

  /**
   * Logs user out.
   *
   * @param {string} key
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  signOut(): void {
    // remove data from session storage and redis server;
    const micrositeNames = this.sessionStorageService.getItem('micrositeName');
    const cartCheckoutData = this.sessionStorageService.getItem('cartCheckout');
    const state = this.sessionStorageService.getItem('providerState');
    this.sessionStorageService.clear();
    this.sessionStorageService.setItem('providerState', state);
    this.sessionStorageService.setItem('micrositeName', micrositeNames);
    if (!['null', 'undefined', undefined, null, ''].includes(cartCheckoutData)) {
      this.sessionStorageService.setItem('cartCheckout', cartCheckoutData);
    }
    // this.router.navigate(['/']);
  }

  /**
   * Acitvates the registered user.
   *
   * @param {string} token
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  activateAccount(token: string): Observable<any> {
    return this.http.get(`${environment.User_service_API_Endpoint}register/activate?token=${token}`).pipe(map(res => res));
  }

  /**
   * Returns a user profile it takes authorization token as input.
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getUserProfile(userId: any): Observable<any> {
    // return this.http.get(environment.User_service_API_Endpoint + `user/profile`)
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/${userId}`).pipe(map(res => res));
  }

  /**
   * Returns a user profile tips and title.
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  /**
   * Get Profile home page content.
   */
  getProfileTipsAndTitle() {
    return this.http.get(`${environment.Content_service_API_Endpoint}profile-content`).pipe(map(res => res));
  }

  getUserCartData(userId: any): Observable<any> {
    return this.http.get(`${environment.Customer_service_API_Endpoint}cart/${userId}`).pipe(map(res => res));
  }

  /**
   * Sends activation email to the registered user.
   *
   * @param {String} email
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  resendActivationEmail(email: String): Observable<any> {
    const userData = {
      email: email.trim(),
    };

    return this.http.post(`${environment.User_service_API_Endpoint}register/send-activation-email`, userData).pipe(map(res => res));
  }

  /**
   *
   * @param {string} category
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  verifyCaptcha(resposne: String): Observable<any> {
    const userData = {
      response: resposne,
    };

    return this.http.post(`${environment.User_service_API_Endpoint}captcha/verify`, userData).pipe(map(res => res));
  }

  /**
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getSignedInUser() {
    let user;
    try {
      const token = this.sessionStorageService.getItem('authHeader');
      if (token && this.isBrowser) {
        user = JSON.parse(atob(token.split(' ')[1].split('.')[1]));
      }
    } catch (e) {
      console.error(e);
    }
    return user;
  }

  /**
   * Get customer details
   *
   * @param {String} userId
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getCustomerDetails(userId: String, guestToken?: string): Observable<any> {
    const options = guestToken ? { headers: { authorization: guestToken } } : undefined;
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/${userId}`, options).pipe(
      map(res => {
        return res;
      }),
    );
  }

  /**
   * Get security questions
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getSecurityQuestions(): Observable<any> {
    return this.http.get(`${environment.Content_service_API_Endpoint}security-question`).pipe(map(res => res));
  }

  /**
   * Get security questions
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getCustomerSecurityQuestions(userId: String): Observable<any> {
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/securityquestion/${userId}`).pipe(map(res => res));
  }

  /**
   * Get customer address
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getCustomerAddress(userId: String): Observable<any> {
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/address/?customerId=${userId}`).pipe(map(res => res));
  }

  /**
   * Verify userEmail
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  verifyUser(email: String): Observable<any> {
    return this.http.get(`${environment.User_service_API_Endpoint}user/checkemail?email=${email}`).pipe(map(res => res));
  }

  /**
   * Verify phoneNumber
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  verifyPhoneNumber(phone: String): Observable<any> {
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/check/phonenumber/${phone}`).pipe(map(res => res));
  }

  /**
   * Update customer info
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  updateCustomerInfo(customerId: string, userData: any): Observable<any> {
    return this.http.put(`${environment.Customer_service_API_Endpoint}customer/${customerId}`, userData).pipe(map(res => res));
  }

  /**
   * Update primary email
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  updatePrimaryEmail(customerId: string, userData: any): Observable<any> {
    return this.http.put(`${environment.User_service_API_Endpoint}/user/changeemail/${customerId}`, userData).pipe(map(res => res));
  }

  /**
   * Update customer billing address info info
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  updateCustomerBillingAddress(customerId: string, addressId: string, userAddressData: any): Observable<any> {
    return this.http
      .put(`${environment.Customer_service_API_Endpoint}customer/${customerId}/address/${addressId}`, userAddressData)
      .pipe(map(res => res));
  }

  /**
   * Update customer preference info
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  updatePreferences(customerId: string, userPreferenceData: any): Observable<any> {
    return this.http.put(`${environment.Customer_service_API_Endpoint}customer/${customerId}`, userPreferenceData).pipe(map(res => res));
  }

  /**
   * add customer service address info
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  addCustomerServiceAddress(customerId: string, userAddressData: any): Observable<any> {
    return this.http.post(`${environment.Customer_service_API_Endpoint}address/service/${customerId}`, userAddressData).pipe(map(res => res));
  }

  /**
   * add new customer address info
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  addCustomerAddress(customerId: string, addresses: any): Observable<any> {
    return this.http.post(`${environment.Customer_service_API_Endpoint}customer/${customerId}/address/`, addresses).pipe(map(res => res));
  }

  /**
   * Get customer service address info
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getAllCustomerAddresses(customerId: string, guestToken?): Observable<any> {
    const options = guestToken ? { headers: { authorization: guestToken } } : undefined;
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/address/${customerId}`, options).pipe(
      map(res => {
        this.globalVariables.getAllCustomerAddresses = res.body;
        return res;
      }),
    );
  }

  /**
   * Get customer account info
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getCustomerAccounts(customerGuid: string, guestToken?): Observable<any> {
    return this.http
      .get(`${environment.Eft_Proxy_Service_API_Endpoint}account/${customerGuid}`, {
        authorization: guestToken,
      })
      .pipe(map(res => res));
  }

  /**
   * Get customer payment cards
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getCustomerCards(customerToken: string, guestToken?): Observable<any> {
    return this.http
      .get(`${environment.Eft_Proxy_Service_API_Endpoint}financial-instrument/${customerToken}`, {
        authorization: guestToken,
      })
      .pipe(map(res => res));
  }

  /**
   * Add new address.
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  addNewAddress(customerId: string, userData: any): Observable<any> {
    return this.http.post(`${environment.Customer_service_API_Endpoint}address/${customerId}`, userData).pipe(map(res => res));
  }

  /**
   * CRM customer address lookup.
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  crmCustomerAddress(
    streetNumber: string,
    streetName: string,
    city: String,
    state: String,
    stateId: String,
    zipcode: String,
    customerId?: String,
  ): Observable<any> {
    let params = `streetNumber=${encodeURIComponent(streetNumber)}&streetName=${encodeURIComponent(
      streetName,
    )}&city=${city}&state=${state}&zipcode=${zipcode}`;
    if (customerId) {
      params += `&customerId=${customerId}`;
    }
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/verify-service-address?${params}`).pipe(map(res => res));
  }

  /**
   * Returns a crm customer based on customer id.
   * @returns {Observable<any>}
   * @memberof UserService
   */
  searchCustomerById(customerId: string, query: any): Observable<any> {
    const url = `${environment.Crm_customer_service_API_Endpoint}crm-customer/${customerId}?${query}`;
    return this.http.get(url).pipe(map(res => res));
  }

  /**
   * Returns a crm customer based on customer address and phone info etc.
   * @returns {Observable<any>}
   * @memberof UserService
   */
  searchCustomerByAddress(query: any): Observable<any> {
    const url = `${environment.Crm_customer_service_API_Endpoint}crm-customer/search?${query}`;
    return this.http.get(url).pipe(map(res => res));
  }

  /**
   * Returns a customer based on customer address and phone info etc.
   * @returns {Observable<any>}
   * @memberof UserService
   */
  searchCustomerByAddressFromCustomers(query: any): Observable<any> {
    const url = `${environment.Customer_service_API_Endpoint}customer/searchAddressHash?${query}`;
    return this.http.get(url).pipe(map(res => res));
  }

  enrollExistingUser(data: any): Observable<any> {
    return this.http.post(`${environment.Customer_service_API_Endpoint}customer/enroll`, data).pipe(map(res => res));
  }

  /**
   * Returns a .
   * @returns {boolean} customerExist
   * @memberof UserService
   */
  searchExistingCustomerByCustomerNumber(customerNumber: any): Observable<any> {
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/searchExistingCustomer/${customerNumber}`).pipe(map(res => res));
  }

  /**
   * DONOT EDIT OR TOUCH THIS METHOD
   * Logs in Admin as a user.
   *
   * @param {string} key
   * @param {string} password
   * @param {boolean} isAdmin
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  adminSignInAsUser(username: string, password: string, isAdmin: boolean): Observable<any> {
    const userData = {
      email: username.trim(),
      password,
      isAdmin,
    };
    // return this.httpClient.post('http://localhost:8125/' + `login`, userData)
    return this.http.post(`${environment.User_service_API_Endpoint}login`, userData).pipe(map(res => res));
  }

  /**
   * Get customer payment record (check delinquency)
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  delinquencyCheck(customerNo: string): Observable<any> {
    return this.http.get(`${environment.Customer_service_API_Endpoint}delinquency-check?customer=${customerNo}`).pipe(map(res => res));
  }

  /**
   * Returns a .
   * @returns {any} SignIntoken
   * @memberof UserService
   */
  etsSignInToken(token?): Observable<any> {
    let obj = {};
    if (token) {
      const options = token ? { headers: { authorization: token } } : {};
      obj = {
        ...options,
      };
    }
    return this.http.get(`${environment.Eft_Proxy_Service_API_Endpoint}session-token`, obj).pipe(map(res => res));
  }

  // VJ[3509]-Delete Property From admin side.
  deleteProperty(dataForDelete: any): Observable<any> {
    return this.http
      .post(`${environment.Customer_service_API_Endpoint}customer/delete-web-customer/${dataForDelete.customerId}`, dataForDelete)
      .pipe(map(res => res));
  }

  getGuestToken(email: string) {
    return this.http.post(`${environment.User_service_API_Endpoint}token`, {
      email,
    });
  }

  getIncreasedPriceCustomerInfo(customerId: string) {
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer-campaign/price-increase?id=${customerId}`).pipe(map(res => res));
  }

  getIsFistTimeCustomer(email: string) {
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/isfirsttimeuser?email=${email}`).pipe(map(res => res));
  }

  getAzureB2cRedirectUrl() {
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/azure/getazureb2curl`).pipe(map(res => res));
  }

  getEnrollmentDatafromOracle(custAccountId) {
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/oracle/enrollmentdata?custaccountid=${custAccountId}`).pipe(map(res => res));
  }

  getCustAccountIdFromOracle(oracleCustId) {
    return this.http.get(`${environment.Customer_service_API_Endpoint}customer/oracle/getcustaccountid?oraclecustid=${oracleCustId}`).pipe(map(res => res));
  }

  /**
   * Create customer account stripe
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  stripeCreateCustomer(body: string): Observable<any> {
    return this.http.post(`${environment.Eft_Proxy_Service_API_Endpoint}stripe/customer`, body).pipe(
      map(res => {
        return res;
      }),
    );
  }

  /**
   * Get customer account info stripe
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getStripeCustomerAccounts(stripeId: string): Observable<any> {
    return this.http.get(`${environment.Eft_Proxy_Service_API_Endpoint}stripe/cards/${stripeId}`).pipe(
      map(res => {
        return res;
      }),
    );
  }

  /**
   * Get stripe session
   *
   * @returns {Observable<any>}
   *
   * @memberof UserService
   */
  getStripeSessionForCust(queryParams): Observable<any> {
    const paymentTypes = queryParams.paymentTypes.map(val => `paymentTypes=${val}`).join('&');
    return this.http.get(`${environment.Eft_Proxy_Service_API_Endpoint}stripe/session?${paymentTypes}&customer=${queryParams.customer}`).pipe(
      map(res => {
        return res;
      }),
    );
  }
}
