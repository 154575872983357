<div id="cart-header" class="cart cart-header active target-show fs-restrict-dead-clicks">
  <div>
    <div class="cart-note-div beige-bg">
      <span *ngIf="globalVariables?.cartCheckout?.items?.length === 0 || cartProducts.length === 0" class="cart-note">Cart total (0 Plan)</span>
      <span *ngIf="globalVariables?.cartCheckout?.items?.length > 0" class="cart-note"
        >Cart total ({{ globalVariables?.cartCheckout?.items?.length > 0 ? globalVariables?.cartCheckout?.items?.length : 0 }} Plan): ${{
          productsByBillingType?.grossTotal | number : '1.2-2'
        }}</span
      >
    </div>
    <div *ngIf="globalVariables?.cartCheckout?.items?.length > 0" class="cart-body">
      <div *ngIf="productsByBillingType?.offbill?.products?.length > 0" class="payment-box payment-box-offbill content-section">
        <div class="page-wrap row ets-box col-12">
          <div class="card card-bg-cls border-light mb-3">
            <div class="row no-gutters">
              <span class="col-12 card-title">Product(s) to be <b>Paid Now</b> </span>
              <div class="col-12">
                <div class="select-payment-mobile">
                  <span class="m-font select-payment pull-left mt-1">Select payment option:</span>
                  <form>
                    <div class="row payment-option txt-right">
                      <div class="col-6 padd0" *ngFor="let freq of billingfrequencyArr">
                        <div class="custom-radio">
                          <input
                            type="radio"
                            class="custom-control-input"
                            id="frequency-{{ freq }}-{{ tempId }}"
                            [value]="freq"
                            name="frequency"
                            [(ngModel)]="globalVariables.billingFrequencyCheckout"
                            (change)="setPaymentFrequency(freq)"
                          />
                          <label class="custom-control-label" for="frequency-{{ freq }}-{{ tempId }}">{{ freq }}</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul *ngIf="productsByBillingType?.offbill?.products?.length > 0" class="cart-list">
        <li class="cart-item">
          <div class="cart-info">
            <div class="payment-box content-section shaded-white PB-0">
              <div class="ets-box">
                <div class="card border-light mb-3">
                  <ng-container *ngIf="productsByBillingType?.offbill?.products?.length > 0">
                    <div class="card-body-box PD-0">
                      <div class="card-body row no-gutters PL-5 PR-5" *ngFor="let product of productsByBillingType.offbill.products">
                        <div class="text-left media-icon col-md-1 col-lg-1 col-2">
                          <img
                            class="cart-image"
                            [alt]="product?.details?.webProgramName || product?.details?.customerProgramName"
                            [src]="product?.details?.programIconUrl ? getImage(productServiceBaseUrl + product.details.programIconUrl) : ''"
                          />
                        </div>
                        <div class="col-7 col-md-7 col-xl-6 product-details PL-10">
                          <h6 class="product-name LH-20">
                            <strong>{{ product?.details?.webProgramName || product?.details?.customerProgramName }}</strong>
                          </h6>
                          <p class="product-desc">
                            Zip Code: {{ globalVariables?.cartCheckout?.items[0]?.zipcode }} <br />
                            {{ globalVariables?.billingFrequencyCheckout }} Billing Frequency selected
                            <br />
                            {{ product?.isAutoRenewStatus ? product.isAutoRenewStatus : 'Auto Renews Annually' }}
                            <button type="button" class="btn btn-color" (click)="renewProduct(product)">Edit</button>
                          </p>
                          <div class="text-left mobile-only mobile-view">
                            <button
                              type="button"
                              *ngIf="!showPaymentPage"
                              class="btn btn-link non-href-links btn-sm navigate-link pl-5 mobile-view__pl-0"
                              (click)="removeProduct(product)"
                            >
                              Remove
                            </button>
                            <span class="statusBtnStyle pl-5">{{ globalVariables?.billingFrequencyCheckout }}</span>
                          </div>
                        </div>
                        <div class="col-3 col-md-4 col-xl-5 row flex-center-cls">
                          <div
                            class="col-7 desktop-only"
                            style="display: flex; flex-direction: column; margin-top: -10px; justify-content: center; align-items: center; padding: 0"
                          >
                            <button
                              type="button"
                              class="btn btn-link non-href-links btn-sm navigate-link col-9 ga-track-remove-product PD-0"
                              (click)="removeProduct(product)"
                              *ngIf="!showPaymentPage"
                            >
                              REMOVE
                            </button>
                            <p class="statusBtnStyle margin-0">{{ globalVariables?.billingFrequencyCheckout }}</p>
                          </div>
                          <div class="col-12 col-md-12 col-xl-5 p-none offbill-price-cls PR-10">
                            <h6
                              class="cost-txt normal-font"
                              *ngIf="globalVariables?.billingFrequencyCheckout === 'Monthly'; then Monthly; else Annual"
                            ></h6>
                            <ng-template #Monthly>
                              <span class="notranslate">${{ product?.details?.monthlyPrice | slice : 0 : -2 }}</span>
                            </ng-template>
                            <ng-template #Annual>
                              <span class="notranslate">${{ product?.details?.annualPrice | slice : 0 : -2 }}</span>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="row no-gutters tax-div PR-10">
                        <div class="text-left media-icon col-12"></div>
                        <div class="col-6 desktop-only product-details"></div>
                        <div class="col-12 row mobile-only">
                          <div class="col-12 PD-0 flex-align-center">
                            <div class="col-8 text-right product-taxes">Taxes</div>
                            <div class="col-4 text-right to-right p-none PR-7">
                              <h6 class="normal-font margin-0">
                                <span class="notranslate">${{ productsByBillingType?.offbill?.tax.toFixed(2) }}</span>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-6 row desktop-only">
                          <div class="col-5 text-right product-taxes">Taxes</div>
                          <div class="col-12 col-md-6 text-right to-right p-none PR-7">
                            <h6 class="normal-font margin-0">
                              <span class="notranslate">${{ productsByBillingType?.offbill?.tax.toFixed(2) }}</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body row no-gutters sub-total-box pad-0-10">
                      <div class="col-6 total">
                        <div class="product-total-name">
                          <span
                            >Amount Due Now
                            <div>Recurring {{ globalVariables?.billingFrequencyCheckout | titlecase }} Payment (including sales tax)</div>
                          </span>
                        </div>
                      </div>
                      <div class="col-6 row parent-align">
                        <div class="col-12 col-md-12 col-xl-5 text-right font-16 align-subtotal PR-5">Total</div>
                        <div class="col-12 col-md-12 col-xl-7 text-right value-subtotal PR-7">
                          <span class="notranslate">${{ productsByBillingType?.offbill?.total }}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="providerState">
                      <span class="text-green">By enrolling today in the Water Line Protection Program, Las Vegas Valley Water District will reimburse your first year.
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div *ngIf="productsByBillingType?.onbill?.products?.length > 0" class="payment-box payment-box-offbill content-section">
        <div class="page-wrap row PD-0 ets-box col-12">
          <div class="card card-bg-cls border-light mb-3">
            <div class="row no-gutters">
              <span class="col-12 card-title">Product(s) to be included on your <b>Monthly Water Bill</b> </span>
            </div>
          </div>
        </div>
      </div>
      <ul *ngIf="productsByBillingType?.onbill?.products?.length > 0" class="cart-list margin-0">
        <li class="cart-item">
          <div class="cart-info">
            <div class="payment-box content-section shaded-white PB-0">
              <div class="ets-box">
                <div class="card border-light mb-3 margin-0"></div>
                <div *ngIf="productsByBillingType.onbill.products.length > 0" class="card border-light mb-3">
                  <div class="card-body-box PD-0">
                    <div class="card-body row no-gutters PL-5 PR-5 align-items-center" *ngFor="let product of productsByBillingType.onbill.products">
                      <div class="text-left media-icon col-md-1 col-lg-1 col-2">
                        <img
                          [alt]="product?.details?.webProgramName || product?.details?.customerProgramName"
                          class="cart-image"
                          [src]="product?.details?.programIconUrl ? getImage(productServiceBaseUrl + product.details.programIconUrl) : ''"
                        />
                      </div>
                      <div class="col-7 col-md-8 col-xl-7 product-details PL-10">
                        <h6 class="product-name LH-20">
                          <strong>{{ product?.details?.webProgramName || product?.details?.customerProgramName }}</strong>
                        </h6>
                        <p class="product-desc MB-0">
                          Zip Code: {{ globalVariables?.cartCheckout?.items[0]?.zipcode }} <br />
                          Monthly Billing Frequency
                        </p>
                        <div class="text-left mobile-only mt-mobile-view mobile-view flex-row-align-center">
                          <button
                            *ngIf="!showPaymentPage"
                            type="button"
                            class="btn btn-link non-href-links btn-sm navigate-link ga-track-remove-product mobile-view__pl-0"
                            (click)="removeProduct(product)"
                          >
                            Remove
                          </button>
                          <span class="statusBtnStyle pl-mobile-view">ON BILL</span>
                        </div>
                      </div>
                      <div class="col-3 col-md-3 col-xl-4 row flex-center-cls">
                        <div
                          class="col-7 desktop-only"
                          style="display: flex; flex-direction: column; margin-top: -10px; justify-content: center; align-items: center; padding: 0"
                        >
                          <button
                            type="button"
                            class="btn btn-link non-href-links btn-sm navigate-link col-9 ga-track-remove-product PD-0"
                            (click)="removeProduct(product)"
                            *ngIf="!showPaymentPage"
                          >
                            REMOVE
                          </button>
                          <p class="statusBtnStyle margin-0">ON BILL</p>
                        </div>
                        <div class="col-12 col-md-12 col-xl-5 p-none desktop-only offbill-price-cls PR-10">
                          <span>${{ product?.details?.monthlyPrice | slice : 0 : -2 }}</span>
                        </div>
                        <div class="col-12 col-md-12 col-xl-6 p-none mobile-only text-align-center">
                          <span>${{ product?.details?.monthlyPrice | slice : 0 : -2 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="row no-gutters tax-div PR-10">
                      <div class="col-7 col-md-8 col-xl-7 product-details desktop-only">
                        <!-- <div class="text-right mobile-only product-taxes">
                                                    Taxes
                                                </div> -->
                      </div>
                      <div class="col-12 row mobile-only">
                        <div class="col-12 PD-0 flex-align-center">
                          <div class="col-8 text-right product-taxes">Taxes</div>
                          <div class="col-4 text-right to-right p-none PR-7">
                            <h6 class="normal-font margin-0">
                              <span class="notranslate">${{ productsByBillingType?.onbill?.tax.toFixed(2) }}</span>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 col-md-3 col-xl-5 row desktop-only">
                        <div class="col-5 text-right product-taxes">Taxes</div>
                        <div class="col-12 col-md-6 text-right to-right p-none PR-7">
                          <h6 class="normal-font margin-0">
                            <span class="notranslate">${{ productsByBillingType?.onbill?.tax.toFixed(2) }}</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body row no-gutters sub-total-box margin-offbill pad-0-20">
                    <div class="col-8 col-md-9 col-xl-8 total">
                      <div class="product-total-name">
                        <span>Amount to be included on your next monthly water bill</span>
                        <!-- <div>Recurring monthly charges plus taxes.</div> -->
                        <div>Recurring Monthly Payment (including sales tax)</div>
                        <!-- HWR-2190 Jan 9 2020 changes -->
                      </div>
                    </div>
                    <div class="col-4 col-md-3 col-xl-4 row parent-align">
                      <div class="col-12 col-md-12 col-xl-5 text-right font-16 align-subtotal PD-0">Total</div>
                      <div class="col-12 col-md-12 col-xl-7 text-right value-subtotal onbill-subtotal PR-0">
                        <span class="notranslate">${{ productsByBillingType?.onbill?.total }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div *ngIf="globalVariables?.cartCheckout?.items?.length > 0" class="cart-details-main">
        <div class="cart-details">
          <span class="cart-title">Total ({{ globalVariables?.cartCheckout?.items?.length }}) Plan</span>
          <span class="cart-price cart-total-price">${{ productsByBillingType?.grossTotal | number : '1.2-2' }}</span>
        </div>
        <span *ngIf="!showPaymentPage" class="cart-fine-print">Applicable taxes will be applied</span>
      </div>
      <div
        class="no-data-card-body"
        [ngClass]="{ hideLoading: showLoader }"
        *ngIf="
          cartProducts.length > 0 &&
          productsByBillingType?.offbill?.products.length === 0 &&
          productsByBillingType?.onbill?.products.length === 0 &&
          globalVariables.showErrorMessageOnCart &&
          !showLoader
        "
      >
        We are facing technical difficulties, Please try again later.
      </div>
      <ng-container *ngIf="!showPaymentPage">
        <div *ngIf="globalVariables?.cartCheckout?.items?.length > 0 && displayOtherProduct?.length > 0" class="cart-link-div">
          <div class="col-12 d-flex p-0">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 p-0 mob-hide"><hr /></div>
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 cart-other-link">Other Services you may be interested in</div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 p-0 mob-hide"><hr /></div>
          </div>
          <div *ngFor="let product of displayOtherProduct; last as isLast">
            <div class="col-12 mar-15-0 flex-align-center">
              <div class="col-2 PD-0">
                <figure class="image-thumb margin-0">
                  <img class="other-service-img" [src]="getImage(productServiceBaseUrl + product?.programIconUrl)" [alt]="product?.webProgramName" />
                </figure>
              </div>
              <div class="col-7 PR-0 text-align-center">
                <p class="margin-0 font-size-15">
                  Add <b>{{ product?.webProgramName }}</b>
                </p>
                <p class="notranslate margin-0 font-size-13">
                  For only <span class="font-size-18">${{ product?.monthlyPrice | slice : 0 : -2 }} </span> Per Month
                </p>
              </div>
              <div class="col-3 PD-0 text-align-center font-size-15">
                <a class="cart-link" (click)="addCheckoutItemToCart(product)">Add to cart</a>
              </div>
            </div>
            <div class="col-12 flex-just-center">
              <hr class="col-12 devider" *ngIf="!isLast" />
            </div>
          </div>
          <div *ngIf="displayOtherProduct?.length === 0">No other services available.</div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="!showPaymentPage">
      <div *ngIf="globalVariables?.cartCheckout?.items?.length === 0 || cartProducts.length === 0" class="card-body">
        <div class="no-prod-text">No products added yet</div>
        <div class="no-data-card-empty">
          <img class="empty-cart-img" src="img/payment/EmptyCartIcon.svg" alt="Empty Cart" />
          <p class="cartEmpty">Your Cart is Empty</p>
          <button class="btn btn-sm nav-btn add-protection skiptranslate" routerLink="/" title="ADD PROTECTION NOW">ADD PROTECTION NOW</button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
