export interface UserModel {
  referer?: String;
  accountnumber?: Number;
  firstName?: String;
  lastName: String;
  middleName?: String;
  email: String;
  phoneNumber: String;
  phoneNumber2: String;
  isAuthorizedToSendMessage?: MessageAuthorization;
  password: String;
  address: Array<AddressTypeModel>;
}

export interface AddressModel {
  streetNumber: String;
  streetName: String;
  city: String;
  state: String;
  zipcode?: String;
  zipcodeId?: String;
  locationCodeId?: String;
  contactType?: String;
}

export interface AddressTypeModel {
  serviceAddress: AddressModel;
  billingAddress: AddressModel;
  customerNo?: String;
  customerGuid?: String;
  ownOrRentedValidated?: string;
  noWaterBillConversion?: string;
  waterBillOnOff?: boolean;
  firstName?: String;
  lastName: String;
  oracleCustId?: String;
  oracleAccountNumber?: String;
}

export type MessageAuthorization = 0 | 1;

export class User implements UserModel {
  referer?: String;

  accountnumber?: Number;

  firstName?: String;

  lastName: String;

  middleName?: String;

  email: String;

  phoneNumber: String;

  phoneNumber2: String;

  // [VJ HWR-2661-2654 MOBILE FIELD RELATED CODE]
  mobilePhoneNumber: String;

  isAuthorizedToSendMessage?: MessageAuthorization = 0;

  cellPhone1SmsOptInDate: String;

  password: String;

  address: Array<AddressTypeModel> = [
    {
      serviceAddress: {
        streetNumber: '',
        streetName: '',
        city: '',
        state: '',
      },
      billingAddress: {
        streetNumber: '',
        streetName: '',
        city: '',
        state: '',
      },
      firstName: '',
      lastName: '',
    },
  ];
}
